import { KortexDialogConfirmation } from "@aos/react-components";
import { IFileInfoDbModel, IWorkInstructionsElementConfig, IWorkInstructionsVideoProps, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";

import { useTranslate } from "../../../../../../../../../hooks/useTranslate";
import StorageManager from "../../../../../../../StorageManager/StorageManager";

const useStyles = makeStyles({
    storageDialog: {
        height: "90vh",
    },
});

export interface IOwnProps {
    onCancel: () => void;
    onSave: (videoProps: IWorkInstructionsElementConfig<IWorkInstructionsVideoProps>) => void;
    open: boolean;
    videoProps: IWorkInstructionsElementConfig<IWorkInstructionsVideoProps>;
}

export default function WorkInstructionsVideoEditor(props: IOwnProps): JSX.Element {
    const { open, onCancel, onSave, videoProps } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [selectedFile, setSelectedFile] = useState<IFileInfoDbModel | undefined>(undefined);

    useStopKeybindPropagation(open);

    /**
     * Called when dialog's cancel button is clicked
     */
    const handleCancel = (): void => {
        onCancel();
    };

    /**
     * Called when dialog's save button is clicked
     */
    const handleSave = (): void => {
        if (selectedFile) {
            onSave({
                ...videoProps,
                extendedProps: {
                    ...videoProps.extendedProps,
                    url: selectedFile.fileId,
                },
            });
        }
    };

    /**
     * Called when a file has been selected
     *
     * @param {IFileInfoDbModel} file - selected file from the storage
     */
    const handleFileSelected = (file: IFileInfoDbModel): void => {
        setSelectedFile(file);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            dialogProps={{
                classes: { paper: classes.storageDialog },
                fullWidth: true,
                maxWidth: "lg",
            }}
            onCancel={handleCancel}
            onConfirm={handleSave}
            open={open}
            textLabels={{
                titleLabel: translate("action.workInstructions.video.title"),
                cancelButtonLabel: translate("action.workInstructions.video.cancel"),
                proceedButtonLabel: translate("action.workInstructions.video.select"),
            }}
        >
            <StorageManager
                acceptedFileType="video"
                fileFilter={TreeNodeNodeTypeEnum.VIDEO}
                onFileSelected={handleFileSelected}
                preSelectedFileId={videoProps.extendedProps.url}
            />
        </KortexDialogConfirmation>
    );
}

import { theme } from "@aos/react-components";
import { IBomItem, ProcessActionStepWorkInstructions } from "@kortex/aos-common";
import SerializedItemIcon from "@kortex/aos-ui/components/core/Icons/serializedItem/serializedItem";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import {
    IconButton,
    InputBase,
    makeStyles,
    Menu,
    MenuItem,
    PopoverPosition,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useContext, useState } from "react";

import { useProcessEditorContext } from "../../../../../../../context";
import { ActionEditorContext } from "../../../../../../context";
import { BomItemInfoTooltip } from "../../../utilities";

const useStyles = makeStyles({
    iconButton: {
        padding: 0,
    },
    inputTextDecimal: {
        fontSize: "1rem",
        padding: "0px",
        textAlign: "center",
    },
    partNumberContainer: {
        alignItems: "center",
        display: "flex",
    },
    serializedItemIcon: {
        marginLeft: "10px",
    },
    tableCellQuantity: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        gap: "4px",
        width: "100%",
    },
    tableRow: {
        height: "40px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
    typographyQuantity: {
        width: "40px",
    },
    text: {
        margin: "2px",
    },
});

interface IOwnProps {
    item: IBomItem;
}

function WorkInstructionsBomStepItemsRow(props: IOwnProps): JSX.Element {
    const { item } = props;

    const classes = useStyles();
    const actionEditorContext = useContext(ActionEditorContext);
    const { bom } = useProcessEditorContext();
    const translate = useTranslate();

    const [menuPosition, setMenuPosition] = useState<PopoverPosition | null>(null);

    const addDisabled = !bom?.items?.[item.partNumber] || item.quantity >= bom.items[item.partNumber].quantity;
    const editedActionStep = actionEditorContext.editedActionStep as ProcessActionStepWorkInstructions;
    const removeDisabled = item.quantity - 1 <= 0;
    const serializable = item.quantity > 0 && item.lotSerialType !== "NONE";
    const serialized = Boolean(editedActionStep?.config.bomItems[item.partNumber]?.serialized);

    const [quantity, setQuantity] = useState<number>(editedActionStep.config.bomItems[item.partNumber].quantity);

    const handleDeleteItem = (): void => {
        const step = deepClone(editedActionStep);
        delete step.config.bomItems[item.partNumber];

        handleMenuClose();
        actionEditorContext.onChangedStep(step);
    };

    const handleMenuClose = (): void => {
        setMenuPosition(null);
    };

    const handleMoreClick = (event: React.MouseEvent<HTMLElement>): void => {
        setMenuPosition({
            left: event.clientX,
            top: event.clientY,
        });
    };

    const handleQuantityIncrease =
        (item: IBomItem): (() => void) =>
        (): void => {
            const bomItem = bom?.items?.[item.partNumber];
            const updatedQuantity = item.quantity + 1;

            if (bomItem && updatedQuantity <= bomItem.quantity) {
                const step = deepClone(editedActionStep);
                step.config.bomItems[item.partNumber].quantity = updatedQuantity;

                actionEditorContext.onChangedStep(step);
            }
        };

    const handleQuantityDecrease =
        (item: IBomItem): (() => void) =>
        (): void => {
            const updatedQuantity = item.quantity - 1;

            const step = deepClone(editedActionStep);
            step.config.bomItems[item.partNumber].quantity = updatedQuantity < 0 ? 0 : updatedQuantity; // Make sure quantity is not below 0

            actionEditorContext.onChangedStep(step);
        };

    const handleQuantityChange = (event: React.FocusEvent<HTMLInputElement>): void => {
        const newValue = parseFloat(event.target.value);

        const step = deepClone(editedActionStep);
        if (bom?.items?.[item.partNumber].quantity && newValue > bom?.items?.[item.partNumber].quantity) {
            step.config.bomItems[item.partNumber].quantity = bom?.items?.[item.partNumber].quantity;
        } else if (newValue < 1e-6) {
            step.config.bomItems[item.partNumber].quantity = 1e-6;
        } else {
            step.config.bomItems[item.partNumber].quantity = newValue;
        }
        setQuantity(step.config.bomItems[item.partNumber].quantity);
        actionEditorContext.onChangedStep(step);
    };

    const quantityOnChange = (event: React.FocusEvent<HTMLInputElement>): void => {
        setQuantity(parseFloat(event.target.value));
    };

    const handleSerializeItem = (): void => {
        const step = deepClone(editedActionStep);
        step.config.bomItems[item.partNumber].serialized = !step.config.bomItems[item.partNumber].serialized;

        handleMenuClose();
        actionEditorContext.onChangedStep(step);
    };

    return (
        <>
            <TableRow className={classes.tableRow}>
                <TableCell>
                    <BomItemInfoTooltip item={item}>
                        <div className={classes.partNumberContainer}>
                            <Typography id={`${item.partNumber}StepItemPartNumberId`} variant="body2">
                                {item.partNumber}
                            </Typography>
                            {serialized ? (
                                <Tooltip
                                    classes={{ tooltip: classes.tooltip }}
                                    placement="right"
                                    title={translate("player.bom.itemSerialized")}
                                >
                                    <div className={classes.serializedItemIcon}>
                                        <SerializedItemIcon height="24px" width="24px" id={`${item.partNumber}ItemSerializedId`} />
                                    </div>
                                </Tooltip>
                            ) : null}
                        </div>
                    </BomItemInfoTooltip>
                </TableCell>
                <TableCell align="center">
                    {!bom?.items?.[item.partNumber].quantity.toString().includes(".") ? (
                        <div className={classes.tableCellQuantity}>
                            <IconButton
                                className={classes.iconButton}
                                disabled={addDisabled}
                                id={`${item.partNumber}AddQtyButtonId`}
                                onClick={handleQuantityIncrease(item)}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                            <Typography className={classes.typographyQuantity} id={`${item.partNumber}StepItemQuantityId`} variant="body2">
                                {item.quantity}
                            </Typography>
                            <IconButton
                                className={classes.iconButton}
                                disabled={removeDisabled}
                                id={`${item.partNumber}RemoveQtyButtonId`}
                                onClick={handleQuantityDecrease(item)}
                            >
                                <RemoveIcon fontSize="small" />
                            </IconButton>
                        </div>
                    ) : (
                        <div className={classes.tableCellQuantity}>
                            <InputBase
                                className={classes.text}
                                inputProps={{
                                    className: classes.inputTextDecimal,
                                    type: "number",
                                }}
                                value={quantity}
                                id={`${item.partNumber}StepItemQuantityId`}
                                onBlur={handleQuantityChange}
                                onChange={quantityOnChange}
                            />
                        </div>
                    )}
                </TableCell>
                <TableCell align="right">
                    <IconButton className={classes.iconButton} id={`${item.partNumber}MenuButtonId`} onClick={handleMoreClick}>
                        <MoreVertIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <Menu
                anchorPosition={menuPosition ?? undefined}
                anchorReference="anchorPosition"
                open={menuPosition !== null}
                onClose={handleMenuClose}
                id="itemStepMenuId"
            >
                {serializable && (
                    <MenuItem onClick={handleSerializeItem} id={`${item.partNumber}SerializeItemId`}>
                        {serialized
                            ? translate("action.workInstructions.bom.deserialize")
                            : translate("action.workInstructions.bom.serialize")}
                    </MenuItem>
                )}
                <MenuItem onClick={handleDeleteItem} id={`${item.partNumber}DeleteItemId`}>
                    {translate("general.delete")}
                </MenuItem>
            </Menu>
        </>
    );
}

export default WorkInstructionsBomStepItemsRow;

import { IReportTagColumnDbModel, IReportTagEntryDbModel, IReportTagValueDbModel } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useState } from "react";

import {
    reportTagGetColumnList,
    reportTagGetEntryList,
    reportTagGetValueList,
    reportTagUpsertColumn,
    reportTagUpsertEntry,
    reportTagUpsertValue,
} from "../../../../redux/report-manager/report-thunks-tag";

import TagColumnsEditor from "./TagColumnsEditor";
import TagEntriesEditor from "./TagEntriesEditor";
import TagValuesEditor from "./TagValuesEditor";

const useStyles = makeStyles({
    root: {
        display: "grid",
        gridTemplateRows: "auto 1fr",
        gridRowGap: "16px",
        margin: "16px",
        height: "calc(100vh - 180px)",
    },
    settings: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "16px",
    },
});

export default function TagEditor(): JSX.Element {
    const classes = useStyles();
    const userAccessLevel = useSelectorUserSession()?.roleRights.system;

    /**
     * COMPONENT STATES
     */
    const [selectedTagColumnId, setSelectedTagColumnId] = useState(-1);
    const [reportTagEntries, setReportTagEntries] = useState<IReportTagEntryDbModel[]>([]);
    const [reportTagColumns, setReportTagColumns] = useState<IReportTagColumnDbModel[]>([]);
    const [reportTagValues, setReportTagValues] = useState<IReportTagValueDbModel[]>([]);

    const dispatch = useThunkDispatch();
    /**
     * EFfect triggered on component mount
     */
    useEffect((): void => {
        dispatch(reportTagGetEntryList()).then((result): void => {
            setReportTagEntries([...result]);
        });

        dispatch(reportTagGetColumnList()).then((result): void => {
            setReportTagColumns([...result]);
        });

        dispatch(reportTagGetValueList()).then((result): void => {
            setReportTagValues([...result]);
        });
    }, []);

    /**
     * Handle the change of the selected tag column id
     *
     * @param {number} columndId - The id of selected tag column
     */
    const handleSelectedColumndIdChanged = (columndId: number): void => {
        setSelectedTagColumnId(columndId);
    };

    /**
     * Handle update event of a tag column
     *
     * @param {IReportTagColumnDbModel} tagColumn - Updated tag column object
     */
    const handleUpdateTagColumn = async (tagColumn: IReportTagColumnDbModel): Promise<void> => {
        const updatedTagColumn = await dispatch(reportTagUpsertColumn(tagColumn));

        if (updatedTagColumn) {
            setReportTagColumns(
                reportTagColumns.map(
                    (columnItem): IReportTagColumnDbModel =>
                        columnItem.reportTagColumnId === updatedTagColumn.reportTagColumnId ? updatedTagColumn : columnItem
                )
            );
        }
    };

    /**
     * Handle event of tag column insertion
     */
    const handleInsertTagColumn = async (): Promise<void> => {
        const newTagColumn: IReportTagColumnDbModel = {
            label: "New Column",
        };

        const insertedTagColumn = await dispatch(reportTagUpsertColumn(newTagColumn));
        if (insertedTagColumn) {
            setReportTagColumns([...reportTagColumns, insertedTagColumn]);
        }
    };

    /**
     * Handle event of tag column value insertion
     */
    const handleInsertTagColumnValue = async (): Promise<void> => {
        if (!selectedTagColumnId) {
            return;
        }

        const newTagColumnValue: IReportTagValueDbModel = {
            reportTagColumnId: selectedTagColumnId,
            value: "New Value",
        };

        const insertedTagValue = await dispatch(reportTagUpsertValue(newTagColumnValue));
        if (insertedTagValue) {
            setReportTagValues([...reportTagValues, insertedTagValue]);
        }
    };

    /**
     * Handle event of tag column value insertion
     */
    const handleInsertTagEntry = async (): Promise<void> => {
        const newTagEntry: IReportTagEntryDbModel = {};

        const insertedTagEntry = await dispatch(reportTagUpsertEntry(newTagEntry));
        if (insertedTagEntry) {
            setReportTagEntries([...reportTagEntries, insertedTagEntry]);
        }
    };

    /**
     * Handle update event of a tag column value
     *
     * @param {IReportTagValueDbModel} tagColumnValue - Updated tag column value object
     */
    const handleUpdateTagColumnValue = async (tagColumnValue: IReportTagValueDbModel): Promise<void> => {
        const upatedTagColumnValue = await dispatch(reportTagUpsertValue(tagColumnValue));
        if (upatedTagColumnValue) {
            setReportTagValues(
                reportTagValues.map(
                    (columnValueItem): IReportTagValueDbModel =>
                        columnValueItem.reportTagValueId === upatedTagColumnValue.reportTagValueId ? upatedTagColumnValue : columnValueItem
                )
            );
        }
    };

    /**
     * Handle update event of a tag column value
     *
     * @param {IReportTagEntryDbModel} updatedTagEntry - Updated tag column value object
     */
    const handleUpdateTagEntry = async (updatedTagEntry: IReportTagEntryDbModel): Promise<void> => {
        const upatedTagEntry = await dispatch(reportTagUpsertEntry(updatedTagEntry));
        if (upatedTagEntry) {
            setReportTagEntries(
                reportTagEntries.map(
                    (reportEntryItem): IReportTagEntryDbModel =>
                        reportEntryItem.reportTagEntryId === upatedTagEntry.reportTagEntryId ? upatedTagEntry : reportEntryItem
                )
            );
        }
    };

    return (
        <div id="settingsTagsPageId" className={classes.root}>
            <div className={classes.settings}>
                <TagColumnsEditor
                    userAccessLevel={userAccessLevel}
                    tagColumns={reportTagColumns}
                    onSelectedColumnIdChanged={handleSelectedColumndIdChanged}
                    onUpdateTagColumn={handleUpdateTagColumn}
                    onInsertTagColumns={handleInsertTagColumn}
                    onInsertTagColumnValue={handleInsertTagColumnValue}
                />
                <TagValuesEditor
                    userAccessLevel={userAccessLevel}
                    tagColumnValues={reportTagValues.filter((item): boolean => item.reportTagColumnId === selectedTagColumnId)}
                    onTagColumnValueChanged={handleUpdateTagColumnValue}
                />
            </div>

            <TagEntriesEditor
                tagColumns={reportTagColumns}
                tagColumnValues={reportTagValues}
                tagColumnEntries={reportTagEntries}
                onTagEntryChanged={handleUpdateTagEntry}
                onTagEntryInsert={handleInsertTagEntry}
            />
        </div>
    );
}

import { EntityStatusEnum, getDateTimeStr, TrackingId } from "@kortex/aos-common";
import { getPageUrl } from "@kortex/aos-ui/configs/menu";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    tableCell: {
        padding: "8px 4px",
    },
    tableRow: {},
});

export interface IRowData {
    completedOn?: number;
    createdOn: number;
    status: EntityStatusEnum;
    trackingId: TrackingId;
}

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    data: IRowData;
    hideTrackingId: boolean;
    index: number;
}

const Row: FC<IOwnProps> = (props) => {
    const { data, hideTrackingId } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    return (
        <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>
                {hideTrackingId ? null : (
                    <Typography variant="body2">
                        <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=trackingId&trackingId=${data.trackingId}`}>
                            {data.trackingId}
                        </Link>
                    </Typography>
                )}
            </TableCell>
            {/* TODO: Add link to Scheduler (AOS-2578)  */}
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">{translate(`entity.status.${data.status}`)}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">{getDateTimeStr(data.createdOn)}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">{data.completedOn ? getDateTimeStr(data.completedOn) : translate("general.na")}</Typography>
            </TableCell>
        </TableRow>
    );
};

export default Row;

import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import React, { FC, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { getPageUrl } from "../menu";

const PrivateRoute: FC<PropsWithChildren> = (props) => {
    const { children } = props;
    const location = useLocation();
    const session = useSelectorUserSession();

    if (!session?.userId) {
        return <Navigate to={getPageUrl(EnumPageTypes.LOGIN)} replace={true} state={{ from: location }} />;
    }

    return <>{children}</>; // Keep <></> to prevent TS error
};

export default PrivateRoute;

import { theme } from "@aos/react-components";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles({
    tableCell: {
        backgroundColor: theme.palette.grey[300],
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
});

const Header: FC = () => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <TableHead>
            <TableRow>
                <TableCell align="left" className={classes.tableCell} width="15%">
                    <Typography variant="h6">{translate("bomPage.bomTable.quantity")}</Typography>
                </TableCell>
                <TableCell align="left" className={classes.tableCell} width="85%">
                    <Typography variant="h6">{translate("bomPage.bomTable.traceability")}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default Header;

import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";
import { MenuItem } from "@material-ui/core";
import React, { useMemo } from "react";

export function useSearchTypeMenuItems(): JSX.Element[] {
    const language = useSelectorLanguage();
    const translate = useTranslate();

    return useMemo(
        () =>
            [
                "adjustmentType",
                "adjustmentTypeSerialNumber",
                "jobRefId",
                "partNumber",
                "secondaryTraceabilitySerialNumber",
                "traceability",
                "trackingId",
            ]
                .sort((typeA, typeB) => translate(`bomPage.searchType.${typeA}`).localeCompare(translate(`bomPage.searchType.${typeB}`)))
                .map((type, index) => (
                    <MenuItem key={index} id={`bomHeaderSearchType-${type}`} value={type}>
                        {translate(`bomPage.searchType.${type}`)}
                    </MenuItem>
                )),
        [language]
    );
}

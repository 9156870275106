import { theme } from "@aos/react-components";
import { IGetItemsByTypeAjustmentRes } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, Table, TableBody, TablePagination, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";

import { Header } from "./header";
import { Row } from "./row";

const useStyles = makeStyles({
    root: {
        display: "grid",
        height: "calc(100vh - 244px)", // 64px (app header) + 32px (app margins) + 83px (bom page header) + 49px (adjustment type tabs) + 16px (adjustment type tabs margin)
        overflowY: "auto",
    },
    rowEven: {
        backgroundColor: theme.palette.grey[100],
    },
    rowOdd: {
        backgroundColor: theme.palette.grey[200],
    },
    tableContainer: {
        height: "calc(100vh - 298px)", // 64px (app header) + 32px (app margins) + 83px (bom page header) + 49px (adjustment type tabs) + 16px (adjustment type tabs margin) + 54px (table pagination)
        overflowY: "auto",
    },
    tableContainerCaptionEnabled: {
        height: "calc(100vh - 338px)", // tableContainer height - 40px (typography x2)
    },
});

interface IState {
    page: number;
    rowsPerPage: number;
}

interface IOwnProps {
    followUpHistories: IGetItemsByTypeAjustmentRes;
}

const AdjustmentTypeOperationTable: FC<IOwnProps> = (props) => {
    const { followUpHistories } = props;
    const classes = useStyles();
    const translate = useTranslate();

    const [state, setState] = useState<IState>({
        page: 0,
        rowsPerPage: 15,
    });

    const emptyTraceabilityFound = Boolean(followUpHistories.find((history) => !history.traceability));

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setState((prevState) => ({ ...prevState, page: newPage }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState((prevState) => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        }));
    };

    return (
        <div className={classes.root}>
            {emptyTraceabilityFound && (
                // At least 1 history has no traceability
                <>
                    <Typography color="error" variant="caption">
                        {`1: ${translate("bomPage.bomTable.traceabilityNoLongerExists")}`}
                    </Typography>
                    <Typography color="error" variant="caption">
                        {`2: ${translate("bomPage.bomTable.traceabilityNonTraceableItem")}`}
                    </Typography>
                </>
            )}
            <div className={`${classes.tableContainer} ${emptyTraceabilityFound ? classes.tableContainerCaptionEnabled : ""}`}>
                <Table stickyHeader={true}>
                    <Header />
                    <TableBody>
                        {followUpHistories
                            .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                            .map((followUpHistory, index) => (
                                <Row
                                    classes={{ tableRow: index % 2 === 0 ? classes.rowEven : classes.rowOdd }}
                                    followUpHistory={followUpHistory}
                                    key={`adjustmentTypeOperationTableRow${index}`}
                                />
                            ))}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                component="div"
                count={followUpHistories.length}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={state.page}
                rowsPerPage={state.rowsPerPage}
                rowsPerPageOptions={[15, 50, 100]}
            />
        </div>
    );
};

export default AdjustmentTypeOperationTable;

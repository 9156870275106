import { isAppPackaged, isDesktopMode } from "@kortex/aos-ui/utilitites/desktopUtils";
import React, { FC } from "react";
import { BrowserRouter, BrowserRouterProps, HashRouter, HashRouterProps } from "react-router-dom";

const Router: FC<BrowserRouterProps | HashRouterProps> = (props) => {
    const { children, ...rest } = props;

    // Router for production with Electron
    if (isDesktopMode && isAppPackaged()) {
        return <HashRouter {...rest}>{children}</HashRouter>;
    }

    // Base router
    return <BrowserRouter {...rest}>{children}</BrowserRouter>;
};

export default Router;

import { IWoBomItem } from "@kortex/aos-common";
import { MenuType } from "@kortex/aos-ui/components/core/bom";
import { IconButton, makeStyles, TableCell, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { FC } from "react";

import { usePlayerContext } from "../../../context";
import { PlayerControlsBomItemInfoTooltip } from "../../../controls/content/bom/utilities";

const useStyles = makeStyles({
    root: {}, // To overwrite with props
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    item: IWoBomItem;
    onMoreClick: (type: MenuType) => (event: React.MouseEvent<HTMLElement>) => void;
    processActionLabel: string;
    processActionStepLabel: string;
}

const NonTraceableRow: FC<IOwnProps> = (props) => {
    const { item, onMoreClick, processActionLabel, processActionStepLabel } = props;

    const classes = useStyles(props);
    const { jobProcessInfo, playerState } = usePlayerContext();

    const isBomDisabled = !(playerState.processState.bom.enabled && Boolean(jobProcessInfo));

    return (
        <TableRow key={item.partNumber} className={classes.root}>
            {/* ACTION LABEL */}
            <TableCell id={`playerBomDialogNonTraceableRowProcessActionLabelId-${item.partNumber}`} width="10%">
                {processActionLabel}
            </TableCell>
            {/* STEP LABEL */}
            <TableCell id={`playerBomDialogNonTraceableRowProcessActionStepLabelId-${item.partNumber}`} width="10%">
                {processActionStepLabel}
            </TableCell>
            {/* PART NUMBER */}
            <PlayerControlsBomItemInfoTooltip item={item} placement="right">
                <TableCell id={`playerBomDialogNonTraceableRowPartNumberId-${item.partNumber}`} width="10%">
                    {item.partNumber}
                </TableCell>
            </PlayerControlsBomItemInfoTooltip>
            {/* DESCRIPTION */}
            <TableCell id={`playerBomDialogNonTraceableRowDescriptionId-${item.partNumber}`} width="35%">
                {item.description}
            </TableCell>
            {/* QUANTITY */}
            <TableCell id={`playerBomDialogNonTraceableRowQuantityId-${item.partNumber}`} width="5%">
                {item.quantity}
            </TableCell>
            {/* OPTIONS BUTTON */}
            <TableCell colSpan={2} width="30%">
                <IconButton
                    disabled={isBomDisabled}
                    id={`playerBomDialogNonTraceableRowMoreId-${item.partNumber}`}
                    onClick={onMoreClick("non-traceable")}
                >
                    <EditIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default NonTraceableRow;

import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceRequestWrapped,
    ErpGetBomByJobReq,
    ErpGetBomByJobRes,
    ErpGetBomByTrackingIdReq,
    ErpGetBomByTrackingIdRes,
    ErpGetBomReq,
    ErpGetBomRes,
    ErpGetItemsByAdjustmentTypeReq,
    ErpGetItemsByAdjustmentTypeRes,
    ErpGetItemsByPartNumberReq,
    ErpGetItemsByPartNumberRes,
    ErpGetItemsBySecondaryTraceabilitySerialNumberReq,
    ErpGetItemsBySecondaryTraceabilitySerialNumberRes,
    ErpGetItemsByTraceabilityReq,
    ErpGetItemsByTraceabilityRes,
    ErpGetSerializedItemsByAdjustmentTypeReq,
    ErpGetSerializedItemsByAdjustmentTypeRes,
    ErpGetWoBomReq,
    ErpGetWoBomRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { erpGetBom } from "./getBom";
import { erpGetBomByJob } from "./getBomByJob";
import { erpGetBomByTrackingId } from "./getBomByTrackingId";
import { erpGetItemsByAdjustmentType } from "./getItemsByAdjustmentType";
import { erpGetItemsByPartNumber } from "./getItemsByPartNumber";
import { erpGetItemsBySecondaryTraceabilitySerialNumber } from "./getItemsBySecondaryTraceabilitySerialNumber";
import { erpGetItemsByTraceability } from "./getItemsByTraceability";
import { erpGetSerializedItemsByAdjustmentType } from "./getSerializedItemsByAdjustmentType";
import { erpGetWoBom } from "./getWoBom";

interface IAOSClientServiceWrappedErp {
    getBom: AOSClientServiceRequestWrapped<ErpGetBomReq, ErpGetBomRes>;
    getBomByJob: AOSClientServiceRequestWrapped<ErpGetBomByJobReq, ErpGetBomByJobRes>;
    getBomByTrackingId: AOSClientServiceRequestWrapped<ErpGetBomByTrackingIdReq, ErpGetBomByTrackingIdRes>;
    getItemsByAdjustmentType: AOSClientServiceRequestWrapped<ErpGetItemsByAdjustmentTypeReq, ErpGetItemsByAdjustmentTypeRes>;
    getItemsByPartNumber: AOSClientServiceRequestWrapped<ErpGetItemsByPartNumberReq, ErpGetItemsByPartNumberRes>;
    getItemsBySecondaryTraceabilitySerialNumber: AOSClientServiceRequestWrapped<
        ErpGetItemsBySecondaryTraceabilitySerialNumberReq,
        ErpGetItemsBySecondaryTraceabilitySerialNumberRes
    >;
    getItemsByTraceability: AOSClientServiceRequestWrapped<ErpGetItemsByTraceabilityReq, ErpGetItemsByTraceabilityRes>;
    getSerializedItemsByAdjustmentType: AOSClientServiceRequestWrapped<
        ErpGetSerializedItemsByAdjustmentTypeReq,
        ErpGetSerializedItemsByAdjustmentTypeRes
    >;
    getWoBom: AOSClientServiceRequestWrapped<ErpGetWoBomReq, ErpGetWoBomRes>;
}

type AOSClientServiceWrappedErp = IAOSClientServiceWrappedErp;

// ADD_API_CALL Electronic Signature
export function generateErpServiceClient(router: IRouterClient, _: EventEmitter): AOSClientServiceWrappedErp {
    return {
        getBom: AOSClientServiceWrapperUI.request(erpGetBom(router)),
        getBomByJob: AOSClientServiceWrapperUI.request(erpGetBomByJob(router)),
        getBomByTrackingId: AOSClientServiceWrapperUI.request(erpGetBomByTrackingId(router)),
        getItemsByAdjustmentType: AOSClientServiceWrapperUI.request(erpGetItemsByAdjustmentType(router)),
        getItemsByPartNumber: AOSClientServiceWrapperUI.request(erpGetItemsByPartNumber(router)),
        getItemsBySecondaryTraceabilitySerialNumber: AOSClientServiceWrapperUI.request(
            erpGetItemsBySecondaryTraceabilitySerialNumber(router)
        ),
        getItemsByTraceability: AOSClientServiceWrapperUI.request(erpGetItemsByTraceability(router)),
        getSerializedItemsByAdjustmentType: AOSClientServiceWrapperUI.request(erpGetSerializedItemsByAdjustmentType(router)),
        getWoBom: AOSClientServiceWrapperUI.request(erpGetWoBom(router)),
    };
}

generateErpServiceClient.serviceId = 150;

import { theme } from "@aos/react-components";
import { IconButton } from "@material-ui/core";
import { createGenerateClassName, makeStyles, MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import { SnackbarKey, SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";

import { ForegroundStackContext, useForegroundStack } from "../hooks/useForeground";
import { KeybindManagerContext, useKeybindManager } from "../hooks/useKeybind";
import { en as enMessages } from "../locales/en";
import { fr as frMessages } from "../locales/fr";
import { intlEn, intlFr, IntlMiddleware } from "../locales/intl-middleware";
import { useSelectorLanguage } from "../redux/selectors";

import { Router } from "./core/router";
import AppLayout from "./layout/AppLayout/AppLayout";
import { SnackbarConfigurator } from "./layout/snackbarConfigurator";

const generateClassName = createGenerateClassName({
    productionPrefix: "aos",
    seed: "aos",
});

const useStyles = makeStyles({
    snackbarErrorIcon: {
        color: "#fff",
        marginRight: "10px",
    },
    snackbarCloseButton: {
        color: "#fff",
    },
    snackbarCloseIconButton: {
        pointerEvents: "all",
    },
});

function App(): JSX.Element {
    const keybindManagerContext = useKeybindManager();
    const stackContext = useForegroundStack();
    const language = useSelectorLanguage();
    const locMessage = language === "en" ? enMessages : frMessages;
    const classes = useStyles();

    useEffect((): void => {
        switch (language) {
            case "fr":
                IntlMiddleware.set(intlFr);
                break;
            default:
                IntlMiddleware.set(intlEn);
        }
    }, [language]);

    const notistackRef = React.createRef<SnackbarProvider>();

    const onClickDismiss = (key: SnackbarKey) => (): void => {
        notistackRef?.current?.closeSnackbar(key);
    };

    return (
        <IntlProvider locale={language} messages={locMessage}>
            <Router>
                <StylesProvider generateClassName={generateClassName}>
                    <MuiThemeProvider theme={theme}>
                        <ForegroundStackContext.Provider value={stackContext}>
                            <KeybindManagerContext.Provider value={keybindManagerContext}>
                                <SnackbarProvider
                                    action={(key): JSX.Element => (
                                        <IconButton
                                            className={classes.snackbarCloseIconButton}
                                            id={"closeFilterId"}
                                            onClick={onClickDismiss(key)}
                                        >
                                            <CloseIcon className={classes.snackbarCloseButton} />
                                        </IconButton>
                                    )}
                                    iconVariant={{
                                        error: <ErrorIcon className={classes.snackbarErrorIcon} />,
                                    }}
                                    maxSnack={5}
                                    ref={notistackRef}
                                >
                                    <AppLayout />
                                    <SnackbarConfigurator />
                                </SnackbarProvider>
                            </KeybindManagerContext.Provider>
                        </ForegroundStackContext.Provider>
                    </MuiThemeProvider>
                </StylesProvider>
            </Router>
        </IntlProvider>
    );
}

export default App;

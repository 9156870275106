import { theme } from "@aos/react-components";
import { IGetItemsByTypeAjustmentRes } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, Table, TableBody, TablePagination, Typography } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";

import { Header } from "./header";
import { Row } from "./row";

const useStyles = makeStyles({
    root: {
        flex: 1,
    },
    rowEven: {
        backgroundColor: theme.palette.grey[100],
    },
    rowOdd: {
        backgroundColor: theme.palette.grey[200],
    },
    tableContainer: {
        height: "calc(100vh - 298px)", // 64px (app header) + 32px (app margins) + 83px (bom page header) + 49px (adjustment type tabs) + 16px (adjustment type tabs margin) + 54px (table pagination)
        overflowY: "auto",
    },
    tableFooter: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
    },
    typographyTotal: {
        display: "flex",
        marginBottom: "16px",
    },
});

interface ITraceabilityQuantity {
    [traceability: string]: number;
}

interface IState {
    page: number;
    rowsPerPage: number;
}

interface IOwnProps {
    followUpHistories: IGetItemsByTypeAjustmentRes;
}

const TraceabilitySummaryTable: FC<IOwnProps> = (props) => {
    const { followUpHistories } = props;
    const classes = useStyles();
    const translate = useTranslate();

    const [state, setState] = useState<IState>({
        page: 0,
        rowsPerPage: 15,
    });

    const rows = useMemo(() => {
        const traceabilityCount: ITraceabilityQuantity = {};

        for (const history of followUpHistories) {
            const traceability = history.traceability || history.previousValues.traceability;

            if (!traceability) continue;

            if (traceabilityCount[traceability]) {
                traceabilityCount[traceability] += history.quantity;
            } else {
                traceabilityCount[traceability] = history.quantity;
            }
        }

        return Object.keys(traceabilityCount)
            .map((traceability): [string, number] => [traceability, traceabilityCount[traceability]])
            .sort(
                ([traceabilityA, quantityA], [traceabilityB, quantityB]) =>
                    quantityB - quantityA || traceabilityA.localeCompare(traceabilityB) // Order by quantity, alphabetically if quantities are equal
            );
    }, [followUpHistories]);

    const totalQuantity = useMemo(() => rows.reduce((prev, current) => ["Count", prev[1] + current[1]])[1], [rows]);

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setState((prevState) => ({ ...prevState, page: newPage }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState((prevState) => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        }));
    };

    return (
        <div className={classes.root}>
            <div className={classes.tableContainer}>
                <Table stickyHeader={true}>
                    <Header />
                    <TableBody>
                        {rows
                            .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                            .map(([traceability, quantity], index) => (
                                <Row
                                    classes={{ tableRow: index % 2 === 0 ? classes.rowEven : classes.rowOdd }}
                                    quantity={quantity}
                                    traceability={traceability}
                                    key={`traceabilitySummaryTableRow${index}`}
                                />
                            ))}
                    </TableBody>
                </Table>
            </div>
            <div className={classes.tableFooter}>
                <Typography variant="body2">{`${translate("bomPage.bomTable.totalColon")} ${totalQuantity}`}</Typography>
                <TablePagination
                    component="div"
                    count={rows.length}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={state.page}
                    rowsPerPage={state.rowsPerPage}
                    rowsPerPageOptions={[15, 50, 100]}
                />
            </div>
        </div>
    );
};

export default TraceabilitySummaryTable;

import { IWoBomComplete } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Collapse, IconButton, Typography, makeStyles } from "@material-ui/core";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { FC, useState } from "react";

import { WoBomTableProvider } from "./context";
import { WoBomTableItems } from "./items";

const useStyles = makeStyles({
    collapseContext: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
    },
    header: {
        alignItems: "center",
        display: "inline-flex",
        gap: "16px",
        padding: "16px 0",
        width: "100%",
    },
    root: {
        // To overwrite...
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    woBom: IWoBomComplete;
}

const WoBomTable: FC<IOwnProps> = (props) => {
    const { woBom } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    const [expanded, setExpanded] = useState<boolean>(true);

    /**
     * Expands the table
     */
    const handleToggleExpand = (): void => {
        setExpanded((prevState) => !prevState);
    };

    return (
        <WoBomTableProvider woBom={woBom}>
            <div className={classes.root}>
                <div className={classes.header}>
                    <IconButton size="small" onClick={handleToggleExpand} id="openBomItemsButtonId">
                        {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </IconButton>
                    <Typography variant="h6">{woBom.woBom.trackingId}</Typography>
                </div>
                <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                    <div className={classes.collapseContext}>
                        {/* TRACEABLE ITEMS */}
                        <WoBomTableItems itemFilter="traceable" title={translate("bomPage.traceableItems")} />
                        {/* NON-TRACEABLE ITEMS */}
                        <WoBomTableItems itemFilter="non-traceable" title={translate("bomPage.nonTraceableItems")} />
                    </div>
                </Collapse>
            </div>
        </WoBomTableProvider>
    );
};

export default WoBomTable;

import { greyPalette, theme } from "@aos/react-components";
import { IUserTraining, ProcessTrainingRequirementEnum } from "@kortex/aos-common";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { getPageUrl } from "@kortex/aos-ui/configs/menu";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesUsers } from "@kortex/aos-ui/redux/effects";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { useSelectorUsersRolesRights } from "@kortex/aos-ui/redux/selectors";
import { userTrainingUpdateStatus } from "@kortex/aos-ui/redux/user-training-manager/user-training-thunks";
import { userCanArchive, userCanWrite } from "@kortex/aos-ui/utilitites/IUserRights";
import { Checkbox, IconButton, Menu, MenuItem, PopoverPosition, Tooltip, Typography, makeStyles } from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { createRef, useState } from "react";
import { Link } from "react-router-dom";

import { TrainingArchiveConfirmationDialog } from "../trainingArchiveConfirmationDialog";
import { getTrainingRequirementLocale } from "../utilities";

import { useUserTrainingContext } from "./context";
import { getUserTrainingStatusColor } from "./utilities/getUserTrainingStatusColor";

const useStyles = makeStyles({
    cardContent: {
        display: "grid",
        gridTemplateColumns: "32% 10% 10% 10% 13% 13% 12%;",
        alignItems: "center",
    },
    text: {
        color: greyPalette[700],
    },
    tooltip: {
        fontSize: "1.1rem",
        backgroundColor: greyPalette[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
    },
    column: {
        alignItems: "center",
        display: "flex",
        height: "58px",
        justifyContent: "center",
    },
    columnProcessLabel: {
        borderRight: `1px solid ${greyPalette[300]}`,
        justifyContent: "left",
        padding: "0 10px",
        userSelect: "text",
    },
    checkBoxTrainingRoot: {
        pointerEvents: "none",
    },
    buttonContent: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "10px",
    },
    goToStepIcon: {
        marginRight: "10px",
    },
    icon: {
        height: "48px",
        margin: "5px 0 5px",
    },
    iconDeprecated: {
        height: "24px",
        width: "24px",
    },
    linkDisabled: {
        color: greyPalette[700],
        pointerEvents: "none",
        textDecoration: "none",
    },
    iconDeprecatedContainer: {
        alignItems: "center",
        display: "flex",
        height: "48px",
        justifyContent: "center",
        margin: "5px 0 5px",
        width: "48px",
    },
    trainingSelectedCheckbox: {
        width: "30px",
    },
});

interface IOwnProps {
    userTraining: IUserTraining;
    index: number;
}

export default function UserTrainingCard(props: IOwnProps): JSX.Element {
    const { userTraining, index } = props;

    const ref = createRef<HTMLDivElement>();
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();
    const userRoleRightsTraining = useSelectorUsersRolesRights("training");
    const userTrainingContext = useUserTrainingContext();

    const selectedUser = useEntitiesUsers().find((user) => user.userId === userTrainingContext.trainingFilters.userId);

    const [archiveDialogOpened, setArchiveDialogOpened] = useState<boolean>(false);
    const [menuPosition, setMenuPosition] = useState<PopoverPosition | null>(null);

    const trainingStatusColor = getUserTrainingStatusColor(userTraining.current, userTraining.latest);
    const training =
        userTraining.current?.training && userTraining.current.training.status !== "archived" ? userTraining.current : userTraining.latest;
    const readOnly = !userCanWrite(userRoleRightsTraining) || !Boolean(training?.training);

    const handleMoreClick = (event: React.MouseEvent): void => {
        setMenuPosition({
            left: event.clientX,
            top: event.clientY,
        });
    };

    const handleMenuClose = (): void => {
        setMenuPosition(null);
    };

    const handleOpenHistoryDialog = (): void => {
        handleMenuClose();

        userTrainingContext.setHistoryTreeNodeIdSelected(userTraining.treeNode.treeNodeId);
        userTrainingContext.setOpenHistoryDialog(true);
    };

    const handleDeprecateTraining = (): void => {
        handleMenuClose();

        if (!training?.training) return void 0;

        dispatch(
            userTrainingUpdateStatus({
                trainingId: training.training.trainingId,
                status: training.training.status === "deprecated" ? "active" : "deprecated",
                treeNodeId: userTraining.treeNode.treeNodeId,
            })
        );
    };

    const handleOpenArchiveDialog = (): void => {
        handleMenuClose();

        setArchiveDialogOpened(true);
    };

    const handleArchiveTrainingClose = (): void => {
        setArchiveDialogOpened(false);
    };

    const handleArchiveTrainingConfirm = (): void => {
        handleArchiveTrainingClose();

        if (!training?.training) return void 0;

        dispatch(
            userTrainingUpdateStatus({
                trainingId: training.training.trainingId,
                status: "archived",
                treeNodeId: userTraining.treeNode.treeNodeId,
            })
        );
    };

    return (
        <KortexPanelCard
            statusColor={trainingStatusColor.color}
            tooltipValue={translate(trainingStatusColor.tooltip)}
            isSelected={false}
            padding={0}
            spaceBetweenCard={1}
        >
            <div className={classes.cardContent} id={`userTrainingCard${index}Id`} ref={ref}>
                {/* PROCESS NAME */}
                <div className={`${classes.column} ${classes.columnProcessLabel}`} id={`processNameCard${index}Id`}>
                    <Link
                        className={userTrainingContext.canAccessProcessEditor ? "" : classes.linkDisabled}
                        to={`${getPageUrl(EnumPageTypes.PROCESS)}/${userTraining.treeNode.treeNodeId}/${training?.process.processId}`}
                    >
                        <Typography id={`processNameCardLabel${index}Id`} variant="body1">
                            {userTraining.treeNode.label}
                        </Typography>
                    </Link>
                </div>
                {/* STATUS */}
                <div className={classes.column}>
                    <Checkbox
                        checked={training?.training?.trained ?? false}
                        checkedIcon={<CheckBoxIcon />}
                        classes={{ root: classes.checkBoxTrainingRoot }}
                        icon={<CheckBoxOutlineBlankIcon />}
                        id={`checkboxTrained${index}Id`}
                        inputProps={{ readOnly }}
                    />
                </div>
                <div className={classes.column}>
                    <Checkbox
                        checked={training?.training?.certified}
                        checkedIcon={<CheckBoxIcon />}
                        classes={{ root: classes.checkBoxTrainingRoot }}
                        icon={<CheckBoxOutlineBlankIcon />}
                        id={`checkboxCertified${index}Id`}
                        inputProps={{ readOnly }}
                    />
                </div>
                {/* VERSION */}
                <div className={classes.column}>
                    <Typography className={classes.text} id={`versionTraining${index}Id`}>
                        {training?.process.version ?? translate("general.na")}
                    </Typography>
                </div>
                {/* ORIGINAL VERSION */}
                <div className={classes.column}>
                    <Typography className={classes.text} id={`originalVersionTraining${index}Id`}>
                        {training?.originalProcess?.version ?? translate("general.na")}
                    </Typography>
                </div>
                {/* LEVEL OF CHANGE */}
                <div className={classes.column}>
                    <Typography className={classes.text} id={`levelOfChange${index}Id`}>
                        {translate(
                            getTrainingRequirementLocale(training?.process.trainingRequirement ?? ProcessTrainingRequirementEnum.NONE)
                        ).toUpperCase()}
                    </Typography>
                </div>
                {/* EXTRA BUTTONS */}
                <div className={classes.buttonContent}>
                    {training?.training?.status === "deprecated" ? (
                        <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={translate("training.tooltipTrainingStatusDeprecated")}
                            placement="top"
                        >
                            <div className={classes.iconDeprecatedContainer}>
                                <ArchiveIcon className={classes.iconDeprecated} color="error" id="userTrainingIconDeprecatedId" />
                            </div>
                        </Tooltip>
                    ) : (
                        <div style={{ height: "48px", width: "48px", margin: "5px 0" }} />
                    )}
                    <IconButton className={classes.icon} id="userTrainingMoreId" onClick={handleMoreClick}>
                        <MoreVertIcon />
                    </IconButton>
                </div>
                <Menu
                    anchorPosition={menuPosition ?? undefined}
                    anchorReference="anchorPosition"
                    id="userTrainingMoreMenuId"
                    onClose={handleMenuClose}
                    open={menuPosition !== null}
                >
                    {/* TRAINING HISTORY */}
                    <MenuItem id="userTrainingMoreMenuHistoryId" onClick={handleOpenHistoryDialog}>
                        {translate("training.moreOptions.history")}
                    </MenuItem>
                    {/* EDIT TRACEABILITY */}
                    <MenuItem
                        disabled={!userCanArchive(userRoleRightsTraining)}
                        id="userTrainingMoreMenuDeprecatedId"
                        onClick={handleDeprecateTraining}
                    >
                        {training?.training?.status === "deprecated"
                            ? translate("training.moreOptions.maintain")
                            : translate("training.moreOptions.noLongerMaintain")}
                    </MenuItem>
                    {/* ARCHIVE TRAINING */}
                    <MenuItem
                        disabled={!userCanArchive(userRoleRightsTraining)}
                        id="userTrainingMoreMenuArchiveId"
                        onClick={handleOpenArchiveDialog}
                    >
                        {translate("training.moreOptions.archive")}
                    </MenuItem>
                </Menu>
                {training?.training ? (
                    <TrainingArchiveConfirmationDialog
                        certified={training.training.certified}
                        onCancel={handleArchiveTrainingClose}
                        onConfirm={handleArchiveTrainingConfirm}
                        open={archiveDialogOpened}
                        processLabel={userTraining.treeNode.label}
                        processVersion={training.process?.version ?? translate("general.na")}
                        trained={training.training.trained}
                        userName={selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : ""}
                    />
                ) : null}
            </div>
        </KortexPanelCard>
    );
}

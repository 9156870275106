import Debug from "debug";

import { AOSWSClient, client as clientDesktop } from "./kortex-client/clientDesktop";

const debug = Debug("kortex:aos:desktop-utils");

const userAgent = navigator.userAgent.toLowerCase();
export const isDesktopMode: boolean = userAgent && userAgent.indexOf(" electron/") > -1 ? true : false;

let desktopVersion = "dev";
let desktopAdress = "";
let desktopPort = 0;
let packaged = false;

if (isDesktopMode) {
    const remote = window.require("@electron/remote");
    const { app, process } = remote;
    desktopVersion = app && app.getVersion();
    debug("Desktop Version:", desktopVersion);

    desktopAdress = process.env.AOS_RUNNER_DESKTOP_HOSTNAME ? process.env.AOS_RUNNER_DESKTOP_HOSTNAME : "";
    desktopPort = process.env.AOS_RUNNER_DESKTOP_PORT ? parseInt(process.env.AOS_RUNNER_DESKTOP_PORT) : 0;
    packaged = process.env.PACKAGED === "true";
}

export function getDesktopUrl(): { address: string; port: number } {
    return { address: desktopAdress, port: desktopPort };
}

/**
 * Gets desktop version
 *
 * @returns {string} desktop version
 */
export function getDesktopVersion(): string {
    return desktopVersion;
}

/**
 * Gets refrerence to local electron API Client
 *
 */

export function getElectronAPIClient(): AOSWSClient {
    return clientDesktop;
}

/**
 * Returns true if app is packaged as a desktop app.
 */
export function isAppPackaged(): Readonly<boolean> {
    return packaged;
}

import {
    ErpGetBomReq,
    ErpGetBomRes,
    IProcessUiModel,
    ProcessAction,
    ProcessActionCopyReq,
    ProcessActionCopyRes,
    ProcessActionDeleteReq,
    ProcessActionInsertReq,
    ProcessActionStepCopyReq,
    ProcessActionStepDeleteReq,
    ProcessActionStepInsertReq,
    ProcessActionStepReorderReq,
    ProcessActionStepUpdateReq,
    ProcessActionUpdateReq,
    ProcessGetLatestVersionReq,
    ProcessGetLatestVersionRes,
    ProcessId,
    ProcessUiModel,
    ProcessVariableWhereUsedReq,
    ProcessVariableWhereUsedRes,
    ProcessVersionAllParentRes,
    ProcessVersionAllRes,
    TreeNodeId,
    UnwrapAOSPayload,
} from "@kortex/aos-common";

import { APIPayload } from "../../utilitites/kortex-client/client";
import { OrUndefined, Unpack } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { jobUpdatedAction } from "../scheduler-manager/scheduler-actions";
import { AppState, StandardDispatch, StandardThunk } from "../store";

import {
    processActionDeletedAction,
    processActionInsertedAction,
    processActionUpdatedAction,
    processInsertedAction,
    processUpdatedAction,
    processesUpdatedAction,
    setCopiedProcessAction,
    setCopiedProcessActionStepAction,
    setEditedProcessIdAction,
    setProcessLookupTableFromTreeNodeIdUpdated,
    setProcessesLookupTableFromTreeNodesIdUpdated,
} from "./process-actions";
import { ICopiedActionStep } from "./process-types";
import { getProcessIsDraftFromState } from "./utilities/getProcessIsDraftFromState";
// thunks

/**
 * Copy a process action
 *
 * @param {ProcessAction} processAction - process action to copy
 */
export function processActionCopy(processAction: ProcessAction[]): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(setCopiedProcessAction(processAction));
    };
}

/**
 * Copy a process action step
 *
 * @param {ICopiedActionStep} processActionStep - process action step to copy
 */
export function processActionStepCopy(processActionStep: ICopiedActionStep): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(setCopiedProcessActionStepAction(processActionStep));
    };
}

/**
 * Delete a process action
 *
 * @param {APIPayload<"process", "deleteAction">} processAction - process action to delete
 */
export function processActionDelete(processAction: UnwrapAOSPayload<ProcessActionDeleteReq>): StandardThunk<void> {
    return async (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, intl }): Promise<void> => {
        if (getProcessIsDraftFromState(processAction.processId, getState, { intl })) {
            try {
                await api.services.process
                    .deleteAction(processAction)()
                    .then((actions) => {
                        dispatch(processActionDeletedAction([...actions]));
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
    };
}

/**
 * Delete a process action step
 *
 * @param {APIPayload<"process", "deleteAction">} param - process action to delete
 */
export function processActionStepDelete(param: UnwrapAOSPayload<ProcessActionStepDeleteReq>): StandardThunk<ProcessAction | undefined> {
    return async (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, intl }): Promise<ProcessAction | undefined> => {
        if (getProcessIsDraftFromState(param.processAction.processId, getState, { intl })) {
            try {
                return api.services.process
                    .deleteStep(param)()
                    .then((res) => {
                        dispatch(processActionUpdatedAction([{ ...res }]));
                        return { ...res };
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
        return undefined;
    };
}

/**
 *
 * Inser a copy of a process action(s)
 *
 * @param {APIPayload<"process", "copyAction">} processAction - process action to insert
 */
export function processActionInsertCopy(
    processAction: UnwrapAOSPayload<ProcessActionCopyReq>
): StandardThunk<UnwrapAOSPayload<ProcessActionCopyRes>> {
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api, intl }
    ): Promise<UnwrapAOSPayload<ProcessActionCopyRes>> {
        if (getProcessIsDraftFromState(processAction.processId, getState, { intl })) {
            try {
                return api.services.process
                    .insertActionCopy(processAction)()
                    .then((payload) => {
                        dispatch(processActionInsertedAction([...payload.insertedActions]));
                        return payload;
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
        return { duplicatedVariablesWithDifferentProperties: [], insertedActions: [] };
    };
}

/**
 *
 * Inser a copy of a process action step
 *
 * @param {APIPayload<"process", "copyStep">} processActionStep - process action step to insert copy
 */
export function processActionStepInsertCopy(
    processActionStep: UnwrapAOSPayload<ProcessActionStepCopyReq>,
    processId: ProcessId
): StandardThunk<ProcessAction | undefined> {
    return async function (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, intl }): Promise<ProcessAction | undefined> {
        if (getProcessIsDraftFromState(processId, getState, { intl })) {
            try {
                return api.services.process
                    .insertStepCopy(processActionStep)()
                    .then((res) => {
                        dispatch(processActionUpdatedAction([{ ...res }]));
                        return { ...res };
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
        return undefined;
    };
}

/**
 *
 * Reorder steps in action
 *
 * @param {APIPayload<"process", "reorderStep">} processActionSteps - process action step to be reorder
 */
export function processActionStepReorder(
    processActionSteps: UnwrapAOSPayload<ProcessActionStepReorderReq>,
    processId: ProcessId
): StandardThunk<ProcessAction | undefined> {
    return async (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, intl }): Promise<ProcessAction | undefined> => {
        if (getProcessIsDraftFromState(processId, getState, { intl })) {
            try {
                return api.services.process
                    .reorderStep(processActionSteps)()
                    .then((res) => {
                        dispatch(processActionUpdatedAction([{ ...res }]));
                        return { ...res };
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
        return undefined;
    };
}

/**
 * Insert a process action
 *
 * @param {APIPayload<"process", "insertAction">} processAction - process action to insert
 */
export function processActionInsert(processAction: UnwrapAOSPayload<ProcessActionInsertReq>): StandardThunk<void> {
    return async (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, intl }): Promise<void> => {
        if (getProcessIsDraftFromState(processAction.processId, getState, { intl })) {
            try {
                await api.services.process
                    .insertAction(processAction)()
                    .then((actions) => dispatch(processActionInsertedAction([...actions])));
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
    };
}

/**
 * Insert a process action step
 *
 * @param {APIPayload<"process", "insertStep">} param - process action step to insert
 */
export function processActionStepInsert(param: UnwrapAOSPayload<ProcessActionStepInsertReq>): StandardThunk<ProcessAction | undefined> {
    return async (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, intl }): Promise<ProcessAction | undefined> => {
        if (getProcessIsDraftFromState(param.processAction.processId, getState, { intl })) {
            try {
                return api.services.process
                    .insertStep(param)()
                    .then((res) => {
                        dispatch(processActionUpdatedAction([{ ...res }]));
                        return res;
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
        return undefined;
    };
}

/**
 * Update a process action
 *
 * @param {APIPayload<"process", "updateAction">} processActions - process action to update
 */
export function processActionUpdate(processActions: UnwrapAOSPayload<ProcessActionUpdateReq>): StandardThunk<void> {
    return async function (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, intl }): Promise<void> {
        // TODO: Nico commprendre pourquoi j'ai été obligé de mettre processActions[0] && pour éviter le crash.
        if (processActions[0] && getProcessIsDraftFromState(processActions[0].processId, getState, { intl })) {
            try {
                await api.services.process
                    .updateAction(processActions)()
                    .then((updatedProcessActions) => {
                        dispatch(processActionUpdatedAction([...updatedProcessActions]));
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
    };
}

/**
 * Update a process action step
 *
 * @param {APIPayload<"process", "updateStep">} param - process action step to update
 */
export function processActionStepUpdate(param: UnwrapAOSPayload<ProcessActionStepUpdateReq>): StandardThunk<ProcessAction | undefined> {
    return async function (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, intl }): Promise<ProcessAction | undefined> {
        if (getProcessIsDraftFromState(param.processAction.processId, getState, { intl })) {
            try {
                return api.services.process
                    .updateStep(param)()
                    .then((res) => {
                        dispatch(processActionUpdatedAction([{ ...res }]));
                        return res;
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
        return undefined;
    };
}

/**
 * Get a process
 *
 * @param {ProcessId} processId - process ID
 */
export function processGet(
    processId: ProcessId,
    includeActions = true
): StandardThunk<OrUndefined<Unpack<AppState["process"]["processes"]>>> {
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<Unpack<AppState["process"]["processes"]>>> {
        // Search in store first
        const process = getState().process.processes.find((process) => process.processId === processId);

        if (process && (!includeActions || process.actions.length)) {
            return process;
        }

        try {
            // Search in database
            return api.services.process
                .getOne({ processId, includeActions })()
                .then((process) => {
                    if (process) {
                        dispatch(processUpdatedAction(process));
                        return process;
                    }

                    return void 0;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return void 0;
    };
}

/**
 * Insert a process
 *
 * @param {APIPayload<"process", "insert">} process - process to insert
 */
export function processInsert(
    process: APIPayload<"process", "insert">
): StandardThunk<OrUndefined<Unpack<AppState["process"]["processes"]>>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<Unpack<AppState["process"]["processes"]>>> => {
        try {
            return api.services.process
                .insert(process)()
                .then((insertedProcess) => {
                    if (insertedProcess) {
                        dispatch(processInsertedAction(insertedProcess));
                        return insertedProcess;
                    }

                    return void 0;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return void 0;
    };
}

/**
 * Set edited process
 */
export function processSetEditedProcessId(processId: OrUndefined<ProcessUiModel["processId"]>): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(setEditedProcessIdAction(processId));
    };
}

/**
 * Update a process
 *
 * @param {APIPayload<"process", "update">} process - process to update
 */
export function processUpdate(process: APIPayload<"process", "update">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.process
                .update(process)()
                .then((updatedProcess) => {
                    dispatch(processUpdatedAction(updatedProcess));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}

/**
 * Update the training of a process version
 *
 * @param {APIPayload<"process", "update">} process - process to update
 */
export function processUpdateVersionTraining(
    process: APIPayload<"process", "updateVersionTraining">
): StandardThunk<ProcessUiModel | undefined> {
    return (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<ProcessUiModel | undefined> =>
        api.services.process
            .updateVersionTraining(process)()
            .then(
                (updatedProcess) => {
                    dispatch(processUpdatedAction(updatedProcess));

                    return updatedProcess;
                },
                (error) => {
                    handleAPIError(error, dispatch);

                    return undefined;
                }
            );
}

/**
 * Update the standard time of a process
 *
 * @param {APIPayload<"process", "update">} process - process to update
 */
export function processUpdateStandardTime(process: APIPayload<"process", "updateStandardTime">): StandardThunk<ProcessUiModel | undefined> {
    return (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<ProcessUiModel | undefined> =>
        api.services.process
            .updateStandardTime(process)()
            .then(
                (updatedProcess) => {
                    dispatch(processUpdatedAction(updatedProcess));

                    return updatedProcess;
                },
                (error) => {
                    handleAPIError(error, dispatch);

                    return undefined;
                }
            );
}

/**
 * Delete a process variable
 *
 * @param {APIPayload<"process", "deleteVariable">} processVariable - process action to delete
 */
export function processVariableDelete(processVariable: APIPayload<"process", "deleteVariable">): StandardThunk<void> {
    return async function (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, intl }): Promise<void> {
        if (getProcessIsDraftFromState(processVariable.processId, getState, { intl })) {
            try {
                await api.services.process
                    .deleteVariable(processVariable)()
                    .then((process) => {
                        dispatch(processUpdatedAction(process));
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
    };
}

/**
 * Insert a process variable
 *
 * @param {APIPayload<"process", "insertVariable">} processVariable - process action to insert
 */
export function processVariableInsert(
    processVariable: APIPayload<"process", "insertVariable">
): StandardThunk<OrUndefined<Unpack<Unpack<AppState["process"]["processes"]>["variables"]>>> {
    return async (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api, intl }
    ): Promise<OrUndefined<Unpack<Unpack<AppState["process"]["processes"]>["variables"]>>> => {
        if (getProcessIsDraftFromState(processVariable.processId, getState, { intl })) {
            try {
                return api.services.process
                    .insertVariable(processVariable)()
                    .then((process) => {
                        if (process) {
                            dispatch(processUpdatedAction(process));
                            return process.variables[process.variables.length - 1];
                        }
                        return void 0;
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }

        return void 0;
    };
}

/**
 * Update a process variable
 *
 * @param {APIPayload<"process", "updateVariable">} processVariable - process action to update
 */
export function processVariableUpdate(processVariable: APIPayload<"process", "updateVariable">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api, intl }): Promise<void> => {
        if (getProcessIsDraftFromState(processVariable.processId, getState, { intl })) {
            try {
                await api.services.process
                    .updateVariable(processVariable)()
                    .then((process) => {
                        dispatch(processUpdatedAction(process));
                    });
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
    };
}

/**
 * Where used for process variable
 *
 * @param {APIPayload<"process", "whereUsedVariable">} processVariable - process variable to search
 */
export function processVariableWhereUsed(
    processVariable: UnwrapAOSPayload<ProcessVariableWhereUsedReq>
): StandardThunk<UnwrapAOSPayload<ProcessVariableWhereUsedRes>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ProcessVariableWhereUsedRes>> => {
        try {
            return api.services.process.whereUsedVariable(processVariable)();
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return [];
    };
}

/**
 * Approve a process version
 *
 * @param {APIPayload<"process", "approveVersion">} processVersionApproval - approval info
 */
export function processVersionApprove(processVersionApproval: APIPayload<"process", "approveVersion">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.process
                .approveVersion(processVersionApproval)()
                .then((approvedProcessVersion) => {
                    dispatch(processUpdatedAction(approvedProcessVersion));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}

/**
 * Cancel a process version that has been approved.
 *
 * @param {APIPayload<"process", "cancelVersion">} versionInfo - Info that will allow the version to be cancelled
 */
export function processVersionCancel(versionInfo: APIPayload<"process", "cancelVersion">): StandardThunk<ProcessUiModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<ProcessUiModel | undefined> => {
        try {
            return api.services.process
                .cancelVersion(versionInfo)()
                .then((updatedProcess) => {
                    dispatch(processUpdatedAction(updatedProcess));

                    return updatedProcess;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return undefined;
    };
}

/**
 * Put a process version on hold
 *
 * @param {APIPayload<"process", "holdVersion">} versionInfo - Info that will allow the version to be put on hold
 */
export function processVersionHold(versionInfo: APIPayload<"process", "holdVersion">): StandardThunk<ProcessUiModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<ProcessUiModel | undefined> => {
        try {
            return api.services.process
                .holdVersion(versionInfo)()
                .then((updatedProcess) => {
                    dispatch(processUpdatedAction(updatedProcess));

                    return updatedProcess;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return undefined;
    };
}

/**
 * Get all versions of a process
 *
 * @param {TreeNodeId} treeNodeId - tree node ID
 */
export function processVersionGetAll(
    treeNodeId: TreeNodeId,
    includeActions = false
): StandardThunk<UnwrapAOSPayload<ProcessVersionAllRes>> {
    return async (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ProcessVersionAllRes>> => {
        /* Check in the lookup table if all of the processes related to the treeNodeas been already downloaded */
        if (getState().process.processFromTreeNodeIdLookupTable.indexOf(treeNodeId) !== -1) {
            // Yes, request was already made in the past
            // Don't need to go to the hub
            const processes = getState().process.processes.reduce(
                (accumulator: IProcessUiModel[], process: IProcessUiModel): IProcessUiModel[] => {
                    if (process.treeNodeId === treeNodeId) {
                        accumulator.push(process);
                    }
                    return accumulator;
                },
                []
            );
            return processes;
        }

        // Otherwise, let's go to the hub
        try {
            return api.services.process
                .getAllVersions({ includeActions, treeNodeId })({ timeout: 60_000 })
                .then((processes) => {
                    dispatch(setProcessLookupTableFromTreeNodeIdUpdated(treeNodeId));
                    dispatch(processesUpdatedAction([...processes]));
                    return processes;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return [];
    };
}

/**
 * Get all versions of a process with the same parent
 *
 * @param {TreeNodeId[]} treeNodeIdList - tree node id list
 *
 * @returns {IProcessUiModel[]} - return the processes with the same parent.
 */
export function processVersionGetAllWithSameParent(
    treeNodeIdList: TreeNodeId[]
): StandardThunk<UnwrapAOSPayload<ProcessVersionAllParentRes>> {
    return async (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ProcessVersionAllParentRes>> => {
        let processesFromTreeNodesId: IProcessUiModel[] = [];
        const treeNodesIdInStore: TreeNodeId[] = [];
        const treeNodesIdToFetch: TreeNodeId[] = [];
        for (const treeNodeId of treeNodeIdList) {
            /* Check in the lookup table if all of the processes related to the treeNode as been already downloaded */
            if (getState().process.processFromTreeNodeIdLookupTable.indexOf(treeNodeId) !== -1) {
                // Yes, request was already made in the past
                // Don't need to go to the hub
                treeNodesIdInStore.push(treeNodeId);
            } else {
                //define treeNodesIdToFetch to use it as argument of the function to get the data from the database.
                treeNodesIdToFetch.push(treeNodeId);
            }
        }
        if (treeNodesIdInStore.length > 0) {
            const processesInState = getState().process.processes.filter((process) => treeNodesIdInStore.includes(process.treeNodeId));
            processesFromTreeNodesId = processesFromTreeNodesId.concat(processesInState);
        }
        // Otherwise, let's go to the hub
        if (treeNodesIdToFetch.length > 0) {
            try {
                // Search in database
                const processesInDatabase = await api.services.process
                    .getAllVersionsParent({ treeNodesId: treeNodesIdToFetch })({ timeout: 60_000 }) // Can be long to get
                    .then((processes) => {
                        if (processes) {
                            dispatch(setProcessesLookupTableFromTreeNodesIdUpdated(treeNodesIdToFetch));
                            dispatch(processesUpdatedAction([...processes]));
                            return processes;
                        }
                        return [];
                    });
                processesFromTreeNodesId = processesFromTreeNodesId.concat(processesInDatabase);
            } catch (error) {
                handleAPIError(error, dispatch);
            }
        }
        return processesFromTreeNodesId;
    };
}

/**
 * Get latest versions of a process
 *
 * @param {APIPayload<"process", "ProcessGetLatestVersionReq">} req - tree node & filters
 */
export function processGetLatestVersion(
    req: UnwrapAOSPayload<ProcessGetLatestVersionReq>
): StandardThunk<OrUndefined<UnwrapAOSPayload<ProcessGetLatestVersionRes>>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<UnwrapAOSPayload<ProcessGetLatestVersionRes>>> => {
        try {
            return api.services.process.getLatestVersion(req)();
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return void 0;
    };
}

/**
 * Get all versions of a process
 *
 * @param {APIPayload<"process", "getDraftVersion">} treeNode - tree node
 */
export function processVersionGetDraft(
    treeNode: APIPayload<"process", "getDraftVersion">
): StandardThunk<OrUndefined<Unpack<AppState["process"]["processes"]>>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<Unpack<AppState["process"]["processes"]>>> => {
        try {
            return api.services.process
                .getDraftVersion(treeNode)()
                .then((process) => {
                    dispatch(processUpdatedAction(process));
                    return process;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return void 0;
    };
}

/**
 * Archive a process version
 *
 * @param {APIPayload<"process", "archiveVersion">} processVersion - process version to archived
 */
export function processVersionArchive(processVersion: APIPayload<"process", "archiveVersion">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.process
                .archiveVersion(processVersion)()
                .then((process) => {
                    dispatch(processUpdatedAction(process));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}

/**
 * Archive a process version
 *
 * @param {APIPayload<"process", "insertVersion">} process - process version to insert
 */
export function processVersionInsert(process: APIPayload<"process", "insertVersion">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.process
                .insertVersion(process)({ timeout: 10_000 })
                .then((process) => {
                    dispatch(processInsertedAction(process));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}

/**
 * Recover a process version
 *
 * @param {APIPayload<"process", "recoverVersion">} processDbModelKey - process db model key
 */
export function processVersionRecover(processDbModelKey: APIPayload<"process", "recoverVersion">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.process
                .recoverVersion(processDbModelKey)()
                .then((process) => {
                    dispatch(processUpdatedAction(process));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}

/**
 * Update a process version's release dates
 *
 * @param {APIPayload<"process", "updateVersionReleaseDates">} releasedDates - process version to insert
 */
export function processVersionUpdateReleaseDates(
    releasedDates: APIPayload<"process", "updateVersionReleaseDates">
): StandardThunk<ProcessUiModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<ProcessUiModel | undefined> => {
        try {
            return api.services.process
                .updateVersionReleaseDates(releasedDates)({ timeout: 20000 })
                .then((process) => {
                    dispatch(processUpdatedAction(process));
                    return process;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            return undefined;
        }
    };
}

/**
 * Get Bom
 *
 * @param {APIPayload<"erp", "ErpGetBomReq">} req - BOM id and rev
 */
export function processGetBom(req: UnwrapAOSPayload<ErpGetBomReq>): StandardThunk<OrUndefined<UnwrapAOSPayload<ErpGetBomRes>>> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<OrUndefined<UnwrapAOSPayload<ErpGetBomRes>>> => {
        try {
            return await api.services.erp.getBom(req)({ timeout: 10000 });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return void 0;
    };
}

/**
 * Update a job process version
 *
 * @param {APIPayload<"process", "updateJobProcessNewProcessVersion">} req - job process new process version
 */
export function processJobProcessNewProcessVersionUpdate(
    req: APIPayload<"process", "updateJobProcessNewProcessVersion">
): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        api.services.process
            .updateJobProcessNewProcessVersion(req)()
            .then((jobs) => {
                dispatch(jobUpdatedAction([...jobs]));
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }
            });
    };
}

import { theme } from "@aos/react-components";
import { IBomFollowUpDbModel } from "@kortex/aos-common";
import { makeStyles, Table, TableBody } from "@material-ui/core";
import React, { FC } from "react";

import { Header } from "./header";
import { Row } from "./row";

const useStyles = makeStyles({
    rowEven: {
        backgroundColor: theme.palette.grey[100],
    },
    rowOdd: {
        backgroundColor: theme.palette.grey[200],
    },
});

interface IOwnProps {
    followUps: IBomFollowUpDbModel[];
}

const TraceabilityTable: FC<IOwnProps> = (props) => {
    const { followUps } = props;
    const classes = useStyles();

    return (
        <Table stickyHeader={true}>
            <Header />
            <TableBody>
                {followUps.map((followUp, index) => (
                    <Row
                        classes={{ tableRow: index % 2 === 0 ? classes.rowEven : classes.rowOdd }}
                        followUp={followUp}
                        key={`traceabilityTableRow${index}`}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

export default TraceabilityTable;

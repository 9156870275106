import { KortexDialogConfirmation } from "@aos/react-components";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Typography } from "@material-ui/core";
import React, { FC } from "react";

interface IOwnProps {
    onCancel: () => void;
    onConfirm: () => void;
    open: boolean;
}

const BomFollowUpIncompleteDialog: FC<IOwnProps> = (props) => {
    const { onCancel, onConfirm, open } = props;

    const translate = useTranslate();

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            onCancel={onCancel}
            onConfirm={onConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("player.bom.bomFollowUpIncompleteDialogTitle"),
            }}
        >
            <Typography variant="body1">{translate("player.bom.bomFollowUpIncompleteDialogExplanation")}</Typography>
        </KortexDialogConfirmation>
    );
};

export default BomFollowUpIncompleteDialog;

import { theme } from "@aos/react-components";
import { IGetSerializedItemsByTypeAjustmentRes } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { CircularProgress, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { FC, useState } from "react";

import { useBomContext } from "../../context";
import { NoDataFound } from "../../noDataFound";

import { AdjustmentTypeOperationTable } from "./adjustmentTypeOperationTable";
import { AdjustmentTypeSummary } from "./adjustmentTypeSummary";

const useStyles = makeStyles({
    loadingContainer: {
        alignItems: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "16px 0",
        width: "100%",
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.grey["400"]}`,
        marginBottom: "16px",
    },
});

const BomAdjustmentType: FC = () => {
    const classes = useStyles();
    const { loading, queryResult } = useBomContext();
    const translate = useTranslate();

    const [tabSelected, setTabSelected] = useState(0);

    const handleTabChange = (_: React.SyntheticEvent, newValue: number): void => {
        setTabSelected(newValue);
    };

    return (
        <div>
            {loading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            ) : (queryResult as IGetSerializedItemsByTypeAjustmentRes)?.length ? (
                <>
                    <Tabs className={classes.tabs} onChange={handleTabChange} textColor="primary" value={tabSelected} variant="standard">
                        <Tab id="bomAdjustmentTypeTabSummary" label={translate("bomPage.bomTable.summary")} />
                        <Tab id="bomAdjustmentTypeTabOperations" label={translate("bomPage.bomTable.operations")} />
                    </Tabs>
                    {tabSelected === 0 && (
                        <AdjustmentTypeSummary serialNumberHistories={queryResult as IGetSerializedItemsByTypeAjustmentRes} />
                    )}
                    {tabSelected === 1 && (
                        <AdjustmentTypeOperationTable serialNumberHistories={queryResult as IGetSerializedItemsByTypeAjustmentRes} />
                    )}
                </>
            ) : (
                <NoDataFound />
            )}
        </div>
    );
};

export default BomAdjustmentType;

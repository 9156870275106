import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { IFileInfoDbModel, IWorkInstructionsElementConfig, IWorkInstructionsPDFConfig, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { Paper, Radio, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";

import { useTranslate } from "../../../../../../../../../hooks/useTranslate";
import * as CloudStorage from "../../../../../../../../../utilitites/storage";
import { PDF_PLAYER_HEIGHT } from "../../../../../../../../core/PdfPlayer/PdfPlayer";
import StorageManager from "../../../../../../../StorageManager/StorageManager";
import { VIEW_BOX_HEIGHT, VIEW_BOX_WIDTH } from "../../../WorkInstructionsConstants";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const useStyles = makeStyles({
    dialogContainer: {
        height: "90vh",
    },
    storageDialog: {
        height: "85%",
    },
    optionsTitle: {
        padding: "10px",
    },
    paperContainer: {
        marginTop: "10px",
    },
    allOptionsContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    optionsContainer: {
        display: "flex",
        flexDirection: "column",
    },
    optionContainer: {
        paddingLeft: "20px",
        paddingRight: "40px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    pageNumber: {
        margin: "5px",
        width: "150px",
    },
    textField: {
        width: "75px",
        margin: "5px",
    },
});

export interface IOwnProps {
    onCancel: () => void;
    onSave: (pdfProps: IWorkInstructionsElementConfig<IWorkInstructionsPDFConfig>) => void;
    open: boolean;
    pdfProps: IWorkInstructionsElementConfig<IWorkInstructionsPDFConfig>;
}

export default function WorkInstructionsPDFEditor(props: IOwnProps): JSX.Element {
    const { open, onCancel, onSave, pdfProps } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [selectedFile, setSelectedFile] = useState<IFileInfoDbModel | undefined>(undefined);

    const [importAsDocument, setImportAsDocument] = useState<boolean>(true);
    const [importAsSteps, setImportAsSteps] = useState<boolean>(false);
    const [importAllPages, setImportAllPages] = useState<boolean>(true);
    const [importSelectedPages, setImportSelectedPages] = useState<boolean>(false);
    const [selectedPagesString, setSelectedPagesString] = useState<string>("");
    const [selectedPagesError, setSelectedPagesError] = useState<string>("");
    const [maxPages, setMaxPages] = useState<number>(1);
    const [documentWidth, setDocumentWidth] = useState<number>(100);
    const [documentHeight, setDocumentHeight] = useState<number>(100);

    useStopKeybindPropagation(open);

    /**
     * Called when dialog's cancel button is clicked
     */
    const handleCancel = (): void => {
        onCancel();
    };

    /**
     * Called when dialog's save button is clicked
     */
    const handleSave = (): void => {
        if (selectedFile && !selectedPagesError) {
            const dimensionRatio = (VIEW_BOX_HEIGHT - PDF_PLAYER_HEIGHT) / documentHeight;
            const newWidth = documentWidth * dimensionRatio;
            const newHeight = documentHeight * dimensionRatio + PDF_PLAYER_HEIGHT;
            const xPos = (VIEW_BOX_WIDTH - newWidth) / 2;

            onSave({
                ...{
                    ...pdfProps,
                    x: xPos,
                    y: 0,
                    width: newWidth,
                    height: newHeight,
                },
                extendedProps: {
                    ...pdfProps.extendedProps,
                    url: selectedFile.fileId,
                    allPages: importAllPages,
                    totalPages: maxPages,
                    selectedPages: convertRangeStringToArray(selectedPagesString),
                    rotate: 0,
                    originalWidth: documentWidth,
                    originalHeight: documentHeight,
                },
            });
        }
    };

    /**
     * Handle when user select a file
     *
     * @param {IFileInfoDbModel} file - selected file
     */
    const handleFileSelected = (file: IFileInfoDbModel): void => {
        setSelectedFile(file);
    };

    /**
     * Called when PDF Document is loaded sucessfully.. use to get the number of pages
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleLoadDocumentSuccess = (document: any): void => {
        const { numPages } = document;
        setMaxPages(numPages);
    };

    /**
     * Page is loaded sucessfully.. use to get the page size
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleLoadPageSuccess = (page: any): void => {
        setDocumentHeight(page.originalHeight);
        setDocumentWidth(page.originalWidth);
    };

    /**
     * User select import PDF as a document
     */
    const handleImportPDFAsDocument = (): void => {
        setImportAsDocument(true);
        setImportAsSteps(false);
    };

    /**
     * User select import PDF in multiple steps
     */
    const handleImportPDFAddSteps = (): void => {
        setImportAsDocument(false);
        setImportAsSteps(true);
    };

    /**
     * User select import All PDF pages
     */
    const handleImportPDFAllPages = (): void => {
        setImportAllPages(true);
        setImportSelectedPages(false);
        setSelectedPagesError(""); // clear error
        setSelectedPagesString("");
    };

    /**
     * User select import only selected PDF pages
     */
    const handleImportPDFSelectedPages = (): void => {
        setImportAllPages(false);
        setImportSelectedPages(true);
    };

    /**
     * Utility function that convert range string to an array..
     * *** String shall be validated first
     *
     * @param {string} rangeString - string to convert
     * @returns {number[]} - array containing list of values
     */
    const convertRangeStringToArray = (rangeString: string): number[] => {
        const retArray: number[] = [];

        const groupStringArray: string[] = rangeString.split(",");
        for (const groupString of groupStringArray) {
            const rangeStringArray: string[] = groupString.split("-");
            if (rangeStringArray.length === 1) {
                retArray.push(+rangeStringArray[0]);
            } else if (rangeStringArray.length === 2) {
                for (let count: number = +rangeStringArray[0]; count <= +rangeStringArray[1]; count++) {
                    retArray.push(count);
                }
            }
        }
        return retArray;
    };

    /**
     * Returns the highest value in a string
     * ex. 1-3,5,10-13 will return 13
     *
     * @param {string} rangeString - String to evaluate
     * @returns {number} - max value
     */
    const getMaxValue = (rangeString: string): number => {
        let max = 0;
        const groupStringArray: string[] = rangeString.split(",");
        for (const groupString of groupStringArray) {
            const rangeStringArray: string[] = groupString.split("-");
            for (const pageNumber of rangeStringArray) {
                max = Math.max(parseInt(pageNumber), max);
            }
        }
        return max;
    };

    /**
     * Called when the selectedPagesString changes. Validates that the value matches the specified format
     */
    const handleSelectedPagesChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const pattern = /^(\s*\d+\s*\-\s*\d+\s*,?|\s*\d+\s*,?)+$/g; // ex. 1-3,5,10-13
        setSelectedPagesString(value);

        if (getMaxValue(value) > maxPages) {
            setSelectedPagesError(translate("action.workInstructions.pdf.error.invalidRange"));
        } else if (!pattern.test(value)) {
            setSelectedPagesError(translate("action.workInstructions.pdf.error.invalidFormat"));
        } else {
            setSelectedPagesError("");
        }
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            dialogProps={{
                classes: { paper: classes.dialogContainer },
                fullWidth: true,
                maxWidth: "lg",
            }}
            onCancel={handleCancel}
            onConfirm={handleSave}
            open={open}
            textLabels={{
                titleLabel: translate("action.workInstructions.pdf.title"),
                cancelButtonLabel: translate("action.workInstructions.pdf.cancel"),
                proceedButtonLabel: translate("action.workInstructions.pdf.select"),
            }}
        >
            <React.Fragment>
                <div className={classes.storageDialog}>
                    <StorageManager
                        acceptedFileType="pdf"
                        fileFilter={TreeNodeNodeTypeEnum.PDF}
                        onFileSelected={handleFileSelected}
                        preSelectedFileId={pdfProps.extendedProps.url}
                    />
                </div>
                {selectedFile !== undefined && (
                    <Paper className={classes.paperContainer}>
                        <div hidden>
                            <Document
                                file={`${CloudStorage.get().host}/${selectedFile.fileId}`}
                                renderMode="none"
                                onLoadSuccess={handleLoadDocumentSuccess}
                            >
                                <Page pageNumber={1} onLoadSuccess={handleLoadPageSuccess} />
                            </Document>
                        </div>
                        <div className={classes.allOptionsContainer}>
                            <Typography className={classes.optionsTitle} variant="subtitle1">
                                {translate("action.workInstructions.pdf.importOptions")}
                            </Typography>
                            <div className={classes.optionsContainer}>
                                <div className={classes.optionContainer}>
                                    <Radio checked={importAsDocument} onChange={handleImportPDFAsDocument} />
                                    <Typography>{translate("action.workInstructions.pdf.asDocument")}</Typography>
                                </div>
                                <div className={classes.optionContainer}>
                                    <Radio checked={importAsSteps} onChange={handleImportPDFAddSteps} disabled />
                                    <Typography>{translate("action.workInstructions.pdf.asSteps")}</Typography>
                                </div>
                            </div>
                            <Typography className={classes.optionsTitle} variant="subtitle1">
                                {translate("action.workInstructions.pdf.pageTitle")}
                            </Typography>
                            <div className={classes.optionsContainer}>
                                <div className={classes.optionContainer}>
                                    <Radio checked={importAllPages} onChange={handleImportPDFAllPages} />
                                    <Typography>
                                        {translate("action.workInstructions.pdf.allPagesStart")}
                                        {maxPages}
                                        {maxPages <= 1
                                            ? translate("action.workInstructions.pdf.page")
                                            : translate("action.workInstructions.pdf.pages")}
                                        {translate("action.workInstructions.pdf.allPagesEnd")}
                                    </Typography>
                                </div>
                                <div className={classes.optionContainer}>
                                    <Radio checked={importSelectedPages} onChange={handleImportPDFSelectedPages} />
                                    <KortexTextField
                                        className={classes.pageNumber}
                                        TextFieldProps={{
                                            disabled: !importSelectedPages,
                                            placeholder: "e.g. 1-5,8,11-13",
                                        }}
                                        value={selectedPagesString}
                                        variant="standard"
                                        onChange={handleSelectedPagesChange}
                                        error={selectedPagesError}
                                        standardErrorMsgs={{
                                            regexNoMatch: translate("action.workInstructions.pdf.error.invalidFormat"),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Paper>
                )}
            </React.Fragment>
        </KortexDialogConfirmation>
    );
}

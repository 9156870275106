import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { BomFollowUpSymptomId, IBomFollowUp, ISerializedItem } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

import { BomFollowUpSymptomSelector } from "../../../BomFollowUpSymptomSelector";
import { serviceMap, ServiceVariant } from "../../utils";

import SerialNumberSelector from "./serialNumberSelector";

const useStyles = makeStyles({
    dialogContent: {
        display: "grid",
        gridGap: "5px",
    },
    newQuantityTextField: {
        width: "170px",
    },
    quantityTextField: {
        width: "170px",
    },
    traceability: {
        display: "flex",
        alignItems: "center",
    },
    item: {
        fontSize: "1rem",
    },
});

interface IOwnProps {
    followUp: IBomFollowUp;
    onClose: () => void;
    open: boolean;
    variant: ServiceVariant;
    treeNodeId?: number;
    jobRefId?: string;
    isQuantityDecimal: boolean;
}

const ReplacementDialog: FC<IOwnProps> = (props) => {
    const { followUp, onClose, open, variant, treeNodeId, jobRefId, isQuantityDecimal } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const [justification, setJustification] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [quantity, setQuantity] = useState<number>(followUp.quantity);
    const [traceability, setTraceability] = useState<string>(followUp.traceability);
    const [bomFollowUpSymptomId, setBomFollowUpSymptomId] = useState<BomFollowUpSymptomId>(0);
    const [newSerialNumbers, setNewSerialNumbers] = useState<ISerializedItem[]>([]);

    const serializedItemsQuantity = followUp.serializedItems.length;
    const serialNumbersRemovedQuantity = newSerialNumbers.length;

    // Check if quantities are equal between rows and serialized items
    const checkQuantitiesEquality = (): boolean => {
        return (
            // if quantities are equal ex: 3/3
            (followUp.quantity === quantity && serialNumbersRemovedQuantity === serializedItemsQuantity) ||
            // if quantities are not equal 2/3
            (followUp.quantity !== quantity &&
                serializedItemsQuantity > 0 &&
                serializedItemsQuantity <= followUp.quantity &&
                serializedItemsQuantity - serialNumbersRemovedQuantity <= followUp.quantity - quantity &&
                serialNumbersRemovedQuantity <= quantity)
        );
    };

    // validate loading to avoid double clicking the confirm button
    // validate traceability is filled
    // validate justification is filled
    // validate that a symptom is selected
    // if there is a secondary traceability validate that the secondary traceabilities are correctly managed.
    const confirmButtonDisabled =
        loading ||
        traceability.length === 0 ||
        justification.length < 3 ||
        bomFollowUpSymptomId === 0 ||
        (serializedItemsQuantity > 0 && !checkQuantitiesEquality());

    const minimumQuantity = followUp.quantity === 0 ? 0 : 1e-6;

    /**
     * Resets the fields when the dialog is opened
     */
    useEffect(() => {
        if (open) {
            setJustification("");
            setTraceability(followUp.traceability);
            setQuantity(followUp.quantity);
            setBomFollowUpSymptomId(0);
            setNewSerialNumbers([]);
        }
    }, [open]);

    /**
     * Closes the dialog
     */
    const handleClose = (): void => {
        setBomFollowUpSymptomId(0);
        onClose();
    };

    /**
     * Proceeds to replace the item
     */
    const handleConfirm = (): void => {
        setLoading(true);

        dispatch(
            serviceMap[variant].replaceItem({
                bomFollowUpId: followUp.bomFollowUpId,
                quantity,
                traceability,
                justification,
                bomFollowUpSymptomId,
                serializedItems: newSerialNumbers,
            })
        )
            .then(handleClose)
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Updates the justification (inner state)
     */
    const handleJustificationChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setJustification(event.target.value);
    };

    /**
     * Updates the traceability (inner state)
     */
    const handleTraceabilityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setTraceability(event.target.value);
    };

    /**
     * Increases the quantity of specified traceability
     */
    const handleQuantityChange = (value: number): void => {
        setQuantity(value);
    };

    /**
     * Updates symptom of the changement
     */
    const handleBomFollowUpSymptomChange = (symptomId: BomFollowUpSymptomId): void => {
        setBomFollowUpSymptomId(symptomId);
    };

    /**
     * Serial number change
     */
    const handleSerialNumbersChange = (
        serializedItem: ISerializedItem,
        newSerialNumber: ISerializedItem["serialNumber"],
        uncheck?: boolean
    ): void => {
        const newItemsSerialized = deepClone(newSerialNumbers);
        const index = newItemsSerialized.findIndex(
            (serialNumber) => serialNumber.bomFollowUpSerializedItemId === serializedItem.bomFollowUpSerializedItemId
        );

        if (index !== -1) {
            if (uncheck || newSerialNumber.length === 0) {
                // remove
                newItemsSerialized.splice(index, 1);
                setNewSerialNumbers(newItemsSerialized);
            } else {
                // update
                newItemsSerialized[index] = { ...serializedItem, serialNumber: newSerialNumber };
                setNewSerialNumbers(newItemsSerialized);
            }
        } else {
            if (newSerialNumber.length !== 0) {
                // add
                setNewSerialNumbers([
                    ...newItemsSerialized,
                    {
                        ...serializedItem,
                        serialNumber: newSerialNumber,
                    },
                ]);
            }
        }
    };

    /**
     * Render serial numbers
     */
    const renderSerialNumbers = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];
        for (const [serializedItemIndex, serializedItem] of followUp.serializedItems.entries()) {
            if (serializedItem.serialNumber.length > 0) {
                elements.push(
                    <SerialNumberSelector
                        index={serializedItemIndex}
                        serializedItem={serializedItem}
                        onSerialNumbersChange={handleSerialNumbersChange}
                        newSerializedItems={newSerialNumbers}
                        key={serializedItemIndex}
                    />
                );
            }
        }

        return elements;
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={confirmButtonDisabled}
            onCancel={handleClose}
            onConfirm={handleConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("bomPage.bomTable.replacement"),
            }}
            textLabelsIcons={{
                proceedButtonIcon: loading ? <CircularProgress /> : undefined,
            }}
            dialogProps={{ maxWidth: "md" }}
        >
            <div className={classes.dialogContent} id="dialogContentId">
                {/* TRACEABILITY */}
                <div className={classes.traceability}>
                    <KortexTextField
                        label={translate("bomPage.bomTable.traceability")}
                        value={followUp.traceability}
                        variant="outlined"
                        TextFieldProps={{
                            autoComplete: "off",
                            id: "replacementDialogTraceabilityId",
                            disabled: true,
                        }}
                    />
                    <KortexTextField
                        className={classes.quantityTextField}
                        label={translate("bomPage.bomTable.quantity")}
                        value={followUp.quantity}
                        variant="outlined"
                        TextFieldProps={{
                            autoComplete: "off",
                            id: "replacementDialogQuantityId",
                            disabled: true,
                        }}
                    />
                    <KortexTextField
                        label={translate("player.bom.newTraceability")}
                        onChange={handleTraceabilityChange}
                        value={traceability}
                        variant="outlined"
                        TextFieldProps={{
                            autoComplete: "off",
                            id: "replacementDialogNewTraceabilityId",
                            required: true,
                        }}
                    />
                    {/* QUANTITY */}
                    <KortexTextField
                        changedDelayMS={0}
                        className={classes.newQuantityTextField}
                        label={translate("bomPage.bomTable.newQuantity")}
                        min={minimumQuantity}
                        max={followUp.quantity}
                        onChanged={handleQuantityChange}
                        TextFieldProps={{
                            autoComplete: "off",
                            disabled: followUp.quantity === 0,
                            id: "replacementDialogNewQuantityId",
                            required: true,
                        }}
                        type="number"
                        value={quantity}
                        withButtons={quantity > 0}
                        step={isQuantityDecimal ? 0.1 : 1}
                        stepDecimal={isQuantityDecimal ? 6 : 1}
                    />
                </div>

                {renderSerialNumbers()}

                {/* BOM FOLLOW-UP SYMPTOMS */}
                <BomFollowUpSymptomSelector
                    classes={{ item: classes.item }}
                    onChange={handleBomFollowUpSymptomChange}
                    treeNodeId={treeNodeId}
                    jobRefId={jobRefId}
                    value={bomFollowUpSymptomId}
                    KortexTextFieldProps={{ required: true }}
                />

                {/* JUSTIFICATION */}
                <KortexTextField
                    label={translate("bomPage.bomTable.justification")}
                    onChange={handleJustificationChange}
                    TextFieldProps={{
                        autoComplete: "off",
                        id: "replacementDialogJustificationId",
                        multiline: true,
                        rows: 5,
                        required: true,
                    }}
                    value={justification}
                    variant="outlined"
                />
            </div>
        </KortexDialogConfirmation>
    );
};

export default ReplacementDialog;

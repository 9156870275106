export const erp = {
    rpc: {
        getBom: {
            funcId: 15001,
            funcName: "erpGetBom",
        },
        getWoBom: {
            funcId: 15002,
            funcName: "erpGetWoBom",
        },
        // We do not use it, keep to remember to not use the same funcId
        /*getBomPlayer: {
            funcId: 15003,
            funcName: "erpGetBomPlayer",
        },*/
        getBomByJob: {
            funcId: 15004,
            funcName: "erpGetBomByJob",
        },
        getBomByTrackingId: {
            funcId: 15005,
            funcName: "erpGetBomByTrackingId",
        },
        getItemsByPartNumber: {
            funcId: 15006,
            funcName: "erpGetItemsByPartNumber",
        },
        getItemsByTraceability: {
            funcId: 15007,
            funcName: "erpGetItemsByTraceability",
        },
        getItemsByAdjustmentType: {
            funcId: 15008,
            funcName: "erpGetItemsByAdjustmentType",
        },
        getItemsBySecondaryTraceabilitySerialNumber: {
            funcId: 15009,
            funcName: "erpGetItemsBySecondaryTraceabilitySerialNumber",
        },
        getSerializedItemsByAdjustmentType: {
            funcId: 15010,
            funcName: "erpGetSerializedItemsByAdjustmentType",
        },
    },
    serviceId: 150,
    serviceVersion: 1,
};

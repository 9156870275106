import { KortexTextField, theme } from "@aos/react-components";
import { Button, Dialog, DialogActions, DialogContent, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    root: {},
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
    },
    mainIcon: {
        fontSize: "40px",
        marginRight: "20px",
        color: theme.palette.primary[500],
    },
    runAgainCheck: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 30px",
    },
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
        width: "100%",
    },
    buttonIcons: {
        marginRight: "10px",
    },
    processTitle: {
        color: theme.palette.primary[500],
    },
    message: {
        marginTop: "8px",
        width: "100%",
        margin: "0px",
    },
});

interface IOwnProps {
    // Own Props
    open: boolean;
    message?: string;
    title?: string;

    onMessageChange: (message: string) => void;
    onContinue: () => void;
    onCancel: () => void;
}

export default function MessageDialog(props: IOwnProps): JSX.Element {
    const { message, open, title } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [newMessage, setNewMessage] = useState<string>("");

    /**
     * gets message if message is not empty
     */
    useEffect((): void => {
        setNewMessage(message ? message : "");
    }, [message]);

    /**
     * handle the message change
     */
    const handleMessageChange = (value: string): void => {
        props.onMessageChange(value);
    };

    /**
     * handle the continue
     */
    const handleContinue = (): void => {
        if (props.onContinue) {
            props.onContinue();
        }
    };

    /**
     * handle the continue
     */
    const handleCancel = (): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} disableAutoFocus={true} fullWidth={true} id="stepMessageDialogId">
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogDescription}>
                        <Typography variant="h4">{translate("player.stepMessage")}</Typography>
                        <Typography variant="h6">{title}</Typography>
                        <KortexTextField
                            TextFieldProps={{ id: "multilineMessageId", multiline: true, rows: 8 }}
                            className={classes.message}
                            value={newMessage}
                            onChanged={handleMessageChange}
                        />
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        id="cancelButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}
                        className={classes.dialogButtons}
                    >
                        <Typography>{translate("player.cancel")}</Typography>
                    </Button>
                    <Button
                        id="proceedButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleContinue}
                        className={classes.dialogButtons}
                        disabled={!Boolean(newMessage)}
                    >
                        <Typography>{translate("player.continue")}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

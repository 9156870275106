import { theme } from "@aos/react-components";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles({
    root: {
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.grey["400"]}`,
        display: "flex",
        justifyContent: "center",
        padding: "16px",
    },
});

const NoDataFound: FC = () => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.root}>
            <Typography variant="body1">{translate("bomPage.noDataFound")}</Typography>
        </div>
    );
};

export default NoDataFound;

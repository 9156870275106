import { KortexTextField, theme } from "@aos/react-components";
import { BomFollowUpHistoryTraceabilityType } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Button, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { IFilters, useBomContext } from "../context/bomContext";

import FilterMenu from "./filterMenu/filterMenu";
import { isSearchTypeAdjustmentType, useAdjustmentTypeMenuItems, useSearchTypeMenuItems } from "./utils";

const useStyles = makeStyles({
    root: {
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.grey["400"]}`,
        display: "inline-flex",
        gap: "10px",
        paddingBottom: "16px",
        width: "100%",
    },
    spacer: {
        flex: 1,
    },
    textField: {
        width: "300px",
    },
});

const BomHeader: FC = () => {
    const { createDefaultSearch, loading, search, setSearch, updateQuery } = useBomContext();
    const adjustmentTypeMenuItems = useAdjustmentTypeMenuItems(search.type);
    const classes = useStyles();
    const searchTypeMenuItems = useSearchTypeMenuItems();
    const translate = useTranslate();

    const isGenerateButtonDisabled =
        !String(search.filters[search.type]).trim() || !(search.filters[search.type] as string)?.trim() || loading;

    /**
     * Assigns adjustment to the search value
     */
    const handleAdjustmentChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setSearch((prevState) => ({
            ...prevState,
            filters: {
                dateFrom: prevState.filters.dateFrom,
                dateTo: prevState.filters.dateTo,
                [prevState.type]: event.target.value as BomFollowUpHistoryTraceabilityType,
            },
        }));
    };

    /**
     * TODO: Does something when the "Export" button is clicked (TODO: AOS-2550)
     */
    /*
    const handleExport = (): void => {
        // TODO: Do stuff...
        console.log("Export");
    };
    */

    /**
     * Sends search infos to context
     */
    const handleGenerate = (): void => {
        updateQuery();
    };

    /**
     * Generate a report on Enter
     */
    const handleKeyDown = (event: React.KeyboardEvent): void => {
        event.stopPropagation();

        if (event.key === "Enter") {
            event.preventDefault();
            handleGenerate();
        }
    };

    /**
     * Updates the search value
     */
    const handleSearchChange = (value: number | string): void => {
        setSearch((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [prevState.type]: value,
            },
        }));
    };

    /**
     * Updates the search type
     */
    const handleSearchTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const type = event.target.value as keyof IFilters;
        const defaultSearchFilters = createDefaultSearch().filters;

        const filters = {
            ...defaultSearchFilters,
            dateFrom: search.filters.dateFrom,
            dateTo: search.filters.dateTo,
        };

        if (isSearchTypeAdjustmentType(type)) {
            filters[type] = "replacement";
        }

        setSearch({
            type,
            filters,
        });
    };

    return (
        <div className={classes.root}>
            {/* SEARCH TYPE SELECTOR*/}
            <KortexTextField
                className={classes.textField}
                label={translate("bomPage.searchType")}
                onChange={handleSearchTypeChange}
                TextFieldProps={{
                    id: "bomHeaderSearchTypeId",
                    select: true,
                }}
                value={search.type}
            >
                {searchTypeMenuItems}
            </KortexTextField>
            {/* SEARCH VALUE TEXTFIELD */}
            {isSearchTypeAdjustmentType(search.type) ? (
                <KortexTextField
                    className={classes.textField}
                    key="adjustmentTypeSelector" // To prevent MUI warning when switching to 'Adjustment Type' from any other search type
                    label={translate("bomPage.adjustmentType")}
                    onChange={handleAdjustmentChange}
                    TextFieldProps={{
                        id: "bomHeaderSearchAdjustementTypeId",
                        select: true,
                    }}
                    value={search.filters[search.type] as string}
                >
                    {/* Items' value must be one of the 'BomFollowUpHistoryTraceabilityType' */}
                    {adjustmentTypeMenuItems}
                </KortexTextField>
            ) : (
                <KortexTextField
                    changedDelayMS={500}
                    className={classes.textField}
                    key="valueTextField" // To prevent MUI warning when switching to 'Adjustment Type' from any other search type
                    label={translate("bomPage.value")}
                    onChanged={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    TextFieldProps={{
                        autoComplete: "off",
                        id: "bomHeaderSearchId",
                    }}
                    value={search.filters[search.type] ?? ""}
                />
            )}
            <FilterMenu />
            {/* GENERATE BUTTON */}
            <Button color="secondary" disabled={isGenerateButtonDisabled} id="generateBomId" onClick={handleGenerate} variant="outlined">
                {translate("bomPage.generate")}
            </Button>
            {/* SPACER */}
            <div className={classes.spacer} />
            {/* EXPORT BUTTON */}
            {/* 
                TODO: AOS-2550
                <Button color="secondary" onClick={handleExport} variant="outlined">
                    {translate("bomPage.export")}
                </Button>
            */}
        </div>
    );
};

export default BomHeader;

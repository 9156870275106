import { BomFollowUpSerializedItemId, IBomFollowUp, ISerializedItem, IWoBomItem, OrUndefined } from "@kortex/aos-common";
import { MenuType } from "@kortex/aos-ui/components/core/bom";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { processPlayerBomFollowUpInsertFollowUpSerializedItem } from "@kortex/aos-ui/redux/player-manager/player-thunk-bom-followUp";
import { AutoTabField } from "@kortex/aos-ui/utilitites/autoTab";
import { IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { FC } from "react";

import { usePlayerContext } from "../../../context";

const useStyles = makeStyles({
    cellSerialNumber: {
        borderBottom: "none",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    followUp?: IBomFollowUp;
    index: number;
    item: IWoBomItem;
    itemIndex: number;
    onMoreClick: (
        type: MenuType,
        followUp?: IBomFollowUp,
        serializedItem?: ISerializedItem
    ) => (event: React.MouseEvent<HTMLElement>) => void;
    traceabilityIndex: number;
    validateBom: boolean;
}

const SerialNumberRow: FC<IOwnProps> = (props) => {
    const { index, followUp, item, itemIndex, onMoreClick, traceabilityIndex, validateBom } = props;

    const classes = useStyles(props);
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const { jobProcessInfo, playerState } = usePlayerContext();

    const emptyTraceability = !Boolean(followUp?.traceability);
    const emptySerialNumber = !Boolean(followUp?.serializedItems[index]?.serialNumber);
    const emptyPreviousSerialNumber = index !== 0 && !Boolean(followUp?.serializedItems[index - 1]?.serialNumber);
    const error = validateBom && emptySerialNumber;
    const isBomDisabled = !(playerState.processState.bom.enabled && Boolean(jobProcessInfo));
    const editIconDisabled = isBomDisabled || emptyTraceability || emptySerialNumber || emptyPreviousSerialNumber;
    const textfieldDisabled = isBomDisabled || emptyTraceability || !emptySerialNumber || emptyPreviousSerialNumber;

    const handleSerialNumberChange =
        (
            followUpId: OrUndefined<IBomFollowUp["bomFollowUpId"]>,
            bomFollowUpSerializedItemId?: BomFollowUpSerializedItemId
        ): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            const serialNumber = event.target.value;

            if (!followUpId || !serialNumber || serialNumber === followUp?.serializedItems[index]?.serialNumber) return void 0;

            dispatch(
                processPlayerBomFollowUpInsertFollowUpSerializedItem({
                    bomFollowUpId: followUpId,
                    serialNumber,
                    type: bomFollowUpSerializedItemId ? "reassignment" : "standard",
                    bomFollowUpSerializedItemId,
                })
            );
        };

    return (
        <div className={classes.cellSerialNumber}>
            <AutoTabField
                autoTabIndex={[itemIndex, traceabilityIndex, index]}
                error={error ? translate("general.required") : undefined}
                InputProps={{
                    endAdornment: item.serialized ? (
                        <InputAdornment position="end">
                            <IconButton
                                disabled={editIconDisabled}
                                id={`playerBomDialogSerialNumberRowMoreId${item.partNumber}-${index}Id`}
                                onClick={onMoreClick("serial", followUp, followUp?.serializedItems[index])}
                            >
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        <></>
                    ),
                    readOnly: textfieldDisabled,
                }}
                onBlur={handleSerialNumberChange(followUp?.bomFollowUpId, followUp?.serializedItems[index]?.bomFollowUpSerializedItemId)}
                TextFieldProps={{
                    autoComplete: "off",
                    id: `playerBomDialogSerialNumberRowId-${itemIndex}-${traceabilityIndex}-${index}`,
                    placeholder: item.serialized && !textfieldDisabled ? translate("player.bom.serialNumber") : undefined,
                }}
                value={followUp?.serializedItems[index]?.serialNumber ?? ""}
                variant="standard"
            />
        </div>
    );
};

export default SerialNumberRow;

import { useEffect, useRef } from "react";
import shortid from "shortid";

import { AutoTabElement, AutoTabId, useAutoTabContext } from "../autoTabContext";

export function useAutoTab(index: number, id: AutoTabId = shortid.generate()): AutoTabElement {
    const { addElement, removeElement } = useAutoTabContext();

    const autoTabId = useRef<AutoTabElement>([id, index]);

    useEffect(() => {
        // Update inner value
        autoTabId.current = [id, index];

        // Add new ID to the list
        addElement([id, index]);

        return () => {
            // Remove previous ID from the list
            removeElement(autoTabId.current[0]);
        };
    }, [id, index]);

    return autoTabId.current;
}

import { errorPalette, greyPalette, rainbowPalette, successPalette, theme } from "@aos/react-components";
import {
    ActionStepState,
    EnumActionStatus,
    IActionOrderingTable,
    IPlayerActionBaseState,
    IProcessUiModel,
    MIN_TO_SEC,
    ProcessActionId,
    ProcessActionStep,
    getTime,
    orderActionList,
} from "@kortex/aos-common";
import { Typography, makeStyles } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import classNames from "classnames";
import { formatDistanceStrict } from "date-fns";
import Debug from "debug";
import React, { FC, useEffect, useRef, useState } from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import { getActionLabel } from "../../../../../../utilitites/getActionLabel";
import ActionIcon from "../../../../../pages/ProcessEditor/ProcessEditor/ActionSelector/ActionIcon";
import KortexPanelCard from "../../../../KortexPanelCard";
import { usePlayerContext } from "../../../context";

const debug = Debug("kortex:ui:player-page");
debug("Loaded");
Debug.enable("kortex:ui:player-page");

const useStyles = makeStyles({
    actionContent: {
        backgroundColor: theme.palette.primary[500],
        minHeight: "60px",
        padding: "10px 5px",
        display: "flex",
        justifyContent: "space-between",
        color: theme.palette.common.white,
    },
    actionContentIcon: {
        height: "20px",
    },
    actionContentTitle: {
        fontSize: "1.1rem",
        alignSelf: "center",
        marginBottom: "3px",
    },
    actionContentStepCount: {
        alignSelf: "flex-end",
        fontSize: "0.7rem",
    },
    actionContentSteps: {
        padding: "5px 5px 0px 5px",
    },
    stepContent: {
        minHeight: "60px",
        padding: "5px 10px",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
    },
    stepContentPlaying: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    stepContentCompleted: {
        backgroundColor: greyPalette[200],
        color: greyPalette[800],
    },
    stepContentError: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    stepContentHistory: {
        backgroundColor: rainbowPalette[801],
        color: greyPalette[800],
    },
    stepContentIdle: {
        backgroundColor: greyPalette[200],
        color: greyPalette[800],
        cursor: "default",
    },
    stepContainer: {
        overflowY: "auto",
        height: "calc(100vh - 126px)",
    },
    stepTitle: {
        display: "flex",
        justifyContent: "space-between",
        flexGrow: 1,
    },
    stepCheckMark: {
        fontSize: "1.3rem",
        color: successPalette[500],
        fontWeight: "bold",
        marginLeft: "3px",
    },
    stepTime: {
        alignSelf: "flex-end",
        fontSize: "0.7rem",
        marginTop: "3px",
    },
});

interface IOwnProps {
    onActionStepChanged: () => void;
    onRewindTo: (processActionId: ProcessActionId, stepIndex: number) => void;
    playingHistoryIndex: number;
    processWithActions: IProcessUiModel | null;
    playingHistory?: boolean; // are we playing a history snaphot
}

const PlayerControlsTimeline: FC<IOwnProps> = (props) => {
    const { onActionStepChanged, onRewindTo, playingHistoryIndex, playingHistory, processWithActions } = props;

    const { playerState } = usePlayerContext();
    const classes = useStyles();
    const {
        stepContent,
        stepTitle,
        stepCheckMark,
        stepTime,
        actionContent,
        actionContentTitle,
        actionContentStepCount,
        actionContentIcon,
        actionContentSteps,
    } = classes;
    const translate = useTranslate();

    const [currentTime, setCurrentTime] = useState(getTime());
    const [nextRequested, setNextRequested] = useState(false);
    const [startingPathActionId, setStartingPathActionId] = useState(0);
    const [currentPlayedAction, setCurrentPlayedAction] = useState<IPlayerActionBaseState>();
    const [currentPlayedActionStep, setCurrentPlayedActionStep] = useState<ActionStepState>();
    const [playedActions, setPlayedActions] = useState<number[]>([]);
    const [orderedActionTable, setOrderedActionTable] = useState<IActionOrderingTable[]>([]);

    const actionStepToScrollToContainerRef = useRef<HTMLDivElement | null>(null);

    /**
     * Effect that actually scrolls the timeline to the element that holds the ref
     */
    useEffect(() => {
        if (actionStepToScrollToContainerRef.current) {
            actionStepToScrollToContainerRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
    }, [actionStepToScrollToContainerRef.current]);

    /**
     * will be called on mount
     */
    useEffect((): (() => void) => {
        const intervalTimer: ReturnType<typeof setInterval> = setInterval(updateTime, 1000);

        setOrderedActionTable([]);
        setStartingPathActionId(0);

        return (): void => {
            clearInterval(intervalTimer);
        };
    }, []);

    /**
     * will be called on playerState change
     */
    useEffect((): void => {
        let curAction = getCurrentAction();
        let startingActionId = startingPathActionId;
        let curStep = currentPlayedActionStep;

        // Initial position, set it to INPUT
        if (!curAction && processWithActions && startingPathActionId === 0) {
            curAction = playerState.actionsState.find((action: IPlayerActionBaseState): boolean => action.actionType === "core-input");
        }

        // If it's INPUT, update the starting point
        if (curAction && curAction.actionId && curAction.actionType === "core-input") {
            startingActionId = curAction.actionId;
            setStartingPathActionId(startingActionId);
        }

        // If it's FAIL, update the starting point
        if (curAction && curAction.actionId && curAction.actionType === "core-fail") {
            startingActionId = curAction.actionId;
            setStartingPathActionId(startingActionId);
        }

        // Start Ordering
        if (curAction && curAction.actionId && processWithActions) {
            if (startingActionId === 0) {
                startingActionId = curAction.actionId;
                setStartingPathActionId(startingActionId);
            }

            // Order based on the action at the start of the path
            setOrderedActionTable(
                orderActionList(processWithActions.actions, startingActionId, playedActions)
                    // filter out the actions that aren't connected and the input/output
                    .filter((action): boolean => action.playOrder > 0)
            );
        }

        // we found a current action, let's update the current step
        if (curAction) {
            curStep = getCurrentActionStep(curAction);
        }

        // Here to check if step changed, simply check at the start time of the step, it time as changed, it means that it's a new step
        if (curStep?.startTime !== currentPlayedActionStep?.startTime) {
            onActionStepChanged();
        }

        // if it's a new action let's store it to the played actions
        if (curAction) {
            if (curAction.actionId !== currentPlayedAction?.actionId) {
                const newPlayedAction = [...playedActions];
                if (curAction.actionId) {
                    newPlayedAction.push(curAction.actionId);
                    setPlayedActions(newPlayedAction);
                }
            }
            setCurrentPlayedAction(curAction);
        }

        let isNextRequested = false;

        if (curStep) {
            setCurrentPlayedActionStep(curStep);
            // Check if next requested
            isNextRequested = curStep?.status && curStep?.status === EnumActionStatus.WAITING_USER_NEXT;
        }

        if (!playingHistory) {
            // If nextRequested changed
            if (nextRequested !== isNextRequested) {
                setNextRequested(isNextRequested);
            }
        }
    }, [playerState]);

    /**
     * updates the time
     */
    const updateTime = (): void => {
        setCurrentTime(getTime());
    };

    const handleStepClick =
        (actionId: number, stepIndex: number, stepStatus: EnumActionStatus): (() => void) =>
        (): void => {
            // Do nothing if the step has not yet been played
            if (stepStatus === EnumActionStatus.IDLE) {
                return void 0;
            }

            // Do nothing if the runner is processing
            if (!playerState.playNextEnabled) {
                return void 0;
            }

            // Do nothing if it is the step is currently in progress
            if (
                !playingHistory &&
                (stepStatus === EnumActionStatus.PLAYING ||
                    stepStatus === EnumActionStatus.TRAINER_SIGNATURE_REQUIRED ||
                    stepStatus === EnumActionStatus.VALIDATION_REQUIRED ||
                    stepStatus === EnumActionStatus.WAITING_USER_NEXT)
            ) {
                return void 0;
            }

            onRewindTo(actionId, stepIndex);
        };

    /**
     * get the step card
     *
     * @param {number} actionId - process action ID
     * @param {number} stepIndex - action index
     * @param {ProcessActionStep} stepProps - step props
     * @param {ActionStepState} stepState - the current step state
     */
    const getStepContent = (
        actionId: number,
        stepIndex: number,
        stepProps: ProcessActionStep,
        stepState?: ActionStepState
    ): JSX.Element => {
        const stepStatus = stepState?.status ?? EnumActionStatus.IDLE;

        return (
            <div
                className={classNames(stepContent, stepClass(actionId, stepIndex, stepStatus))}
                onClick={handleStepClick(actionId, stepIndex, stepStatus)}
            >
                <div id="playerTimelineStepTitleId" className={stepTitle}>
                    {stepProps.label}
                    {stepStatus === EnumActionStatus.COMPLETED && (
                        <div id="playerTimelineStepCheckedId" className={stepCheckMark}>
                            <DoneIcon />
                        </div>
                    )}
                    {(stepStatus === EnumActionStatus.PLAYING ||
                        stepStatus === EnumActionStatus.TRAINER_SIGNATURE_REQUIRED ||
                        stepStatus === EnumActionStatus.VALIDATION_REQUIRED ||
                        stepStatus === EnumActionStatus.WAITING_USER_NEXT) && (
                        <div id="playerTimelineStepPlayingId" className={stepCheckMark}>
                            <PlayArrowIcon />
                        </div>
                    )}
                </div>
                <div className={stepTime}>
                    {stepState ? (
                        <>
                            {stepElapsedTime(stepState)}{" "}
                            {stepStatus !== EnumActionStatus.PAUSED &&
                                stepState.targetTime >= MIN_TO_SEC &&
                                `/ ${Math.floor(stepState.targetTime / MIN_TO_SEC)} ${translate("units.short.minutes")}`}
                            {stepStatus !== EnumActionStatus.PAUSED &&
                                stepState.targetTime < MIN_TO_SEC &&
                                `/ ${stepState.targetTime} ${translate("units.short.seconds")}`}
                        </>
                    ) : (
                        <>
                            {"0 / "}
                            {stepProps.standardTimeSec >= MIN_TO_SEC &&
                                `${Math.floor(stepProps.standardTimeSec / MIN_TO_SEC)} ${translate("units.short.minutes")}`}
                            {stepProps.standardTimeSec < MIN_TO_SEC && `${stepProps.standardTimeSec} ${translate("units.short.seconds")}`}
                        </>
                    )}
                </div>
            </div>
        );
    };

    /**
     * get the elapsed time of the current and completed steps
     *
     * @param {ActionStepState} stepState - the current step state
     */
    const stepElapsedTime = (stepState: ActionStepState): string => {
        switch (stepState.status) {
            case EnumActionStatus.COMPLETED:
                return formatDistanceStrict(stepState.endTime - stepState.pauseDuration, stepState.startTime, {
                    roundingMethod: "floor",
                });
            case EnumActionStatus.PLAYING:
            case EnumActionStatus.WAITING_USER_NEXT:
            case EnumActionStatus.VALIDATION_REQUIRED:
                return formatDistanceStrict(currentTime - stepState.pauseDuration, stepState.startTime, {
                    roundingMethod: "floor",
                });
            case EnumActionStatus.PAUSED:
                return translate("player.paused");
            default:
                return "0";
        }
    };

    /**
     * gets the history style based on current status
     *
     * @param {number} actionId - action ID
     * @param {number} stepIndex - step index
     * @param {EnumActionStatus} stepStatus - current playing step status
     */
    const stepClass = (actionId: number, stepIndex: number, stepStatus: EnumActionStatus): string => {
        const historyAction = playerState.historyInfo[playingHistoryIndex];

        if (playingHistory && historyAction?.processActionId === actionId && historyAction?.stepIndex === stepIndex) {
            return classes.stepContentHistory;
        } else {
            switch (stepStatus) {
                case EnumActionStatus.PLAYING:
                case EnumActionStatus.WAITING_USER_NEXT:
                case EnumActionStatus.PAUSED:
                    return classes.stepContentPlaying;
                case EnumActionStatus.IDLE:
                    return classes.stepContentIdle;
                case EnumActionStatus.COMPLETED:
                    return classes.stepContentCompleted;
                default:
                    return classes.stepContentError;
            }
        }
    };

    /**
     * gets the history style based on current status
     *
     * @param {string} status - current playing status
     *
     */
    const stepHighlightColor = (status: string): string => {
        switch (status) {
            case EnumActionStatus.PLAYING:
            case EnumActionStatus.WAITING_USER_NEXT:
            case EnumActionStatus.PAUSED:
            case EnumActionStatus.VALIDATION_REQUIRED:
                return theme.palette.secondary[500];
            case EnumActionStatus.IDLE:
                return greyPalette[500];
            case EnumActionStatus.COMPLETED:
                return theme.palette.secondary[500];
            default:
                return errorPalette[400];
        }
    };

    /**
     * Predicate that checks if the element should be scrolled into the view
     *
     * @param {number} actionIndex - element's actionIndex
     * @param {number} actionId - element's actionId
     * @param {number} stepIndex - element's stepIndex
     */
    const shouldBeScrolledIntoView = (actionIndex: number, actionId: number, stepIndex: number): boolean => {
        if (playingHistory) {
            const historyAction = playerState.historyInfo[playingHistoryIndex];

            if (historyAction) {
                return historyAction.processActionId === actionId && historyAction.stepIndex === stepIndex;
            }

            return false;
        } else {
            return playerState.actionsState.length - 1 === actionIndex && playerState.processState.currentStepIndex === stepIndex;
        }
    };

    /**
     * Gets the Action content
     *
     * @param {IActionOrderingTable} action - process action
     * @param {number} actionIndex - action index
     */
    const getActionContent = (action: IActionOrderingTable, actionIndex: number): JSX.Element => {
        const processAction = processWithActions?.actions.find((inAction) => action.actionId === inAction.processActionId);

        if (!processAction) {
            return <></>;
        }

        return (
            <div key={actionIndex}>
                <div id="playerTimelineActionId" className={actionContent}>
                    <div>
                        <div id="playerTimelineActionTitleId" className={actionContentTitle}>
                            <Typography>{translate(getActionLabel(processAction.type))}</Typography>
                        </div>
                        <div className={actionContentStepCount}>
                            <Typography variant="caption">{`${processAction.steps.length} ${translate(
                                processAction.steps.length > 1 ? "player.steps" : "player.step" // FIXME: Was not localized
                            )}`}</Typography>
                        </div>
                    </div>
                    <div className={actionContentIcon}>
                        <ActionIcon type={processAction.type} />
                    </div>
                </div>
                <div id="playerTimelineActionContentStepsId" className={actionContentSteps}>
                    {processAction.steps.map((stepProps, stepIndex): JSX.Element => {
                        const stepState = playerState.actionsState.find((inAction) => inAction.actionId === action.actionId)?.baseStates
                            ?.stepsState[stepIndex];
                        stepState?.status;
                        return (
                            <KortexPanelCard
                                key={stepIndex}
                                statusColor={stepHighlightColor(stepState?.status ?? EnumActionStatus.IDLE)}
                                ref={
                                    shouldBeScrolledIntoView(actionIndex, action.actionId ?? 0, stepIndex)
                                        ? actionStepToScrollToContainerRef
                                        : undefined
                                }
                                isSelected={false}
                                padding={0}
                            >
                                {getStepContent(action.actionId ?? 0, stepIndex, stepProps, stepState)}
                            </KortexPanelCard>
                        );
                    })}
                </div>
            </div>
        );
    };

    /**
     * gets the currently played action
     */
    const getCurrentAction = (): IPlayerActionBaseState | undefined => {
        return playerState.actionsState.find(
            (action: IPlayerActionBaseState): boolean =>
                action.baseStates.status === EnumActionStatus.PLAYING ||
                action.baseStates.status === EnumActionStatus.PAUSED ||
                action.baseStates.status === EnumActionStatus.TRAINER_SIGNATURE_REQUIRED ||
                action.baseStates.status === EnumActionStatus.WAITING_USER_NEXT
        );
    };

    /**
     * gets the currently played action
     */
    const getCurrentActionStep = (action: IPlayerActionBaseState): ActionStepState | undefined => {
        let step = action.baseStates.stepsState.find(
            (step: ActionStepState): boolean =>
                step.status === EnumActionStatus.PLAYING ||
                step.status === EnumActionStatus.PAUSED ||
                step.status === EnumActionStatus.TRAINER_SIGNATURE_REQUIRED ||
                step.status === EnumActionStatus.WAITING_USER_NEXT
        );
        if (!step) {
            step = [...action.baseStates.stepsState]
                .reverse()
                .find((step: ActionStepState): boolean => step.status === EnumActionStatus.COMPLETED);
        }
        return step;
    };

    return <div className={classes.stepContainer}>{orderedActionTable.map((action, index) => getActionContent(action, index))}</div>;
};

export default PlayerControlsTimeline;

import { getPageUrl } from "@kortex/aos-ui/configs/menu";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    tableCell: {
        padding: "8px 4px",
    },
    tableRow: {},
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    index: number;
    partNumber: string;
    quantity: number;
}

const Row: FC<IOwnProps> = (props) => {
    const { index, partNumber, quantity } = props;

    const classes = useStyles(props);

    return (
        <TableRow className={classes.tableRow} id="bomFollowUpPageAdjustmentTypeSerialNumberSummaryPartNumberTableRowId">
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeSerialNumberSummaryPartNumberTableRowQuantityId-${index}`} variant="body2">
                    {quantity}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeSerialNumberSummaryPartNumberTableRowPartNumberId-${index}`} variant="body2">
                    <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=partNumber&partNumber=${partNumber}`}>{partNumber}</Link>
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default Row;

import { IVersion } from "@kortex/aos-common";

export const version: IVersion = {
    major: 1,
    minor: 20,
    patch: 0,
    pre: "dev",
    build: 978,
    meta: "",
};

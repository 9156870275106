import { KortexDialogLogin, KortexOutlinedTextField } from "@aos/react-components";
import { ITokenDecoded, LocationKeys, mangleURL } from "@kortex/aos-common";
import { defaultFrontPage, getPageUrl } from "@kortex/aos-ui/configs/menu";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { default as React, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import appConfigs from "../../../configs/app";
import { useLocalStorage } from "../../../hooks/useStorage";
import { useTranslate } from "../../../hooks/useTranslate";
import { EnumLocalStorageItem } from "../../../redux/EnumLocalStorageItem";
import { setLanguage } from "../../../redux/general-manager/general-thunks-general";
import { useSelectorLanguage, useSelectorUserSession } from "../../../redux/selectors";
import { login } from "../../../redux/user-manager/UserActions";
import { isDesktopMode } from "../../../utilitites/desktopUtils";
import getUrlParameter from "../../../utilitites/getUrlParameter";

const HUB_HOST_INSTANCE_PROD = "https://hub.aos.kinovaapps.com";
const HUB_HOST_INSTANCE_DEV = "https://hub.aos-dev.kinovaapps.com";
const HUB_HOST_INSTANCE_STAGING = "https://hub.aos-staging.kinovaapps.com";
const HUB_HOST_INSTANCE_TEST = "http://hub.aos-test.kinovaapps.com";
const HUB_HOST_PREFIX_SECURED = "https://hub.";
const HUB_HOST_PREFIX_UNSECURED = "http://hub.";

export default function LoginPage(): JSX.Element {
    const dispatch = useThunkDispatch();
    const navigate = useNavigate();
    const language = useSelectorLanguage();
    const location = useLocation();
    const session = useSelectorUserSession();
    const translate = useTranslate();

    const [defaultUserName, setDefaultUserName] = useLocalStorage(EnumLocalStorageItem.USER_NAME, "");
    const [defaultInstance, setDefaultInstance] = useLocalStorage(EnumLocalStorageItem.INSTANCE, "");

    /** Called on Mount */
    useEffect((): void => {
        // Automatic login only if the app just opened (to prevent auto-login after a logout)
        if (!session) {
            const search: string | undefined = location.state?.from.search;
            let instance = getUrlParameter("instance", search);
            let username = getUrlParameter("username", search);
            const password = getUrlParameter("password", search);
            const currentUrl = mangleURL(window.location.href);
            const isLocalhost = currentUrl.host === "localhost";

            if (instance) {
                setDefaultInstance(instance);
            } else if (!isLocalhost) {
                if (isDesktopMode) {
                    if (process.env.NODE_ENV === "production") {
                        instance = HUB_HOST_INSTANCE_PROD;
                    } else if (process.env.NODE_ENV === "staging") {
                        instance = HUB_HOST_INSTANCE_STAGING;
                    } else {
                        instance = HUB_HOST_INSTANCE_DEV;
                    }
                    setDefaultInstance(instance);
                } else {
                    // FIXME: PATCH FOR AUTOMATED TEST
                    if (currentUrl.host === "aos-test.kinovaapps.com") {
                        instance = HUB_HOST_INSTANCE_TEST;
                    } else {
                        instance = currentUrl.secured
                            ? HUB_HOST_PREFIX_SECURED + currentUrl.host
                            : HUB_HOST_PREFIX_UNSECURED + currentUrl.host;
                    }
                    instance = currentUrl.secured ? HUB_HOST_PREFIX_SECURED + currentUrl.host : HUB_HOST_PREFIX_UNSECURED + currentUrl.host;
                    setDefaultInstance(instance);
                }
            } else {
                instance = defaultInstance;
            }

            if (username) {
                setDefaultUserName(username as "");
            } else {
                username = defaultUserName;
            }

            if (username && password && instance) {
                // FIXME: add api ready var in redux store
                setTimeout(async () => {
                    redirect(await dispatch(login(username, password, instance)));
                }, 500);
            }
        }
    }, []);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        const userName = event.currentTarget.elements.namedItem("usernameInputId") as HTMLInputElement;
        const password = event.currentTarget.elements.namedItem("passwordInputId") as HTMLInputElement;
        const instance = event.currentTarget.elements.namedItem("instanceInputId") as HTMLInputElement;

        setDefaultUserName(userName.value);
        setDefaultInstance(instance.value);

        redirect(await dispatch(login(userName.value, password.value, instance.value)));
    };

    /**
     * Redirect the app to a page of the app.
     * Use this function after a login.
     */
    const redirect = (token?: ITokenDecoded): void => {
        if (!token) return void 0;

        const from = location?.state?.from;
        const defaultPage = defaultFrontPage(token, true);
        const defaultPageLink = getPageUrl(defaultPage);

        if (defaultPage === EnumPageTypes.PASSWORD) {
            return navigate(defaultPageLink, { replace: true, state: { from } });
        }

        if (from) {
            if (from.pathname === "/") {
                return navigate(defaultPageLink, { replace: true });
            } else {
                return navigate(from, { replace: true });
            }
        }

        return navigate(defaultPageLink, { replace: true });
    };

    const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        dispatch(setLanguage(event.target.value as LocationKeys));
    };

    return (
        <KortexDialogLogin
            textLabels={{
                connectButtonLabel: translate("login.connect"),
                passwordLabel: translate("login.password"),
                usernameLabel: translate("login.username"),
                titleLabel: "Kortex™ AOS",
                languageLabel: translate("login.language"),
                accordionLabel: translate("login.advsettings"),
            }}
            onConnect={handleLogin}
            open={true}
            username={defaultUserName}
            acceptedLanguages={appConfigs.acceptedLanguages.map((lang) => {
                return {
                    key: lang,
                    label: translate(lang),
                };
            })}
            language={language}
            onLanguageChange={handleLanguageChange}
            showForgotPassword={true}
            expandedByDefault={true}
        >
            <KortexOutlinedTextField
                id="instanceInputId"
                fullWidth={true}
                defaultValue={defaultInstance}
                label={translate("login.instance")}
            />
        </KortexDialogLogin>
    );
}

import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { BomFollowUpMultipleTraceabilitiesReq, IBomFollowUp, Unpack } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { Button, CircularProgress, IconButton, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { FC, useEffect, useState } from "react";

import { serviceMap, ServiceVariant } from "../../utils";

import SerialNumberSelector, { ItemReassigned } from "./serialNumberSelector";

const useStyles = makeStyles({
    buttonIcon: {
        paddingRight: "10px",
    },
    dialogButtons: {
        margin: "10px 5px 5px 5px",
        padding: "0px 20px",
    },
    row: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    quantityTextField: {
        width: "170px",
    },
});

interface IOwnProps {
    followUp: IBomFollowUp;
    onClose: () => void;
    open: boolean;
    variant: ServiceVariant;
    isQuantityDecimal: boolean;
}

const MultipleTraceabilitiesDialog: FC<IOwnProps> = (props) => {
    const { followUp, onClose, open, variant, isQuantityDecimal } = props;

    const isFollowUpQuantityZero = followUp.quantity === 0;
    const initialOriginalFollowQuantity = isFollowUpQuantityZero
        ? 0
        : isQuantityDecimal
        ? followUp.quantity
        : followUp.quantity === 1
        ? 1
        : followUp.quantity - 1;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const defaultRow: Unpack<BomFollowUpMultipleTraceabilitiesReq> = {
        quantity: isQuantityDecimal ? 1e-6 : 1,
        traceability: "",
        serializedItems: [],
    };

    const [followUpData, setFollowUpData] = useState<BomFollowUpMultipleTraceabilitiesReq>([
        { ...followUp, quantity: initialOriginalFollowQuantity },
        defaultRow,
    ]);
    const [loading, setLoading] = useState<boolean>(false);

    // Calculate the sum of quantities in the followUpData
    const sumOfQuantities = followUpData.reduce((acc, row) => acc + row.quantity, 0);

    // Check if a row's data is invalid
    const isRowDataInvalid = (row: Unpack<BomFollowUpMultipleTraceabilitiesReq>): boolean => {
        return !row.traceability?.trim() || (!isFollowUpQuantityZero && row.quantity <= 0);
    };

    // Check if quantities are equal between rows and serialized items
    const checkQuantitiesEquality = (): boolean => {
        return followUpData.every((row) => row.quantity === row.serializedItems.length);
    };

    // Check if quantities are great between rows and serialized items
    const checkQuantitiesGreat = (): boolean => {
        return followUpData.some((row) => row.quantity < row.serializedItems.length);
    };

    // Validate the traceability has duplicate in followUpData
    const validateTraceability = (): string => {
        const traceabilities = new Set<string>();

        for (const item of followUpData) {
            if (traceabilities.has(item.traceability)) {
                return item.traceability;
            } else {
                traceabilities.add(item.traceability);
            }
        }

        return "";
    };

    const duplicatedTraceability = validateTraceability();

    // check loading to avoid double clicking the confirm button
    // check if the followUpData array is empty
    // Check if the quantity in the followUp object is not equal to the sum of quantities in the followUpData array
    // Check if a followUpData is invalid
    // Validate the traceability has duplicate in followUpData
    // if there is a secondary traceability validate that the secondary traceabilities are reassigned.
    const confirmButtonDisabled =
        loading ||
        followUpData.length === 0 ||
        (!isFollowUpQuantityZero && followUp.quantity !== sumOfQuantities) ||
        followUpData.some(isRowDataInvalid) ||
        Boolean(duplicatedTraceability) ||
        (followUp.serializedItems.length > 0 && followUp.serializedItems.length === followUp.quantity && !checkQuantitiesEquality()) ||
        (followUp.serializedItems.length > 0 && followUp.serializedItems.length < followUp.quantity && checkQuantitiesGreat());

    /**
     * Resets the fields when the dialog is opened
     */
    useEffect(() => {
        if (open) {
            setFollowUpData([{ ...followUp, quantity: initialOriginalFollowQuantity }, defaultRow]);
        }
    }, [open]);

    /**
     * Creates a new row in the dialog to add a traceability
     */
    const handleAddTraceability = (): void => {
        const updatedRows = deepClone(followUpData);

        updatedRows.push(defaultRow);

        setFollowUpData(updatedRows);
    };

    /**
     * Updates the traceability of a row
     */
    const handleTraceabilityChange =
        (index: number): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            const updatedRows = deepClone(followUpData);

            updatedRows[index].traceability = event.target.value;

            setFollowUpData(updatedRows);
        };

    /**
     * Increases the quantity of specified traceability
     */
    const handleQuantityChange =
        (index: number) =>
        (value: number): void => {
            const updatedRows = deepClone(followUpData);

            updatedRows[index].quantity = value;

            setFollowUpData(updatedRows);
        };

    /**
     * Closes the dialog
     */
    const handleClose = (): void => {
        onClose();
    };

    /**
     * Deletes a row
     */
    const handleDeleteRow =
        (index: number): (() => void) =>
        (): void => {
            const updatedRows = deepClone(followUpData);

            if (followUpData[index].serializedItems.length > 0) {
                updatedRows[0].serializedItems.push(...updatedRows[index].serializedItems);
                updatedRows[0].quantity = updatedRows[0].quantity + updatedRows[index].quantity;
            }

            updatedRows.splice(index, 1);
            setFollowUpData(updatedRows);
        };

    /**
     * Proceeds to add a traceability to an item
     */
    const handleConfirm = (): void => {
        setLoading(true);

        dispatch(serviceMap[variant].multipleTraceabilities(followUpData))
            .then(handleClose)
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Serial number reassigned
     */
    const handleItemSerializedReassigned = (serializedItem: ItemReassigned): void => {
        const newData = deepClone(followUpData);

        // validate if assigned to other traceability
        for (const d of newData) {
            const index = d.serializedItems.findIndex(
                (serialNumber) => serialNumber.bomFollowUpSerializedItemId === serializedItem.bomFollowUpSerializedItemId
            );

            if (index !== -1) {
                // remove
                d.serializedItems.splice(index, 1);
            }
        }

        // add
        newData[serializedItem.indexTraceabilitySelected].serializedItems.push({
            bomFollowUpSerializedItemId: serializedItem.bomFollowUpSerializedItemId,
            serialNumber: serializedItem.serialNumber,
        });

        setFollowUpData(newData);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={confirmButtonDisabled}
            onCancel={handleClose}
            onConfirm={handleConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("bomPage.bomTable.multipleTraceabilities"),
            }}
            textLabelsIcons={{
                proceedButtonIcon: loading ? <CircularProgress /> : undefined,
            }}
        >
            <>
                <div key="multipleTraceabilitiesDialogOriginalTraceability">
                    <Typography
                        color={followUp.quantity === sumOfQuantities || isFollowUpQuantityZero ? "textPrimary" : "error"}
                        variant="body2"
                    >{`${translate("bomPage.bomTable.quantitySum")} ${followUp.quantity}`}</Typography>

                    {followUpData.map(
                        (row, index): JSX.Element => (
                            <div key={`multipleTraceabilitiesDialogRow${index}`}>
                                <div className={classes.row} id={`rowValue${index}Id`}>
                                    <KortexTextField
                                        label={translate("bomPage.bomTable.traceability")}
                                        onBlur={handleTraceabilityChange(index)}
                                        TextFieldProps={{
                                            autoComplete: "off",
                                            id: `multipleTraceabilitiesDialogTraceability${index}Id`,
                                            required: true,
                                            disabled: index === 0,
                                        }}
                                        value={row.traceability}
                                        variant="outlined"
                                        error={
                                            duplicatedTraceability && duplicatedTraceability === row.traceability
                                                ? `${translate("bomPage.bomTable.duplicatedTraceability")} `
                                                : undefined
                                        }
                                    />
                                    {/* QUANTITY */}
                                    <KortexTextField
                                        changedDelayMS={0}
                                        className={classes.quantityTextField}
                                        label={translate("bomPage.bomTable.quantity")}
                                        min={isFollowUpQuantityZero ? 0 : 1e-6}
                                        max={followUp.quantity}
                                        onChanged={handleQuantityChange(index)}
                                        TextFieldProps={{
                                            autoComplete: "off",
                                            disabled: isFollowUpQuantityZero,
                                            id: `multipleTraceabilitiesDialogQuantity${index}Id`,
                                            required: true,
                                        }}
                                        type="number"
                                        value={isFollowUpQuantityZero ? 0 : row.quantity}
                                        withButtons={!isFollowUpQuantityZero}
                                        step={isQuantityDecimal ? 0.1 : 1}
                                        stepDecimal={isQuantityDecimal ? 6 : 1}
                                    />
                                    {index > 0 && followUpData.length > 2 ? (
                                        <IconButton id={`multipleTraceabilitiesDialogDeleteRow${index}Id`} onClick={handleDeleteRow(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    ) : null}
                                </div>
                                {row.serializedItems.map(
                                    (itemSerialized, indexItemSerialized): JSX.Element => (
                                        <SerialNumberSelector
                                            index={indexItemSerialized}
                                            indexTraceability={index}
                                            serializedItem={itemSerialized}
                                            onItemSerializedReassigned={handleItemSerializedReassigned}
                                            traceabilities={followUpData}
                                            key={indexItemSerialized}
                                        />
                                    )
                                )}
                            </div>
                        )
                    )}
                </div>

                <Button
                    className={classes.dialogButtons}
                    color="secondary"
                    disabled={false} // faire en fonction de la quantity par step
                    id="multipleTraceabilitiesDialogAddTraceabilityButtonId"
                    onClick={handleAddTraceability}
                    variant="contained"
                >
                    <AddIcon className={classes.buttonIcon} />
                    {translate("bomPage.bomTable.addTraceability")}
                </Button>
            </>
        </KortexDialogConfirmation>
    );
};

export default MultipleTraceabilitiesDialog;

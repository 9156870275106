import { errorPalette, greyPalette, successPalette, theme, warningPalette } from "@aos/react-components";
import {
    IProcessValidationProcess,
    IProcessValidationProcessAction,
    OrUndefined,
    ProcessActionId,
    ProcessUiModel,
    ProcessValidation,
    ProcessValidationProcessActionErrorKey,
} from "@kortex/aos-common";
import { Drawer, Link, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import RefreshIcon from "@material-ui/icons/Refresh";
import WarningIcon from "@material-ui/icons/Warning";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";

const SNACKBAR_AUTO_HIDE_DURATION = 8000;

enum ProcessValidationStatus {
    FAIL,
    NONE,
    LOADING,
    SUCCESS,
}

const useStyles = makeStyles({
    iconError: {
        cursor: "pointer",
        color: errorPalette[500],
        marginRight: "4px",
    },
    iconWarning: {
        cursor: "pointer",
        color: warningPalette[500],
        marginRight: "4px",
    },
    refreshIcon: {
        cursor: "pointer",
        marginRight: "4px",
    },
    snackbarLoading: {
        backgroundColor: greyPalette[500],
    },
    snackbarSuccess: {
        backgroundColor: successPalette[500],
    },
    tableCellError: {
        alignItems: "center",
        display: "flex",
    },
    tableContainer: {
        minWidth: "700px",
        overflow: "auto",
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: "32px",
    },
    tableHeaderCellError: {
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        display: "flex",
        height: "32px",
    },
    tableRow: {
        "&:nth-of-type(odd)": {
            backgroundColor: greyPalette[200],
        },
    },
});

interface IValidationLabels {
    fail: string;
    success: string;
}

interface IOwnProps {
    labels?: IValidationLabels;
    processValidation?: ProcessValidation;
    onClose: () => void;
    onErrorClick?: (processActionId: ProcessActionId, stepIndex?: number) => void;
    open: boolean;
    process: OrUndefined<ProcessUiModel>;
    snackbarOnly?: boolean;
}

export default function ProcessValidationDrawer(props: IOwnProps): JSX.Element {
    const { labels, onErrorClick, onClose, open, process, snackbarOnly, processValidation } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [status, setStatus] = useState<ProcessValidationStatus>(ProcessValidationStatus.NONE);
    const [snackbarOpened, setSnackBarOpened] = useState(false);

    const showDrawer = status === ProcessValidationStatus.FAIL && !snackbarOnly;

    /**
     * Get complete error/warning message
     *
     * @param {string} key - key
     * @param {string} [message] - message to complement the error key
     */
    const getCompleteMessage = (key: string, message?: string, bomItem?: string): string => {
        return message ? `${bomItem ? `[${bomItem}] ` : ""}${translate(key)} ${translate(message)}` : translate(key);
    };

    /**
     * Get process action label
     */
    const getProcessActionLabel = (processActionId: ProcessActionId): string => {
        return process?.actions?.find((action) => action.processActionId === processActionId)?.label ?? "";
    };

    /**
     * Get process action step label
     */
    const getProcessActionStepLabel = (processActionId: ProcessActionId, stepIndex: OrUndefined<number>): string => {
        return stepIndex !== undefined
            ? process?.actions?.find((action) => action.processActionId === processActionId)?.steps[stepIndex]?.label ?? ""
            : translate("processEditor.validation.na");
    };

    /**
     * Get snackbar color depending on validation status
     */
    const getSnackBarColor = (): string => {
        switch (status) {
            case ProcessValidationStatus.FAIL:
                return errorPalette[500];
            case ProcessValidationStatus.SUCCESS:
                return successPalette[500];
            default:
                return greyPalette[500];
        }
    };

    /**
     * Get snackbar message depending on validation status
     */
    const getSnackBarMessage = (): string => {
        switch (status) {
            case ProcessValidationStatus.FAIL:
                return labels?.fail ?? translate("processEditor.validation.errorFound");
            case ProcessValidationStatus.LOADING:
                return translate("processEditor.validation.validating");
            case ProcessValidationStatus.SUCCESS:
                return labels?.success ?? translate("processEditor.validation.success");
            default:
                return "";
        }
    };

    /**
     * Get status based on validation results
     *
     */
    const getStatus = (): ProcessValidationStatus => {
        if (!processValidation) {
            return ProcessValidationStatus.NONE;
        }

        return !Boolean(processValidation.process.errors.length) && !Boolean(processValidation.processAction.errors.length)
            ? ProcessValidationStatus.SUCCESS
            : ProcessValidationStatus.FAIL;
    };

    /**
     * Handle error click
     *
     * @param {ProcessActionId} processActionId - process action ID
     * @param {number} stepIndex - step index
     */
    const handleProcessActionErrorClick =
        (processActionId: ProcessActionId, stepIndex?: number): (() => void) =>
        (): void => {
            if (onErrorClick) {
                onErrorClick(processActionId, stepIndex);
            }
        };

    /**
     * Refresh error list
     */
    const handleRefresh = (): void => {
        if (process) {
            startValidation();
        }
    };

    /**
     * Close snackbar
     */
    const handleSnackBarClose = (): void => {
        setSnackBarOpened(false);
        if (status !== ProcessValidationStatus.FAIL || snackbarOnly) {
            onClose();
        }
    };

    /**
     * Check if validator can redirect to the page where the error was found
     *
     * @param {ProcessValidationProcessActionErrorKey} key - error key
     */
    const isErrorLinkable = (key: string): boolean => {
        // Most errors/warnings for process actions can redirect to its page
        // so include those who cannot in the condition below
        return key !== ProcessValidationProcessActionErrorKey.INVALID_ACTION_ID;
    };

    /**
     * Render table cell in which process errors will be displayed
     *
     * @param {IProcessValidationProcess[]} errors - errors
     * @param {Element} icon - icon
     */
    const renderValidationProcess = function <T extends string>(errors: IProcessValidationProcess<T>[], icon: JSX.Element): JSX.Element[] {
        return errors.map((error, index) => (
            <TableRow className={classes.tableRow} key={index}>
                <TableCell className={classes.tableCellError}>
                    {icon}
                    <Typography variant="body2">{getCompleteMessage(error.key, error.message)}</Typography>
                </TableCell>
                <TableCell>{translate("processEditor.validation.na")}</TableCell>
                <TableCell>{translate("processEditor.validation.na")}</TableCell>
            </TableRow>
        ));
    };

    /**
     * Render table cell in which process action errors will be displayed
     *
     * @param {IProcessValidationProcess[]} errors - errors
     * @param {Element} icon - icon
     */
    const renderValidationProcessAction = function <T extends string>(
        errors: IProcessValidationProcessAction<T>[],
        icon: JSX.Element
    ): JSX.Element[] {
        return errors.map((error, index) => (
            <TableRow className={classes.tableRow} key={index}>
                <TableCell className={classes.tableCellError}>
                    {icon}
                    {isErrorLinkable(error.key) ? (
                        // A link can be applied to the error message
                        <>
                            <Link onClick={handleProcessActionErrorClick(error.processActionId, error.stepIndex)} variant="body2">
                                {getCompleteMessage(error.key, error.message, error.bomItemPartNumber)}
                            </Link>
                        </>
                    ) : (
                        // Display the error without a link
                        <Typography variant="body2">{getCompleteMessage(error.key, error.message, error.bomItemPartNumber)}</Typography>
                    )}
                </TableCell>
                <TableCell>
                    <Typography variant="body2">{getProcessActionLabel(error.processActionId)}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body2">{getProcessActionStepLabel(error.processActionId, error.stepIndex)}</Typography>
                </TableCell>
            </TableRow>
        ));
    };

    /**
     * Start process validation
     *
     */
    const startValidation = (): void => {
        setStatus(ProcessValidationStatus.LOADING);

        setStatus(getStatus());

        setSnackBarOpened(true);
    };

    /**
     * Start validation on open
     */
    useEffect(() => {
        if (open && process) {
            startValidation();
        }
    }, [open]);

    return (
        <>
            {status !== ProcessValidationStatus.NONE && (
                <Snackbar
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                    autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
                    ContentProps={{
                        style: {
                            backgroundColor: getSnackBarColor(),
                        },
                    }}
                    message={getSnackBarMessage()}
                    onClose={handleSnackBarClose}
                    open={snackbarOpened}
                />
            )}

            {showDrawer && (
                <Drawer anchor="right" open={open} onClose={onClose}>
                    <Paper className={classes.tableContainer}>
                        {process && processValidation ? (
                            // Show errors if process exist
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow className={classes.tableHeader}>
                                        <TableCell className={classes.tableHeaderCellError}>
                                            <RefreshIcon className={classes.refreshIcon} onClick={handleRefresh} />
                                            <Typography variant="body1">{translate("processEditor.validation.error")}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeader}>
                                            <Typography variant="body1">{translate("processEditor.validation.action")}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableHeader}>
                                            <Typography variant="body1">{translate("processEditor.validation.step")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* ERRORS */}
                                    {renderValidationProcess(processValidation.process.errors, <ErrorIcon className={classes.iconError} />)}
                                    {renderValidationProcessAction(
                                        processValidation.processAction.errors,
                                        <ErrorIcon className={classes.iconError} />
                                    )}
                                    {/* WARNINGS */}
                                    {renderValidationProcess(
                                        processValidation.process.warnings,
                                        <WarningIcon className={classes.iconWarning} />
                                    )}
                                    {renderValidationProcessAction(
                                        processValidation.processAction.warnings,
                                        <WarningIcon className={classes.iconWarning} />
                                    )}
                                </TableBody>
                            </Table>
                        ) : (
                            // Process does not exist, show error message
                            <Typography variant="body2">{translate("processEditor.validation.processNotFound")}</Typography>
                        )}
                    </Paper>
                </Drawer>
            )}
        </>
    );
}

import { theme } from "@aos/react-components";
import { IBomFollowUp, isBomItemTraceable, ISerializedItem, IWoBomItem, OrUndefined } from "@kortex/aos-common";
import { MenuType, NonTraceableItemMenu, TraceabilityMenu } from "@kortex/aos-ui/components/core/bom";
import { usePlayerContext } from "@kortex/aos-ui/components/core/ProcessPlayer/context";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { IconButton, makeStyles, PopoverPosition, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { FC, useState } from "react";

import { PlayerControlsBomItemInfoTooltip } from "../../utilities";

import { TraceabilityRow } from "./traceabilityRow";

const useStyles = makeStyles({
    cell: {
        borderBottom: "none",
    },
    root: {}, // To overwrite with props
    row: {
        borderBottom: `0.1px solid ${theme.palette.grey[300]}`,
    },
    rowPartNumber: {
        backgroundColor: "#cccccc",
    },
    editIcon: {
        padding: "0px",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    item: IWoBomItem;
    index: number;
}

const PlayerControlsBomItemRow: FC<IOwnProps> = (props) => {
    const { item, index } = props;

    const classes = useStyles(props);
    const translate = useTranslate();
    const { jobProcessInfo, playerState, woBom, process } = usePlayerContext();

    const [menuPosition, setMenuPosition] = useState<PopoverPosition | undefined>(undefined);
    const [menuType, setMenuType] = useState<MenuType>("traceability");
    const [followUpSelected, setFollowUpSelected] = useState<OrUndefined<IBomFollowUp>>(undefined);
    const [serializedItemSelected, setSerializedItemSelected] = useState<ISerializedItem | undefined>(undefined);

    const isItemTraceable = isBomItemTraceable(item);
    const isBomDisabled = !(playerState.processState.bom.enabled && Boolean(jobProcessInfo));
    const processActionStepId = playerState.uiActionProps?.stepProps.processActionStepId;
    const isOptionsButtonDisabled =
        isBomDisabled || !Boolean(woBom) || processActionStepId === undefined || !item.followUp[processActionStepId];

    const handleMenuClose = (): void => {
        setMenuPosition(undefined);
    };

    const handleDialogClose = (): void => {
        setFollowUpSelected(undefined);
        setSerializedItemSelected(undefined);
    };

    /**
     * Handles on click menu button
     */
    const handleMoreClick =
        (type: MenuType, followUp?: IBomFollowUp, serializedItem?: ISerializedItem): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            if (type === "serial") setSerializedItemSelected(serializedItem);

            setMenuType(type);
            setFollowUpSelected(followUp);
            setMenuPosition({
                left: event.clientX,
                top: event.clientY,
            });
        };

    const renderRows = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];

        if (!processActionStepId || !item.followUp[processActionStepId]) {
            elements.push(
                <TraceabilityRow handleMoreClick={handleMoreClick} index={0} item={item} itemIndex={index} key={elements.length} />
            );
            return elements;
        }

        for (const [traceabilityIndex, bomFollowUp] of item.followUp[processActionStepId].entries()) {
            elements.push(
                <TraceabilityRow
                    bomFollowUp={bomFollowUp}
                    handleMoreClick={handleMoreClick}
                    index={traceabilityIndex}
                    item={item}
                    itemIndex={index}
                    key={traceabilityIndex}
                />
            );
        }

        return elements;
    };

    return (
        <>
            <Table className={classes.root} id={"itemLineId"}>
                <React.Fragment key={index}>
                    {/* HEADER */}
                    <TableHead>
                        <TableRow className={`${classes.row} ${classes.rowPartNumber}`}>
                            {/* PART NUMBER */}
                            <PlayerControlsBomItemInfoTooltip item={item} placement="left-end">
                                <TableCell id={`${item.partNumber}partNumberId`} className={classes.cell} colSpan={isItemTraceable ? 3 : 1}>
                                    {item.partNumber}
                                </TableCell>
                            </PlayerControlsBomItemInfoTooltip>
                            {item.lotSerialType.length === 0 ||
                                (!isItemTraceable && (
                                    <>
                                        <TableCell
                                            align="left"
                                            className={classes.cell}
                                            id={`${item.partNumber}quantityId`}
                                            width="54px"
                                        >{`${translate("player.bom.qty")} : ${item.quantity}`}</TableCell>
                                        {/*  SUM OF ALL OVERCONSUMPTIONS */}
                                        <TableCell
                                            align="left"
                                            className={classes.cell}
                                            id={`${item.partNumber}sumOverconsumptionId`}
                                            width="36px"
                                        >
                                            {processActionStepId && item.followUp[processActionStepId]?.length
                                                ? `+${item.followUp[processActionStepId]
                                                      .map((line) => line.quantity)
                                                      .reduce((acc, value) => acc + value, 0)}`
                                                : ""}
                                        </TableCell>
                                        <TableCell align="right" className={classes.cell} width="36px">
                                            {item.quantity > 0 && (
                                                <IconButton
                                                    disabled={isBomDisabled}
                                                    disableRipple={true}
                                                    id={`playerControlsBomItemMore${item.partNumber}-${index}Id`}
                                                    onClick={handleMoreClick("non-traceable")}
                                                    className={classes.editIcon}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                        </TableRow>
                        {isItemTraceable && (
                            <TableRow className={classes.row}>
                                <TableCell className={classes.cell} width="10%">
                                    {translate("player.bom.qty")}
                                </TableCell>
                                <TableCell className={classes.cell} width="45%">
                                    {translate("player.bom.traceability")}
                                </TableCell>
                                {item.serialized && (
                                    <TableCell className={classes.cell} width="45%">
                                        {translate("player.bom.serialNumber")}
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableHead>

                    {/* BODY */}
                    {isItemTraceable && <TableBody>{renderRows()}</TableBody>}
                </React.Fragment>
            </Table>
            {!isOptionsButtonDisabled && followUpSelected ? (
                <TraceabilityMenu
                    followUp={followUpSelected}
                    onDialogClose={handleDialogClose}
                    onMenuClose={handleMenuClose}
                    type={menuType}
                    menuPosition={menuPosition}
                    serializedItem={serializedItemSelected}
                    variant="player"
                    treeNodeId={process?.treeNodeId}
                    isQuantityDecimal={item.isQuantityDecimal}
                />
            ) : null}
            {!isItemTraceable && woBom && item.quantity > 0 ? (
                <NonTraceableItemMenu
                    item={item}
                    menuPosition={menuPosition}
                    onDialogClose={handleDialogClose}
                    onMenuClose={handleMenuClose}
                    processActionStepId={processActionStepId}
                    variant="player"
                    woBom={woBom}
                />
            ) : null}
        </>
    );
};

export default PlayerControlsBomItemRow;

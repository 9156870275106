import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { BomFollowUpSymptomId, IBomFollowUp } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

import { BomFollowUpSymptomSelector } from "../../../BomFollowUpSymptomSelector";
import { serviceMap, ServiceVariant } from "../../utils";

const useStyles = makeStyles({
    dialogContent: {
        display: "grid",
        gridGap: "5px",
    },
    quantityTextField: {
        width: "170px",
    },
    traceability: {
        display: "flex",
        alignItems: "center",
    },
    item: {
        fontSize: "1rem",
    },
});

interface IOwnProps {
    followUp: IBomFollowUp;
    onClose: () => void;
    open: boolean;
    variant: ServiceVariant;
    treeNodeId?: number;
    jobRefId?: string;
    isQuantityDecimal: boolean;
}

const OverconsumptionDialog: FC<IOwnProps> = (props) => {
    const { followUp, onClose, open, variant, treeNodeId, jobRefId, isQuantityDecimal } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const [justification, setJustification] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [quantity, setQuantity] = useState<number>(isQuantityDecimal ? followUp.quantity : 1);
    const [traceability, setTraceability] = useState<string>(followUp.traceability);
    const [bomFollowUpSymptomId, setBomFollowUpSymptomId] = useState<BomFollowUpSymptomId>(0);

    const confirmButtonDisabled = loading || traceability.length === 0 || justification.length < 3 || bomFollowUpSymptomId === 0;

    /**
     * Resets the fields when the dialog is opened
     */
    useEffect(() => {
        if (open) {
            setJustification("");
            setTraceability(followUp.traceability);
            setQuantity(isQuantityDecimal ? followUp.quantity : 1);
            setBomFollowUpSymptomId(0);
        }
    }, [open]);

    /**
     * Closes the dialog
     */
    const handleClose = (): void => {
        onClose();
    };

    /**
     * Proceeds to overconsume the item
     */
    const handleConfirm = (): void => {
        setLoading(true);

        dispatch(
            serviceMap[variant].overconsumeItem({
                bomFollowUpId: followUp.bomFollowUpId,
                justification,
                quantity,
                traceability,
                bomFollowUpSymptomId,
            })
        )
            .then(handleClose)
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Updates the justification (inner state)
     */
    const handleJustificationChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setJustification(event.target.value);
    };

    /**
     * Updates the traceability (inner state)
     */
    const handleTraceabilityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setTraceability(event.target.value);
    };

    /**
     * Increases the quantity of specified traceability
     */
    const handleQuantityChange = (value: number): void => {
        setQuantity(value);
    };

    /**
     * Updates symptom of the changement
     */
    const handleBomFollowUpSymptomChange = (symptomId: BomFollowUpSymptomId): void => {
        setBomFollowUpSymptomId(symptomId);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={confirmButtonDisabled}
            onCancel={handleClose}
            onConfirm={handleConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("bomPage.bomTable.overconsumption"),
            }}
            textLabelsIcons={{
                proceedButtonIcon: loading ? <CircularProgress /> : undefined,
            }}
        >
            <div className={classes.dialogContent} id="dialogContentId">
                {/* TRACEABILITY */}
                <div className={classes.traceability}>
                    <KortexTextField
                        label={translate("bomPage.bomTable.traceability")}
                        TextFieldProps={{
                            autoComplete: "off",
                            id: "overconsumptionDialogTraceabilityId",
                            disabled: true,
                        }}
                        value={followUp.traceability}
                        variant="outlined"
                    />
                    <KortexTextField
                        label={translate("bomPage.bomTable.newTraceability")}
                        onChange={handleTraceabilityChange}
                        TextFieldProps={{
                            autoComplete: "off",
                            id: "overconsumptionDialogNewTraceabilityId",
                            required: true,
                        }}
                        value={traceability}
                        variant="outlined"
                    />
                    {/* QUANTITY */}
                    <KortexTextField
                        changedDelayMS={0}
                        className={classes.quantityTextField}
                        label={translate("bomPage.bomTable.quantity")}
                        min={1e-6}
                        onChanged={handleQuantityChange}
                        TextFieldProps={{
                            autoComplete: "off",
                            id: "overconsumptionDialogQuantityId",
                            required: true,
                        }}
                        type="number"
                        value={quantity}
                        withButtons={true}
                        step={isQuantityDecimal ? 0.1 : 1}
                        stepDecimal={isQuantityDecimal ? 6 : 1}
                    />
                </div>

                {/* BOM FOLLOW-UP SYMPTOMS */}
                <BomFollowUpSymptomSelector
                    classes={{ item: classes.item }}
                    onChange={handleBomFollowUpSymptomChange}
                    treeNodeId={treeNodeId}
                    jobRefId={jobRefId}
                    value={bomFollowUpSymptomId}
                    KortexTextFieldProps={{ required: true }}
                />

                {/* JUSTIFICATION */}
                <KortexTextField
                    label={translate("bomPage.bomTable.justification")}
                    onChange={handleJustificationChange}
                    TextFieldProps={{
                        autoComplete: "off",
                        id: "overconsumptionDialogJustificationId",
                        multiline: true,
                        required: true,
                        rows: 5,
                    }}
                    value={justification}
                    variant="outlined"
                />
            </div>
        </KortexDialogConfirmation>
    );
};

export default OverconsumptionDialog;

import { theme } from "@aos/react-components";
import { BomFollowUpId, IBomFollowUp, isBomItemTraceable, ISerializedItem, IWoBomItem } from "@kortex/aos-common";
import { MenuType } from "@kortex/aos-ui/components/core/bom";
import { usePlayerContext } from "@kortex/aos-ui/components/core/ProcessPlayer/context";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { processPlayerBomFollowUpInsertFollowUp } from "@kortex/aos-ui/redux/player-manager/player-thunk-bom-followUp";
import { AutoTabField } from "@kortex/aos-ui/utilitites/autoTab";
import { IconButton, InputAdornment, makeStyles, TableCell, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { FC } from "react";

import { SerialNumberRow } from "../serialNumberRow";

const useStyles = makeStyles({
    cell: {
        borderBottom: "none",
    },
    editIcon: {
        padding: "0px",
    },
    row: {
        borderBottom: `0.1px solid ${theme.palette.grey[300]}`,
    },
});

interface IOwnProps {
    bomFollowUp?: IBomFollowUp;
    handleMoreClick: (
        type: MenuType,
        followUp?: IBomFollowUp,
        serializedItem?: ISerializedItem
    ) => (event: React.MouseEvent<HTMLElement>) => void;
    index: number;
    item: IWoBomItem;
    itemIndex: number;
}

const TraceabilityRow: FC<IOwnProps> = (props) => {
    const { bomFollowUp, handleMoreClick, index, item, itemIndex } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const { bomStepItems, jobProcessInfo, playerState, woBom } = usePlayerContext();

    const processActionStepId = playerState.uiActionProps?.stepProps.processActionStepId;
    const bomDisabled = !(playerState.processState.bom.enabled && Boolean(jobProcessInfo));
    const optionsDisabled = bomDisabled || !Boolean(woBom) || processActionStepId === undefined || !item.followUp[processActionStepId];
    const quantityMax = bomStepItems[item.partNumber]?.quantity ?? item.quantity;
    const traceable = isBomItemTraceable(item);
    const textFieldDisabled = bomDisabled || Boolean(bomFollowUp?.traceability);

    const handleTraceabilityChange =
        (bomFollowUpId?: BomFollowUpId, quantity?: number): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            const newValue = event.target.value;

            if (!newValue.trim().length || textFieldDisabled) return void 0;

            dispatch(
                processPlayerBomFollowUpInsertFollowUp({
                    bomFollowUpId,
                    jobRefId: jobProcessInfo!.job.jobRefId,
                    partNumber: item.partNumber,
                    processActionStepId,
                    quantity: quantity ?? quantityMax,
                    traceability: newValue,
                    trackingId: playerState.processState.serialNumber,
                    trackingInstances: playerState.processState.serialNumberInstances.toString(),
                })
            );
        };

    const renderSerializedRows = (): JSX.Element[] => {
        if (!item.serialized) return [];

        const elements: JSX.Element[] = [];

        for (let i = 0; i < (bomFollowUp?.quantity ?? quantityMax); i++) {
            elements.push(
                <SerialNumberRow
                    bomFollowUp={bomFollowUp}
                    handleMoreClick={handleMoreClick}
                    index={i}
                    item={item}
                    itemIndex={itemIndex}
                    key={i}
                    traceabilityIndex={index}
                />
            );
        }

        return elements;
    };

    return (
        <TableRow className={classes.row} key={index}>
            {/* QUANTITY */}
            <TableCell className={classes.cell} id={`traceabilityRowQuantityId-${itemIndex}-${index}`}>
                {bomFollowUp ? `${bomFollowUp?.isOverconsumption ? "+ " : ""}${bomFollowUp.quantity}` : quantityMax}
            </TableCell>
            {/* TRACEABILITY */}
            <TableCell className={classes.cell}>
                {traceable && (
                    <AutoTabField
                        autoTabIndex={[itemIndex, index, -1]} // -1 added to have the same number of indexes as the serial numbers
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        className={classes.editIcon}
                                        disabled={optionsDisabled}
                                        disableRipple={true}
                                        id={`traceabilityRowMoreId-${itemIndex}-${index}`}
                                        onClick={handleMoreClick("traceability", bomFollowUp)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            readOnly: textFieldDisabled,
                        }}
                        onBlur={handleTraceabilityChange(bomFollowUp?.bomFollowUpId, bomFollowUp?.quantity)}
                        TextFieldProps={{
                            autoComplete: "off",
                            autoFocus: index === 0 && itemIndex === 0,
                            id: `traceabilityFieldId-${itemIndex}-${index}`,
                            placeholder: item.lotSerialType,
                        }}
                        value={bomFollowUp?.traceability ?? ""}
                        variant="standard"
                    />
                )}
            </TableCell>
            {/* SERIAL NUMBERS */}
            <TableCell className={classes.cell}>{renderSerializedRows()}</TableCell>
        </TableRow>
    );
};

export default TraceabilityRow;

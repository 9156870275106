import { theme } from "@aos/react-components";
import { getDateStr, IGetItemsByTypeAjustmentRes, PartNumber } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, Table, TableBody, TablePagination, Typography } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";

import { useBomContext } from "../../../../context";
import { DownloadCsvButton, IFile } from "../../../downloadCsvButton";

import { Header } from "./header";
import { Row } from "./row";

const useStyles = makeStyles({
    root: {
        flex: 1,
    },
    rowEven: {
        backgroundColor: theme.palette.grey[100],
    },
    rowOdd: {
        backgroundColor: theme.palette.grey[200],
    },
    tableContainer: {
        height: "calc(100vh - 298px)", // 64px (app header) + 32px (app margins) + 83px (bom page header) + 49px (adjustment type tabs) + 16px (adjustment type tabs margin) + 54px (table pagination)
        overflowY: "auto",
    },
    tableFooter: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
    },
    tableFooterRight: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
    },
    typographyTotal: {
        marginLeft: "4px",
        userSelect: "text",
    },
});

interface IPartNumberQuantity {
    [partNumber: PartNumber]: number;
}

interface IState {
    page: number;
    rowsPerPage: number;
}

interface IOwnProps {
    followUpHistories: IGetItemsByTypeAjustmentRes;
}

const PartNumberSummaryTable: FC<IOwnProps> = (props) => {
    const { followUpHistories } = props;

    const { previousSearch } = useBomContext();
    const classes = useStyles();
    const translate = useTranslate();

    const [state, setState] = useState<IState>({
        page: 0,
        rowsPerPage: 15,
    });

    const rows = useMemo(() => {
        const partNumberCount: IPartNumberQuantity = {};

        for (const history of followUpHistories) {
            if (partNumberCount[history.followUp.partNumber]) {
                partNumberCount[history.followUp.partNumber] += history.quantity;
            } else {
                partNumberCount[history.followUp.partNumber] = history.quantity;
            }
        }

        return Object.keys(partNumberCount)
            .map((partNumber): [string, number] => [partNumber, partNumberCount[partNumber]])
            .sort(
                ([partNumberA, quantityA], [partNumberB, quantityB]) => quantityB - quantityA || partNumberA.localeCompare(partNumberB) // Order by quantity, alphabetically if quantities are equal
            );
    }, [followUpHistories]);

    const totalQuantity = useMemo(() => rows.reduce((prev, current) => ["Count", prev[1] + current[1]])[1], [rows]);

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setState((prevState) => ({ ...prevState, page: newPage }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState((prevState) => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        }));
    };

    const onDownload = (): IFile[] => [
        {
            data: rows.map(([partNumber, quantity]) => ({
                // Please note that the order of the data below will be the same when exported to a CSV document.
                quantity,
                partNumber,
            })),
            name: `${translate("bomPage.bomTable.bomFollowUp")} - ${translate("bomPage.searchType.adjustmentType")} - ${translate(
                `bomPage.adjustmentType.${previousSearch.filters.adjustmentType}`
            )} - ${translate("bomPage.bomTable.summary")} - ${translate("bomPage.bomTable.partNumber")}${
                previousSearch.filters.dateFrom ? ` - ${getDateStr(previousSearch.filters.dateFrom)}` : ""
            }${previousSearch.filters.dateTo ? ` - ${getDateStr(previousSearch.filters.dateTo)}` : ""}.csv`,
        },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.tableContainer}>
                <Table stickyHeader={true}>
                    <Header />
                    <TableBody>
                        {rows
                            .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                            .map(([partNumber, quantity], index) => (
                                <Row
                                    classes={{ tableRow: index % 2 === 0 ? classes.rowEven : classes.rowOdd }}
                                    index={index}
                                    quantity={quantity}
                                    partNumber={partNumber}
                                    key={`partNumberSummaryTableRow${index}`}
                                />
                            ))}
                    </TableBody>
                </Table>
            </div>
            <div className={classes.tableFooter}>
                <Typography className={classes.typographyTotal} variant="body2">{`${translate(
                    "bomPage.bomTable.totalColon"
                )} ${totalQuantity}`}</Typography>
                <div className={classes.tableFooterRight}>
                    <DownloadCsvButton onDownload={onDownload} />
                    <TablePagination
                        component="div"
                        count={rows.length}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        page={state.page}
                        rowsPerPage={state.rowsPerPage}
                        rowsPerPageOptions={[15, 50, 100]}
                    />
                </div>
            </div>
        </div>
    );
};

export default PartNumberSummaryTable;

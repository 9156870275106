import { Kinova, ServiceManagerClient, WSClient } from "@kortex/aos-api-client";
import {
    ErpGetItemsBySecondaryTraceabilitySerialNumberReq,
    ErpGetItemsBySecondaryTraceabilitySerialNumberRes,
    getRPCHubInfo,
} from "@kortex/aos-common";

import { objectFromUTF8Array, utf8ArrayFromObject, utf8ArrayToKortexApiErrorObject } from "../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const erpGetItemsBySecondaryTraceabilitySerialNumber = (router: WSClient["router"]) =>
    ServiceManagerClient.getRR<
        ErpGetItemsBySecondaryTraceabilitySerialNumberReq,
        ErpGetItemsBySecondaryTraceabilitySerialNumberRes,
        Kinova.Api.IError
    >(getRPCHubInfo("erp")("getItemsBySecondaryTraceabilitySerialNumber"), {
        router,
        serializer: {
            decode: objectFromUTF8Array,
            encode: utf8ArrayFromObject,
            err: {
                decode: utf8ArrayToKortexApiErrorObject,
            },
        },
    });

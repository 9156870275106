import { getDateTimeStr, IGetItemsByTypeAjustmentRes, Unpack } from "@kortex/aos-common";
import { getPageUrl } from "@kortex/aos-ui/configs/menu";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    tableCell: {
        padding: "8px 4px",
    },
    tableRow: {},
    typographyLine: {
        display: "flex",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    followUpHistory: Unpack<IGetItemsByTypeAjustmentRes>;
}

const Row: FC<IOwnProps> = (props) => {
    const { followUpHistory } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    return (
        <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">{followUpHistory.quantity}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <div className={classes.typographyLine}>
                    <Typography variant="body2">
                        {followUpHistory.traceability || followUpHistory.previousValues.traceability ? (
                            <Link
                                to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=traceability&traceability=${
                                    followUpHistory.traceability
                                }`}
                            >
                                {followUpHistory.traceability || followUpHistory.previousValues.traceability}
                            </Link>
                        ) : (
                            translate("general.na")
                        )}
                    </Typography>
                    &nbsp;
                    {!followUpHistory.traceability && followUpHistory.previousValues.traceability ? (
                        <Typography color="error" variant="body2">
                            <sup>1</sup>
                        </Typography>
                    ) : !followUpHistory.traceability && !followUpHistory.previousValues.traceability ? (
                        <Typography color="error" variant="body2">
                            <sup>2</sup>
                        </Typography>
                    ) : null}
                </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                    <Link
                        to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=partNumber&partNumber=${followUpHistory.followUp.partNumber}`}
                    >
                        {followUpHistory.followUp.partNumber}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                    <Link
                        to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=trackingId&trackingId=${followUpHistory.followUp.trackingId}`}
                    >
                        {followUpHistory.followUp.trackingId}
                    </Link>
                </Typography>
            </TableCell>
            {/* TODO: Add link to Scheduler (AOS-2578)  */}
            <TableCell className={classes.tableCell}>
                <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=jobRefId&jobRefId=${followUpHistory.followUp.jobRefId}`}>
                    <Typography variant="body2">{followUpHistory.followUp.jobRefId}</Typography>
                </Link>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">{getDateTimeStr(followUpHistory.createdOn)}</Typography>
            </TableCell>
        </TableRow>
    );
};

export default Row;

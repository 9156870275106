import { IKortexTextFieldProps, KortexTextField } from "@aos/react-components";
import React, { FC } from "react";

import { useAutoTabContext } from "../autoTabContext/autoTabContext";
import { useAutoTab } from "../useAutoTab/useAutoTab";

interface IOwnProps extends IKortexTextFieldProps {
    autoTabIndex: [number, ...number[]];
}

const AutoTabField: FC<IOwnProps> = (props) => {
    const { autoTabIndex, children, TextFieldProps, ...rest } = props;

    const { generateIndex, onTab } = useAutoTabContext();

    const [id] = useAutoTab(generateIndex(...autoTabIndex), TextFieldProps?.id);

    return (
        <KortexTextField {...rest} onKeyDown={onTab(id)} TextFieldProps={{ ...TextFieldProps, id }}>
            {children}
        </KortexTextField>
    );
};

export default AutoTabField;

import { getDateTimeStr, IBomFollowUpDbModel } from "@kortex/aos-common";
import { getPageUrl } from "@kortex/aos-ui/configs/menu";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    tableCell: {
        padding: "8px 4px",
    },
    tableRow: {},
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    followUp: IBomFollowUpDbModel;
}

const Row: FC<IOwnProps> = (props) => {
    const { followUp } = props;

    const classes = useStyles(props);

    return (
        <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                    <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=traceability&traceability=${followUp.traceability}`}>
                        {followUp.traceability}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                    <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=partNumber&partNumber=${followUp.partNumber}`}>
                        {followUp.partNumber}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                    <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=trackingId&trackingId=${followUp.trackingId}`}>
                        {followUp.trackingId}
                    </Link>
                </Typography>
            </TableCell>
            {/* TODO: Add link to Scheduler (AOS-2578)  */}
            <TableCell className={classes.tableCell}>
                <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=jobRefId&jobRefId=${followUp.jobRefId}`}>
                    <Typography variant="body2">{followUp.jobRefId}</Typography>
                </Link>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">{getDateTimeStr(followUp.updatedOn)}</Typography>
            </TableCell>
        </TableRow>
    );
};

export default Row;

import { IVersion } from "@kortex/aos-common";

export const version: IVersion = {
    major: 1,
    minor: 19,
    patch: 0,
    pre: "dev",
    build: 949,
    meta: "",
};

import { EntityStatusEnum } from "../../../enum/EntityStatusEnum";
export function createEntityModel() {
    return {
        entityUniqueId: "",
        trackingId: "",
        trackingInstance: 0,
        partNumber: "",
        status: EntityStatusEnum.NEW,
        batchEnable: false,
        completed: false,
        passed: false,
        failed: false,
        inProgressResultMasterId: 0,
        completedProcessesId: "",
        startedOn: 0,
        completedOn: 0,
    };
}

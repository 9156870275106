import { PromiseStack } from "@kortex/aos-common";
import React, { createContext, FC, KeyboardEvent, PropsWithChildren, useContext, useRef } from "react";

export type AutoTabId = string;
export type AutoTabElement = [id: AutoTabId, index: number];

interface IAutoTabContext {
    addElement: (id: AutoTabElement) => void;
    generateIndex: (...indexes: number[]) => number;
    onTab: (id: AutoTabId) => (event: KeyboardEvent<HTMLElement>) => void;
    removeElement: (id: AutoTabId) => void;
}

export const AutoTabContext = createContext<IAutoTabContext>({
    addElement: () => void 0,
    generateIndex: () => 0,
    onTab: () => (): void => void 0,
    removeElement: () => void 0,
});

interface IAutoTabProviderProps extends PropsWithChildren {
    /**
     * Return to the first item after scrolling through the last one.
     */
    loop?: boolean;
    /**
     * Keyboard keys that are checked to focus the next element. Defaults to "Tab" and "Enter".
     */
    keys?: string[];
}

export const AutoTabProvider: FC<IAutoTabProviderProps> = (props) => {
    const { children, loop = false, keys = ["Enter", "Tab"] } = props;

    const stack = new PromiseStack(true);
    const tabbableElements = useRef<AutoTabElement[]>([]);

    const addElement = (element: AutoTabElement): void =>
        stack.push(
            () =>
                new Promise<void>((resolve) => {
                    tabbableElements.current.push(element);

                    if (stack.length <= 1) {
                        tabbableElements.current = tabbableElements.current.sort((a, b) => a[1] - b[1]);
                    }

                    resolve();
                })
        );

    const generateIndex = (...indexes: number[]): number => {
        let exponent = indexes.length - 1;
        let total = 0;

        for (let i = 0; i < indexes.length; i++) {
            total += (indexes[i] + 1) * Math.pow(10, exponent);
            exponent--;
        }

        return total;
    };

    const onTab = (id: AutoTabId) => (event: KeyboardEvent<HTMLElement>) => {
        if (!keys.includes(event.key)) return void 0;

        event.preventDefault();
        event.stopPropagation();

        const index = tabbableElements.current.findIndex((elementId) => elementId[0] === id) ?? -1;
        let nextElement = tabbableElements.current[index + (event.shiftKey ? -1 : 1)];

        // Focus next element
        if (!nextElement) {
            if (loop) {
                nextElement = event.shiftKey
                    ? tabbableElements.current[tabbableElements.current.length - 1] // Last element
                    : tabbableElements.current[0]; // First element

                if (nextElement) {
                    document.getElementById(nextElement[0])?.focus();
                }

                return void 0;
            }

            document.getElementById(tabbableElements.current[index]?.[0] ?? "")?.blur();

            return void 0;
        }

        return document.getElementById(nextElement[0])?.focus();
    };

    const removeElement = (id: AutoTabId): void =>
        stack.push(
            () =>
                new Promise<void>((resolve) => {
                    const index = tabbableElements.current.findIndex((elementId) => elementId[0] === id);

                    if (index !== -1) {
                        tabbableElements.current.splice(index, 1);
                    }

                    resolve();
                })
        );

    return <AutoTabContext.Provider value={{ addElement, generateIndex, onTab, removeElement }}>{children}</AutoTabContext.Provider>;
};

export const useAutoTabContext = (): IAutoTabContext => useContext<IAutoTabContext>(AutoTabContext);

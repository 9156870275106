import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";

import { IntlMiddleware } from "../locales/intl-middleware";
import { getElectronAPIClient } from "../utilitites/desktopUtils";
import { client } from "../utilitites/kortex-client/client";

import { GlobalActions } from "./app.global.types";
import { bomFollowUpReducer } from "./bom-follow-up/bom-follow-up-reducer";
import { BomFollowUpActions } from "./bom-follow-up/bom-follow-up-types";
import { bomReducer } from "./bom-manager/bom-reducer";
import { BomActions } from "./bom-manager/bom-types";
import { electronicSignatureReducer } from "./electronic-signature-manager/electronic-signature-reducer";
import { ElectronicSignatureActions } from "./electronic-signature-manager/electronic-signature-types";
import { erpSettingsReducer } from "./erp-settings-manager/erp-settings-reducer";
import { ErpSettingsActions } from "./erp-settings-manager/erp-settings-types";
import { failuresReducer } from "./failures-manager/failures-reducer";
import { FailureActions } from "./failures-manager/failures-types";
import { fileReducer } from "./file-manager/file-reducer";
import { FileActions } from "./file-manager/file-types";
import { generalReducer } from "./general-manager/general-reducer";
import { GeneralActions } from "./general-manager/general-types";
import { NotificationActions } from "./notifications-center-manager/notification-types";
import { notificationReducer } from "./notifications-center-manager/notifications-center-reducer";
import { playerReducer } from "./player-manager/player-reducer";
import { PlayerActions } from "./player-manager/player-types";
import { processReducer } from "./process-manager/process-reducer";
import { ProcessActions } from "./process-manager/process-types";
import { processTrainingReducer } from "./process-training-manager/process-training-reducer";
import { ProcessTrainingActions } from "./process-training-manager/process-training-types";
import { resultSettingReducer } from "./result-setting-manager/result-setting-reducer";
import { ResultSettingActions } from "./result-setting-manager/result-setting-types";
import { reworkReducer } from "./rework-manager/rework-reducer";
import { ReworkActions } from "./rework-manager/rework-types";
import { schedulerReducer } from "./scheduler-manager/scheduler-reducer";
import { SchedulerActions } from "./scheduler-manager/scheduler-types";
import { settingOrganizationReducer } from "./setting-organization-manager/setting-organization-reducer";
import { SettingOrganizationActions } from "./setting-organization-manager/setting-organization-types";
import { taskReducer } from "./task-manager/task-reducer";
import { TaskActions } from "./task-manager/task-types";
import { trainingCertificationPendingReducer } from "./training-certification-pending-manager/training-certification-pending-reducer";
import { TrainingCertificationPendingActions } from "./training-certification-pending-manager/training-certification-pending-types";
import { trainingPendingReducer } from "./training-pending-manager/training-pending-reducer";
import { TrainingPendingActions } from "./training-pending-manager/training-pending-types";
import { trainingReadingCertificatePendingReducer } from "./training-reading-certificate-pending-manager/training-reading-certificate-pending-reducer";
import { TrainingReadingCertificatePendingAction } from "./training-reading-certificate-pending-manager/training-reading-certificate-pending-types";
import { treeReducer } from "./tree-manager/tree-reducer";
import { TreeActions } from "./tree-manager/tree-types";
import { userReducer } from "./user-manager/users-reducer";
import { UserActions } from "./user-manager/users-types";
import { userTrainingReducer } from "./user-training-manager/user-training-reducer";
import { UserTrainingActions } from "./user-training-manager/user-training-types";
import { workScheduleReducer } from "./work-scheduler-manager/work-schedule-reducer";
import { WorkScheduleActions } from "./work-scheduler-manager/work-schedule-types";

const rootReducer = combineReducers({
    bomFollowUp: bomFollowUpReducer,
    bom: bomReducer,
    electronicSignature: electronicSignatureReducer,
    erpSettings: erpSettingsReducer,
    failure: failuresReducer,
    file: fileReducer,
    general: generalReducer,
    notification: notificationReducer,
    player: playerReducer,
    process: processReducer,
    processTraining: processTrainingReducer,
    resultSetting: resultSettingReducer,
    rework: reworkReducer,
    scheduler: schedulerReducer,
    workSchedule: workScheduleReducer,
    setting: settingOrganizationReducer,
    task: taskReducer,
    tree: treeReducer,
    trainingPending: trainingPendingReducer,
    trainingCertificationPending: trainingCertificationPendingReducer,
    trainingReadingCertificatePending: trainingReadingCertificatePendingReducer,
    user: userReducer,
    userTraining: userTrainingReducer,
});

export type AppState = Exclude<ReturnType<typeof rootReducer>, undefined>;

export type AppActions =
    | BomFollowUpActions
    | BomActions
    | ElectronicSignatureActions
    | ErpSettingsActions
    | FailureActions
    | FileActions
    | GeneralActions
    | GlobalActions
    | NotificationActions
    | PlayerActions
    | ProcessActions
    | ProcessTrainingActions
    | ResultSettingActions
    | ReworkActions
    | SettingOrganizationActions
    | SchedulerActions
    | WorkScheduleActions
    | TaskActions
    | UserTrainingActions
    | TreeActions
    | TrainingPendingActions
    | TrainingCertificationPendingActions
    | TrainingReadingCertificatePendingAction
    | UserActions
    | UserActions;

export interface IExtraThunkArg {
    apiDesktop: ReturnType<typeof getElectronAPIClient>;
    apiUI: typeof client;
    intl: typeof IntlMiddleware;
}

export const store = createStore(
    rootReducer,
    undefined,
    composeWithDevTools(
        applyMiddleware(
            thunk.withExtraArgument({
                apiDesktop: getElectronAPIClient(),
                apiUI: client,
                intl: IntlMiddleware,
            })
        )
    )
);

// The type of the store
export type StandardStore = typeof store;
// The type of dispatch() method
export type StandardDispatch = ThunkDispatch<AppState, IExtraThunkArg, AppActions>;
// The return type of a thunk action creator
export type StandardThunk<TReturnType = unknown> = ThunkAction<
    // In AOS, all thunks are asynchronous
    Promise<TReturnType>,
    AppState,
    IExtraThunkArg,
    AppActions
>;

import { theme } from "@aos/react-components";
import { IGetBomByJobRes } from "@kortex/aos-common";
import { makeStyles, Table, TableBody } from "@material-ui/core";
import React, { FC, useMemo } from "react";

import { Header } from "./header";
import { IRowData, Row } from "./row";

const useStyles = makeStyles({
    rowEven: {
        backgroundColor: theme.palette.grey[100],
    },
    rowOdd: {
        backgroundColor: theme.palette.grey[200],
    },
});

interface IOwnProps {
    resultMasters: IGetBomByJobRes["resultMasters"];
}

const JobTable: FC<IOwnProps> = (props) => {
    const { resultMasters } = props;
    const classes = useStyles();

    const rows = useMemo(
        () =>
            resultMasters.map(
                (resultMaster): IRowData => ({
                    completedOn: resultMaster.resultProcess?.completedOn,
                    createdOn: resultMaster.createdOn,
                    status: resultMaster.entity.status,
                    trackingId: resultMaster.trackingId,
                })
            ),
        [resultMasters]
    );

    return (
        <Table stickyHeader={true}>
            <Header />
            <TableBody>
                {rows.map((row, index) => (
                    <Row
                        classes={{ tableRow: index % 2 === 0 ? classes.rowEven : classes.rowOdd }}
                        data={row}
                        index={index}
                        key={`jobTableRow${index}`}
                        hideTrackingId={rows[index - 1]?.trackingId === row.trackingId}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

export default JobTable;

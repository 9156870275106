import { KortexDialogConfirmation, magentaPalette, theme, warningPalette } from "@aos/react-components";
import {
    ActionStepState,
    EnumActionStatus,
    EnumProcessStatus,
    IPlayerActionBaseState,
    IProcessUiModel,
    MIN_TO_MS,
    MIN_TO_SEC,
    SEC_TO_MS,
    getTime,
    isStepTraceabilityComplete as validateStepTraceability,
} from "@kortex/aos-common";
import { useKeybind } from "@kortex/aos-ui/hooks/useKeybind";
import { CircularProgress, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MessageBubbleIcon from "@material-ui/icons/ChatBubbleOutline";
import FailIcon from "@material-ui/icons/Close";
import NextIcon from "@material-ui/icons/FastForward";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import InfoIcon from "@material-ui/icons/InfoRounded";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import { formatDistanceStrict } from "date-fns";
import Debug from "debug";
import React, { FC, useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import TrainingIcon from "../../../Icons/training/training";
import { usePlayerContext } from "../../context";

import { BomFollowUpIncompleteDialog } from "./bomFollowUpIncompleteDialog";

const debug = Debug("kortex:ui:player-page");
debug("Loaded");
Debug.enable("kortex:ui:player-page");

const useStyles = makeStyles({
    spacer: {
        flexGrow: 2,
    },
    playerSmallButtonContainer: {
        display: "flex",
        gap: "10px",
        padding: "5px",
    },
    messageIconButton: {
        padding: 0,
    },
    bomFollowUpIconButton: {
        padding: 0,
    },
    messageButton: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
        paddingTop: "0px",
    },
    bomFollowUpButton: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
        paddingTop: "0px",
    },
    actionSecondButton: {
        cursor: "pointer",
        width: "45px",
        height: "45px",
        alignSelf: "center",
        paddingLeft: "10px",
    },
    actionButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary[500],
        cursor: "pointer",
        width: "45px",
        height: "45px",
        alignSelf: "center",
        paddingLeft: "10px",
        margin: "10px",
    },
    actionFailButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error[500],
        cursor: "pointer",
        width: "45px",
        height: "45px",
        alignSelf: "center",
        paddingLeft: "10px",
    },
    actionButtonBig: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary[500],
        cursor: "pointer",
        width: "70px",
        height: "70px",
        padding: "5px",
    },
    actionFailPathButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error[500],
        cursor: "pointer",
        width: "70px",
        height: "70px",
        padding: "5px",
    },
    actionButtonText: {
        color: theme.palette.common.white,
        backgroundColor: warningPalette[900],
        cursor: "pointer",
        fontSize: "1.4rem",
        "&:hover": {
            backgroundColor: warningPalette[900],
        },
        width: "48px",
        height: "48px",
    },
    controlTimeSection: {
        fontSize: "0.8rem",
        marginTop: "10px",
        marginBottom: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    elapsedTime: {
        paddingRight: "25px",
        paddingLeft: "30px",
    },
    stepManager: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderTop: `2px solid ${theme.palette.grey[300]}`,
    },
    buttonContainers: {
        display: "flex",
        width: "100%",
        height: "110px",
        flexDirection: "column",
    },
    buttonContainer: {
        width: "100%",
        height: "80px",
        justifySelf: "center",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
    },
    iconFiller: {
        width: "50px",
    },
    buttonDisabled: {
        backgroundColor: theme.palette.grey[200],
    },
    buttonsDiv: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
    trainingCommuniqueIcon: {
        color: magentaPalette["magenta"],
    },
});

interface IOwnProps {
    // Own Props
    canPlay: boolean;
    dryRunMode?: boolean;
    maskArrowKeys: boolean;
    nextButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
    playingHistoryIndex: number;
    processWithActions: IProcessUiModel | null;
    playingHistory?: boolean; // are we playing a history snaphot
    onStart: () => void;
    onStop: () => void;
    onPause: () => void;
    onResume: () => void;
    onPlayNext: () => void;
    onRewind: () => void;
    onMessageDialog: () => void;
    onBomFollowUpDialog: () => void;
    onDetailsDialog: () => void;
    onFail: () => void;
    onTraningCommuniqueOpen: () => void;
    trainingCommuniqueEnabled?: boolean;
}

const PlayerControlsFooter: FC<IOwnProps> = (props) => {
    const {
        dryRunMode,
        nextButtonRef,
        playingHistory,
        processWithActions,
        canPlay = true,
        maskArrowKeys = true,
        trainingCommuniqueEnabled = false,
        onDetailsDialog,
        onFail,
        onMessageDialog,
        onBomFollowUpDialog,
        onPause,
        onPlayNext,
        onResume,
        onRewind,
        onStart,
        onStop,
        onTraningCommuniqueOpen,
    } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const { bomStepItems, loadingBomFollowUp, playerState } = usePlayerContext();

    const [currentTime, setCurrentTime] = useState(getTime());
    const [remainingMinTime, setRemainingMinTime] = useState(0);
    const [nextRequested, setNextRequested] = useState(false);
    const [startingPathActionId, setStartingPathActionId] = useState(0);
    const [currentPlayedAction, setCurrentPlayedAction] = useState<IPlayerActionBaseState>();
    const [currentPlayedActionStep, setCurrentPlayedActionStep] = useState<ActionStepState>();
    const [playedActions, setPlayedActions] = useState<number[]>([]);
    const [confirmStopDialogOpen, setConfirmStopDialogOpen] = useState<boolean>(false); // @JAY FIXME: It is never set to true
    const [bomFollowUpIncompleteDialogOpen, setBomFollowUpIncompleteDialogOpen] = useState<boolean>(false);

    /**
     * Indicates if arrow keys are disabled
     */
    const arrowKeysDisabled = (): boolean => {
        return (
            currentPlayedAction === undefined ||
            currentPlayedAction.actionType === "core-output" ||
            playerState.processState.status !== EnumProcessStatus.PLAYING ||
            confirmStopDialogOpen ||
            maskArrowKeys ||
            (document.activeElement?.tagName === "INPUT" &&
                (document.activeElement?.getAttribute("type") === "text" || document.activeElement?.getAttribute("type") === "password"))
        );
    };

    /**
     * Key down event - Arrow left
     * Rewind
     */
    useKeybind("ArrowLeft", () => handleRewind(), { disabled: arrowKeysDisabled() });

    /**
     * Key down event - Arrow right
     * Go Forward
     */
    useKeybind("ArrowRight", () => handlePlayNext()(), { disabled: arrowKeysDisabled() });

    /**
     * will be called on mount
     */
    useEffect((): (() => void) => {
        const intervalTimer: ReturnType<typeof setInterval> = setInterval(updateTime, 1000);

        setStartingPathActionId(0);

        return (): void => {
            clearInterval(intervalTimer);
        };
    }, []);

    /**
     * will be called on playerState change
     */
    useEffect((): void => {
        let curAction = getCurrentAction();
        let curStep = currentPlayedActionStep;

        // Initial position, set it to INPUT
        if (!curAction && processWithActions && startingPathActionId === 0) {
            curAction = playerState.actionsState.find((action: IPlayerActionBaseState): boolean => action.actionType === "core-input");
        }

        // we found a current action, let's update the current step
        if (curAction) {
            curStep = getCurrentActionStep(curAction);
        }

        // if it's a new action let's store it to the played actions
        if (curAction) {
            if (curAction.actionId !== currentPlayedAction?.actionId) {
                const newPlayedAction = [...playedActions];
                if (curAction.actionId) {
                    newPlayedAction.push(curAction.actionId);
                    setPlayedActions(newPlayedAction);
                }
            }
            setCurrentPlayedAction(curAction);
        }

        let isNextRequested = false;

        if (curStep) {
            setCurrentPlayedActionStep(curStep);
            // Check if next requested
            isNextRequested = curStep?.status && curStep?.status === EnumActionStatus.WAITING_USER_NEXT;
        }

        if (!playingHistory) {
            // If nextRequested changed
            if (nextRequested !== isNextRequested) {
                setNextRequested(isNextRequested);
            }
        }
    }, [playerState]);

    // sets the remaining current time
    useEffect((): void => {
        let newRemainingMinTime = 0;
        if (!nextRequested) {
            if (currentPlayedActionStep && currentPlayedActionStep.minimumTime > 0) {
                const elapsedTime = currentTime - currentPlayedActionStep?.pauseDuration - currentPlayedActionStep?.startTime;
                if (elapsedTime < currentPlayedActionStep.minimumTime * SEC_TO_MS) {
                    newRemainingMinTime = currentPlayedActionStep.minimumTime * SEC_TO_MS - elapsedTime;
                }
            }
        }

        setRemainingMinTime(newRemainingMinTime);
    }, [currentTime]);

    /**
     * handle close Confirm Stop Dialog... Do not stop
     */
    const handleConfirmStopDialogOpenClose = (): void => {
        setConfirmStopDialogOpen(false);
    };

    /**
     * handle close Confirm Stop Dialog... Accept to stop
     */
    const handleConfirmStopDialogOpenConfirm = (): void => {
        setConfirmStopDialogOpen(false);
        props.onStop();
    };

    /**
     * updates the time
     */
    const updateTime = (): void => {
        setCurrentTime(getTime());
    };

    /**
     * get process elapsed time
     */
    const processElapsedTime = (): string => {
        let totalTime = 0;
        let totalPlayedTime = 0;

        if (!playerState) {
            return "";
        }

        for (const actionStateItem of playerState.actionsState) {
            if (actionStateItem.baseStates.stepsState) {
                for (const stepState of actionStateItem.baseStates.stepsState) {
                    totalTime += stepState.targetTime ? parseInt(stepState.targetTime.toString(), 10) : 0;
                    if (stepState.endTime !== -1) {
                        totalPlayedTime += stepState.endTime - stepState.startTime;
                    }
                }
            }
        }

        switch (playerState.processState.status) {
            case EnumProcessStatus.IDLE:
                return "";
            case EnumProcessStatus.PLAYING:
                return (
                    formatDistanceStrict(
                        currentTime - playerState.processState.pauseDuration - playerState.processState.stopDuration,
                        playerState.processState.startTime,
                        {
                            roundingMethod: "floor",
                        }
                    ) +
                    " / " +
                    Math.floor(totalTime / MIN_TO_SEC).toString() +
                    " " +
                    translate("units.short.minutes")
                );

            case EnumProcessStatus.FAILED:
            case EnumProcessStatus.COMPLETED:
                return (
                    Math.floor(totalPlayedTime / MIN_TO_MS).toString() +
                    " " +
                    translate("units.short.minutes") +
                    " / " +
                    Math.floor(totalTime / MIN_TO_SEC).toString() +
                    " " +
                    translate("units.short.minutes")
                );

            case EnumProcessStatus.PAUSED:
                return translate("player.paused");
            default:
                return (
                    formatDistanceStrict(
                        playerState.processState.endTime - playerState.processState.pauseDuration - playerState.processState.stopDuration,
                        playerState.processState.startTime,
                        {
                            roundingMethod: "floor",
                        }
                    ) +
                    " / " +
                    totalTime.toString() +
                    " " +
                    translate("units.short.minutes")
                );
        }
    };

    /**
     * handle the start process
     */
    const handleStart = (): void => {
        if (playerState.processState.status === EnumProcessStatus.PAUSED) {
            onResume();
        } else {
            onStart();
        }
    };

    /**
     * handle the rewind
     
     */
    const handleRewind = (): void => {
        onRewind();
    };

    /**
     * handle the pause process
     */
    const handlePause = (): void => {
        onPause();
    };

    /**
     * handle the play next process
     */
    const handlePlayNext =
        (skipTraceabilityValidation = false): ((event?: React.MouseEvent<HTMLButtonElement>) => void) =>
        () => {
            if (!playerState.playNextEnabled) {
                return void 0;
            }

            if (!skipTraceabilityValidation && !isStepTraceabilityComplete()) {
                setBomFollowUpIncompleteDialogOpen(true);
                return void 0;
            }

            onPlayNext();

            if (!playingHistory) {
                setNextRequested(false);
                setRemainingMinTime(0);
                updateTime();
            }

            return void 0;
        };

    /**
     * If enter was pressed on the "Next" button, unfocus it
     */
    const handlePlayNextKeyUp = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
        if (!playerState.playNextEnabled) {
            return void 0;
        }

        if (event.key === "Enter") {
            event.currentTarget.blur();
        }
    };

    /**
     * handle message open dialog request
     */
    const handleEnterMessage = (): void => {
        onMessageDialog();
    };

    /**
     * handle bom followUp open dialog request
     */
    const handleOpenBomFollowUp = (): void => {
        onBomFollowUpDialog();
    };

    /**
     * handle request details open dialog request
     */
    const handleRequestDetails = (): void => {
        onDetailsDialog();
    };

    /**
     * gets the currently played action
     */
    const getCurrentAction = (): IPlayerActionBaseState | undefined => {
        return playerState.actionsState.find(
            (action: IPlayerActionBaseState): boolean =>
                action.baseStates.status === EnumActionStatus.PLAYING ||
                action.baseStates.status === EnumActionStatus.PAUSED ||
                action.baseStates.status === EnumActionStatus.TRAINER_SIGNATURE_REQUIRED ||
                action.baseStates.status === EnumActionStatus.WAITING_USER_NEXT
        );
    };

    /**
     * gets the currently played action
     */
    const getCurrentActionStep = (action: IPlayerActionBaseState): ActionStepState | undefined => {
        let step = action.baseStates.stepsState.find(
            (step: ActionStepState): boolean =>
                step.status === EnumActionStatus.PLAYING ||
                step.status === EnumActionStatus.PAUSED ||
                step.status === EnumActionStatus.TRAINER_SIGNATURE_REQUIRED ||
                step.status === EnumActionStatus.WAITING_USER_NEXT
        );
        if (!step) {
            step = [...action.baseStates.stepsState]
                .reverse()
                .find((step: ActionStepState): boolean => step.status === EnumActionStatus.COMPLETED);
        }
        return step;
    };

    const getRemaingingTimeString = (): string => {
        const remainingTimeStrSplit = formatDistanceStrict(remainingMinTime, 0, {
            roundingMethod: "floor",
        }).split(" ");
        return remainingTimeStrSplit[0] + remainingTimeStrSplit[1].charAt(0);
    };

    /**
     * handles the fail step
     */
    const handleFailStep = (): void => {
        if (
            currentPlayedAction?.actionType === "core-input" ||
            dryRunMode // @JAY FIXME: Do we want a confirmation before stopping in dry mode? If so, remove 'dryRunMode' from condition and fix it in Player.tsx
        ) {
            onStop();
        } else {
            onFail();
        }
    };

    const handleStop = (): void => {
        if (currentPlayedAction?.actionType === "core-input") {
            onStop();
        } else {
            setConfirmStopDialogOpen(true);
        }
    };

    const handleBomFollowUpIncompleteDialogCancel = (): void => {
        setBomFollowUpIncompleteDialogOpen(false);
    };

    const handleBomFollowUpIncompleteDialogConfirm = (): void => {
        setBomFollowUpIncompleteDialogOpen(false);
        handlePlayNext(true)();
    };

    const isStepTraceabilityComplete = (): boolean => {
        // Do nothing if BOM features are disabled or step does not have any BOM item
        if (!playerState.processState.bom.enabled || !Object.values(bomStepItems).length) return true;

        // Do nothing if step content is not loaded
        if (!playerState.uiActionProps?.stepProps.processActionStepId) return false;

        // Check if at least 1 item has incomplete traceability/serial number for the specified process action step
        return validateStepTraceability(playerState.uiActionProps.stepProps.processActionStepId, bomStepItems);
    };

    return (
        <>
            <div className={classes.stepManager}>
                <div className={classes.buttonContainers}>
                    <div className={classes.playerSmallButtonContainer}>
                        <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={translate("player.infoExecution")}>
                            <IconButton className={classes.messageIconButton} id="playerDetailsButtonId" onClick={handleRequestDetails}>
                                <InfoIcon className={classes.messageButton} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={translate("player.trainingCommunique")}>
                            <span>
                                <IconButton
                                    className={`
                                ${classes.messageIconButton}
                                ${trainingCommuniqueEnabled ? classes.trainingCommuniqueIcon : ""}
                            `}
                                    disabled={!trainingCommuniqueEnabled}
                                    id="playerTrainingCommuniqueButtonId"
                                    onClick={onTraningCommuniqueOpen}
                                >
                                    <TrainingIcon className={classes.messageButton} />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <div className={classes.spacer} />
                        {playerState.processState && playerState.processState.status === EnumProcessStatus.PLAYING && (
                            <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={translate("player.addComment")}>
                                <span>
                                    <IconButton
                                        className={classes.messageIconButton}
                                        disabled={!playerState.playNextEnabled}
                                        id="playerAddMessageButtonId"
                                        onClick={handleEnterMessage}
                                    >
                                        <MessageBubbleIcon className={classes.messageButton} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                        {playerState.processState.bom.enabled && (
                            <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={translate("player.bomFollowUp")}>
                                <span>
                                    <IconButton
                                        className={classes.bomFollowUpIconButton}
                                        id="playerBomFollowUpButtonId"
                                        onClick={handleOpenBomFollowUp}
                                    >
                                        <AssignmentIcon className={classes.bomFollowUpButton} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </div>

                    <div className={classes.buttonContainer}>
                        {playerState.processState &&
                            (playerState.processState.status === EnumProcessStatus.IDLE ||
                                playerState.processState.status === EnumProcessStatus.COMPLETED ||
                                playerState.processState.status === EnumProcessStatus.FAILED) && (
                                <>
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltip }}
                                        placement="top"
                                        title={translate("player.startExecution")}
                                    >
                                        <span>
                                            <IconButton
                                                className={classes.actionButtonBig}
                                                color="primary"
                                                id="playerStartButtonId"
                                                onClick={handleStart}
                                                disabled={!canPlay}
                                            >
                                                <PlayArrowIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </>
                            )}

                        {playerState.processState &&
                            (playerState.processState.status === EnumProcessStatus.PLAYING ||
                                playerState.processState.status === EnumProcessStatus.PLAYING_FAILED) && (
                                <div className={classes.buttonsDiv}>
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltip }}
                                        placement="top"
                                        title={translate("player.stopExecution")}
                                    >
                                        <IconButton className={classes.actionButton} id="playerStopButtonId" onClick={handleStop}>
                                            <StopIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={translate("player.rewindStep")}>
                                        <span>
                                            <IconButton
                                                id="playerRewindButtonId"
                                                className={classes.actionSecondButton}
                                                onClick={handleRewind}
                                                disabled={currentPlayedAction?.actionType === "core-input" || !playerState.playNextEnabled}
                                            >
                                                <FastRewindIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>

                                    {(remainingMinTime <= 0 || playingHistory) && (
                                        <Tooltip
                                            classes={{ tooltip: classes.tooltip }}
                                            placement="top"
                                            title={translate("player.nextStep")}
                                        >
                                            <span>
                                                <IconButton
                                                    className={
                                                        playerState.processState.status !== EnumProcessStatus.PLAYING_FAILED
                                                            ? `${classes.actionButtonBig}`
                                                            : classes.actionFailPathButton
                                                    }
                                                    disabled={!playerState.playNextEnabled || loadingBomFollowUp}
                                                    id="playerNextButtonId"
                                                    onClick={handlePlayNext()}
                                                    onKeyDown={handlePlayNextKeyUp}
                                                    ref={nextButtonRef}
                                                >
                                                    {playerState.playNextEnabled ? <NextIcon /> : <CircularProgress />}
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    )}
                                    {remainingMinTime > 0 && !playingHistory && (
                                        <IconButton className={classes.actionButtonText}>{getRemaingingTimeString()}</IconButton>
                                    )}
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltip }}
                                        placement="top"
                                        title={translate("player.pauseExecution")}
                                    >
                                        <span>
                                            <IconButton
                                                id="playerPauseButtonId"
                                                className={classes.actionSecondButton}
                                                onClick={handlePause}
                                                classes={{
                                                    disabled: classes.buttonDisabled,
                                                }}
                                                disabled={
                                                    !currentPlayedAction ||
                                                    currentPlayedAction.actionType !== "core-work-instructions" ||
                                                    !playerState.playNextEnabled
                                                }
                                            >
                                                <PauseIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </div>
                            )}

                        {playerState.processState && playerState.processState.status === EnumProcessStatus.PAUSED && (
                            <>
                                <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={translate("player.stopExecution")}>
                                    <IconButton className={classes.actionButton} id="playerStopButtonId" onClick={handleStop}>
                                        <StopIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={translate("player.rewindStep")}>
                                    <span>
                                        <IconButton
                                            id="playerRewindButtonId"
                                            className={classes.actionSecondButton}
                                            onClick={handleRewind}
                                            disabled={currentPlayedAction?.actionType === "core-input" || !playerState.playNextEnabled}
                                        >
                                            <FastRewindIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={translate("player.startExecution")}>
                                    <span>
                                        <IconButton
                                            className={classes.actionButtonBig}
                                            color="primary"
                                            id="playerStartButtonId"
                                            onClick={handleStart}
                                            disabled={!canPlay}
                                        >
                                            <PlayArrowIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <div className={classes.iconFiller} />
                            </>
                        )}
                    </div>
                </div>
                {playerState.processState && playerState.processState.status !== EnumProcessStatus.COMPLETED && (
                    <>
                        <div className={classes.controlTimeSection}>
                            <span className={classes.elapsedTime}>{processElapsedTime()}</span>
                            {!dryRunMode &&
                                playerState.processState &&
                                currentPlayedAction?.actionType !== "core-input" &&
                                !playerState.processState.inFailPath &&
                                (playerState.processState.status === EnumProcessStatus.PAUSED ||
                                    playerState.processState.status === EnumProcessStatus.PLAYING ||
                                    playerState.processState.status === EnumProcessStatus.PLAYING_FAILED) && (
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltip }}
                                        placement="top"
                                        title={translate("player.failExecution")}
                                    >
                                        <IconButton className={classes.actionFailButton} id="playerFailButtonId" onClick={handleFailStep}>
                                            <FailIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                        </div>
                    </>
                )}
            </div>
            <KortexDialogConfirmation
                open={confirmStopDialogOpen}
                textLabels={{
                    titleLabel: translate("player.stopPlayerTitleConfirmation"),
                    cancelButtonLabel: translate("player.cancel"),
                    proceedButtonLabel: translate("player.confirm"),
                }}
                onCancel={handleConfirmStopDialogOpenClose}
                onConfirm={handleConfirmStopDialogOpenConfirm}
                closeOnEscape={true}
            >
                <Typography variant="h6">{translate("player.stopPlayerConfirmation")}</Typography>
            </KortexDialogConfirmation>
            <BomFollowUpIncompleteDialog
                onCancel={handleBomFollowUpIncompleteDialogCancel}
                onConfirm={handleBomFollowUpIncompleteDialogConfirm}
                open={bomFollowUpIncompleteDialogOpen}
            />
        </>
    );
};

export default PlayerControlsFooter;

import { IGetSerializedItemsByTypeAjustmentRes } from "@kortex/aos-common";
import { Divider, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { PartNumberSummaryTable } from "./partNumberSummaryTable";
import { SerialNumberSummaryTable } from "./serialNumberSummaryTable";

const useStyles = makeStyles({
    root: {
        display: "flex",
        gap: "16px",
    },
});

interface IOwnProps {
    serialNumberHistories: IGetSerializedItemsByTypeAjustmentRes;
}

const AdjustmentTypeSummary: FC<IOwnProps> = (props) => {
    const { serialNumberHistories } = props;

    const classes = useStyles();

    return (
        <div className={classes.root} id="bomFollowUpPageAdjustmentTypeSerialNumberSummaryTabId">
            <SerialNumberSummaryTable serialNumberHistories={serialNumberHistories} />
            <Divider flexItem={true} orientation="vertical" />
            <PartNumberSummaryTable serialNumberHistories={serialNumberHistories} />
        </div>
    );
};

export default AdjustmentTypeSummary;

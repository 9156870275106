import { IBomFollowUpDbModel } from "@kortex/aos-common";
import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { useBomContext } from "../../context";
import { NoDataFound } from "../../noDataFound";

import { SecondaryTraceabilityTable } from "./secondaryTraceabilityTable";

const useStyles = makeStyles({
    loadingContainer: {
        alignItems: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "16px 0",
        width: "100%",
    },
});

const BomSecondaryTraceability: FC = () => {
    const classes = useStyles();
    const { loading, queryResult } = useBomContext();

    return (
        <div>
            {loading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            ) : (queryResult as IBomFollowUpDbModel[])?.length ? (
                <SecondaryTraceabilityTable followUps={queryResult as IBomFollowUpDbModel[]} />
            ) : (
                <NoDataFound />
            )}
        </div>
    );
};

export default BomSecondaryTraceability;

import { KortexTextField } from "@aos/react-components";
import { ISerializedItem } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Checkbox, makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

const useStyles = makeStyles({
    serialNumber: {
        display: "flex",
        alignItems: "center",
    },
    checkbox: {
        padding: "0px",
    },
});

interface IOwnProps {
    index: number;
    serializedItem: ISerializedItem;
    newSerializedItems: ISerializedItem[];
    onSerialNumbersChange: (serializedItem: ISerializedItem, newSerialNumber: ISerializedItem["serialNumber"], uncheck?: boolean) => void;
}

const SerialNumberSelector: FC<IOwnProps> = (props): JSX.Element => {
    const { index, serializedItem, newSerializedItems, onSerialNumbersChange } = props;

    const indexNewSerializedItem = newSerializedItems.findIndex(
        (serializedItem) => serializedItem.bomFollowUpSerializedItemId === serializedItem.bomFollowUpSerializedItemId
    );

    const [serialNumberChecked, setSerialNumberChecked] = useState<boolean>(indexNewSerializedItem !== -1);
    const [serialNumber, setSerialNumber] = useState<ISerializedItem["serialNumber"]>("");

    useEffect(() => {
        setSerialNumberChecked(false);
        setSerialNumber("");
    }, [serializedItem]);

    const classes = useStyles();
    const translate = useTranslate();

    const handleSerialNumberChecked = (): void => {
        if (!serialNumberChecked) {
            setSerialNumberChecked(!serialNumberChecked);
        } else {
            setSerialNumberChecked(!serialNumberChecked);
            onSerialNumbersChange(serializedItem, "", true);
            setSerialNumber("");
        }
    };

    /**
     * Updates the serial number of the item
     */
    const handleSerialNumberChange = (newValue: string): void => {
        setSerialNumber(newValue);
        onSerialNumbersChange(serializedItem, newValue);
    };

    return (
        <div className={classes.serialNumber}>
            {/* Serial Number */}
            <KortexTextField
                label={translate("bomPage.bomTable.serialNumber")}
                value={serializedItem.serialNumber}
                variant="standard"
                TextFieldProps={{
                    autoComplete: "off",
                    id: `replacementDialogSerialNumber${index}Id`,
                    disabled: true,
                }}
                InputProps={{
                    endAdornment: (
                        <Checkbox
                            className={classes.checkbox}
                            disabled={false}
                            checked={serialNumberChecked}
                            onChange={handleSerialNumberChecked}
                            id={`serialNumberCheckbox${index}Id`}
                        />
                    ),
                }}
            />
            {/* New Serial Number */}
            {serialNumberChecked && (
                <KortexTextField
                    label={translate("player.bom.newSerialNumber")}
                    value={serialNumber}
                    variant="standard"
                    TextFieldProps={{
                        autoComplete: "off",
                        id: `replacementDialogNewSerialNumber${index}Id`,
                        required: true,
                    }}
                    changedDelayMS={400}
                    onChanged={handleSerialNumberChange}
                />
            )}
        </div>
    );
};

export default SerialNumberSelector;

import { fr as kortexSequencerLocaleFr } from "@aos/process-sequencer";
import { KEY_ERROR_LOCALE_FR } from "@kortex/aos-common";

import { ILocalIntl } from "./ILocale";

export const fr: ILocalIntl = {
    ...kortexSequencerLocaleFr,
    ...KEY_ERROR_LOCALE_FR,

    "globals.appname": "KINOVA",

    en: "Anglais",
    fr: "Français",

    // GENERAL
    "general.general": "Général",
    "general.archive": "Archivé",
    "general.add": "Ajouter",
    "general.unarchive": "Désarchivé",
    "general.cancel": "Annuler",
    "general.date": "Date",
    "general.new": "Nouveau",
    "general.resume": "Reprendre",
    "general.proceed": "Procéder",
    "general.language": "Langue",
    "general.save": "Sauvegarder",
    "general.select": "Sélectionner",
    "general.clear": "Effacer",
    "general.clearAll": "Tout effacer",
    "general.delete": "Supprimer",
    "general.deleteAll": "Tout supprimer",
    "general.yes": "Oui",
    "general.no": "Non",
    "general.na": "N/A",
    "general.close": "Fermer",
    "general.finish": "Terminer",
    "general.seconds": "Secondes",
    "general.minutes": "Minutes",
    "general.hours": "Heures",
    "general.days": "Jours",
    "general.weeks": "Semaines",
    "general.months": "Mois",
    "general.years": "Années",
    "general.update": "Mettre à jour",
    "general.purge": "Purge",
    "general.none": "Aucun",
    "general.id": "Id",
    "general.pass": "Succès",
    "general.fail": "Échec",
    "general.create": "Créer",
    "general.true": "Vrai",
    "general.false": "Faux",
    "general.warning": "Avertissement",
    "general.electronicSignatureConsent":
        "Par l'exécution de cette signature électronique, je consens à ce qu'elle soit l'équivalent légal de ma signature manuscrite et à ce que je sois légalement lié par ce consentement. J'atteste par ailleurs que ma signature sur ce formulaire est aussi valide que si j'avais signé le document en personne, en format papier.",
    "general.confirm": "Confirmer",
    "general.selectAll": "Tout sélectionner",
    "general.search": "Recherche",
    "general.searchUser": "Rechercher un utilisateur",
    "general.selectUser": "Sélectionnez les utilisateurs à ajouter à la formation",
    "general.userName": "Nom d'utilisateur",
    "general.process": "Processus",
    "general.processes": "Processus",
    "general.from": "De",
    "general.to": "À",
    "general.createdOn": "Date de Création",
    "general.clearFilters": "Effacer les filtres",
    "general.required": "Requis",

    // ACCESS LEVEL
    "accessLevel.write": "Écriture",
    "accessLevel.read": "Lecture",
    "accessLevel.none": "Aucun",
    "accessLevel.authenticated": "Authentification",
    "accessLevel.insert": "Insertion",
    "accessLevel.archive": "Archive",

    // UNITS
    "units.short.minutes": "min",
    "units.short.seconds": "sec",

    /**
     * APP LAYOUT
     */
    "appLayout.updateAndRestart": "Mettre à jour et redémarrer",
    "appLayout.reconnecting": "Reconnection...",
    "appLayout.updateAvailable": "Une nouvelle mise à jour est prête à être installé.",

    /**
     * FUNCTION - CONDITIONS
     */
    // GENERAL
    "action.condition.delete": "Supprimer",
    "action.condition.logical": "Logique",
    "action.condition.logical.and": "ET",
    "action.condition.logical.or": "OU",
    "action.condition.preview": "Aperçu",
    // EXPERT MODE
    "action.condition.expert": "Mode expert",
    "action.condition.expert.condition": "Condition",
    // SIMPLIFIED MODE
    "action.condition.simplified": "Mode simplifié",
    "action.condition.simplified.comparison": "Comparaison",
    "action.condition.simplified.comparison.equals": "égal à",
    "action.condition.simplified.comparison.notEqual": "n'est pas égal à",
    "action.condition.simplified.comparison.greaterThan": "plus grand que",
    "action.condition.simplified.comparison.lessThan": "plus petit que",
    "action.condition.simplified.comparison.greaterThanOrEqualTo": "plus grand ou égal à",
    "action.condition.simplified.comparison.lessThanOrEqualTo": "plus petit ou égal à",
    "action.condition.simplified.leftOperand": "Opérande de gauche",
    "action.condition.simplified.rightOperand": "Opérande de droite",
    "action.condition.simplified.typeMismatch": "Les types de condition et de variables ne correspondent pas",
    "action.condition.simplified.type": "Type",
    "action.condition.simplified.type.date": "Date",
    "action.condition.simplified.type.numeric": "Numérique",
    "action.condition.simplified.type.text": "Texte",
    "action.condition.simplified.type.time": "Temps",

    /**
     * FUNCTION - LOOP
     */
    "action.loop.conditions": "Conditions",
    "action.loop.configurations": "Configurations",
    "action.loop.incrementVariable": "Variable à incrémenter",
    "action.loop.alwaysDoOnce": "Faire la boucle au moins une fois",
    "action.loop.resetVariable": "Réinitialiser la variable lors de la première boucle",
    "action.loop.preview": "Aperçu (Boucle tant que la condition est VRAI)",

    /**
     * FUNCTION - ROUTING PROCESS
     */
    "action.routingProcess.process": "Processus",
    "action.routingProcess.processVersion": "Version",
    "action.routingProcess.selectProcess": "Sélectionner un processus",
    "action.routingProcess.selectVersion": "Sélectionner une version",
    "action.routingProcess.checkPrevious": "Vérifier si l'étape précédente a été complétée (si présente)",

    /**
     * FUNCTION - CONNECTOR
     */
    // GENERALs
    "action.connector.failOnCommandFailure": "Échouer le processus si la commande échoue",
    "action.connector.storeTo": "Stocker dans",
    "action.connector.storeConsoleOutputTo": "Stocker la sortie de la console dans",
    "action.connector.type": "Type",
    "action.connector.type.file": "Fichier",
    "action.connector.type.rest": "REST",
    "action.connector.type.shell": "Shell",
    // FILE
    "action.connector.file.action": "Action",
    "action.connector.file.action.read": "Lire",
    "action.connector.file.action.read.fileType": "Type de fichier",
    "action.connector.file.action.read.fileType.binary": "Binaire",
    "action.connector.file.action.read.fileType.text": "Texte",
    "action.connector.file.action.write": "Écrire",
    "action.connector.file.action.write.data": "Données",
    "action.connector.file.filePath": "Chemin du fichier",

    // REST
    "action.connector.rest.contentType": "Type de contennu",
    "action.connector.rest.key": "Clé",
    "action.connector.rest.method": "Méthode",
    "action.connector.rest.method.delete": "DELETE",
    "action.connector.rest.method.get": "GET",
    "action.connector.rest.method.post": "POST",
    "action.connector.rest.method.put": "PUT",
    "action.connector.rest.params.body": "Corps",
    "action.connector.rest.params.header": "Entête",
    "action.connector.rest.params.headers": "Entêtes",
    "action.connector.rest.params.query": "Requête",
    "action.connector.rest.params.queries": "Requêtes",
    "action.connector.rest.requestResponseType.appJSON": "Application/JSON",
    "action.connector.rest.requestResponseType.appJSONRaw": "Application/JSON-Raw",
    "action.connector.rest.requestResponseType.appXML": "Application/XML",
    "action.connector.rest.responseType": "Type de réponse",
    "action.connector.rest.url": "URL",
    "action.connector.rest.value": "Valeur",

    // SHELL
    "action.connector.shell.command": "Commande",
    "action.connector.shell.workingDirectory": "Répertoire de travail",

    // INPUT
    "action.input.general": "Général",
    "action.input.enabled": "Activé",
    "action.input.disabled": "Désactivé",
    "action.input.type": "Type",
    "action.input.trackingId": "Numéro de suivi",
    "action.input.referenceId": "Bon de travail",
    "action.input.allowBatch": "Autoriser le lot",
    "action.input.schedulerPlay": "Jouer Planificateur",
    "action.input.manualPlay": "Jouer manuel",
    "action.input.regexValidation": "Regex de validation",
    "action.input.regexTest": "Valeur de test",
    "action.input.regexTestButton": "Tester la regex",
    "action.input.manualEntry": "Entrée manuelle",
    "action.input.autoGenerated": "Généré automatiquement",
    "action.input.trackingIdLabel": "Étiquette",
    "action.input.prepopulatedValue": "Valeur pré-populée",
    "action.input.prepopulatedValueLock": "Bloquer",
    "action.input.prepopulatedValueError": "Le champ sera bloqué, mais il n'y a pas de valeur pré-populée",
    "action.input.referenceIdLabel": "ID de référence",
    "action.input.yes": "Oui",
    "action.input.no": "Non",
    "action.input.batchProcessing": "Traitement par lots",
    "action.input.enableBatchProcessing": "Activer le traitement par lots",
    "action.input.maxBatchQty": "Max. Quantité de lot",
    "action.input.quantity": "Quantité",
    "action.input.regex.nomatch": "Aucune correspondance",
    "action.input.regex.invalid": "Regex invalide",
    "action.input.job": "Job",

    /**
     * FUNCTION - EDITOR
     */
    "action.editor.addStep": "Ajouter",
    "action.editor.copy": "Copier",
    "action.editor.delete": "Supprimer",
    "action.editor.edit": "Éditer",
    "action.editor.read": "Lire",
    "action.editor.paste": "Coller",
    "action.editor.redo": "Rétablir",
    "action.editor.undo": "Annuler",
    "action.editor.actionEditor": "Éditeur d'action",
    "action.editor.untitled": "Sans titre",

    /**
     * FUNCTION - STEP EDITOR
     */
    "action.stepEditor.electronicSignatureApproval": "Approbation par signature électronique",
    "action.stepEditor.electronicSignatureApprovalContext": "Message pour l'approbateur",
    "action.stepEditor.electronicSignatureApprovalContext.error": "La longeur du texte doit être d'au moins 10 caractères.",
    "action.stepEditor.autoPlayNext": "Jouer l'étape suivante lorsque terminée",
    "action.stepEditor.cancel": "Annuler",
    "action.stepEditor.createAnother": "Créer une autre",
    "action.stepEditor.label": "Étiquette",
    "action.stepEditor.label.error": "L'étiquette doit avoir au moins 3 lettres.",
    "action.stepEditor.minimumTime": "Temps minimum",
    "action.stepEditor.none": "Aucun",
    "action.stepEditor.save": "Enregistrer",
    "action.stepEditor.targetTime": "Temps cible",
    "action.stepEditor.title": "Réglages de l'étape",
    "action.stepEditor.canRetry": "Peut réessayer",
    "action.stepEditor.continueIfFail": "Continuer en cas de problèmes",
    "action.stepEditor.validationGroup": "Groupe de validation",

    /**
     * MATH - EDITOR
     */
    "action.math.equation": "Équation",
    "action.math.storeTo": "Stocker dans",

    /**
     * MESSAGE - EDITOR
     */
    "action.message.message": "Message",
    "action.message.storeTo": "Stocker dans",
    "action.message.level": "Niveau du message",
    "action.message.error": "Erreur",
    "action.message.informative": "Informatif",
    "action.message.warning": "Attention",

    /**
     * SET - EDITOR
     */
    "action.set.value": "Valeur",
    "action.set.storeTo": "Stocker dans",

    /**
     * FUNCTION - WORK INSTRUCTIONS
     */
    // STEP
    "action.workInstructions.stepModifier.trainingCommunique": "Un communiqué de formation est assigné à cette étape.",
    // BOM
    "action.workInstructions.bom.addToStep": "Ajouter à l'étape",
    "action.workInstructions.bom.bom": "BOM",
    "action.workInstructions.bom.bomLoadError": "Une erreur est survenue lors du chargement du BOM.",
    "action.workInstructions.bom.information": "Information",
    "action.workInstructions.bom.bomItem": "Items BOM",
    "action.workInstructions.bom.itemStepBom": "Items de l'étape",
    "action.workInstructions.bom.itemBubbleNumber": "#",
    "action.workInstructions.bom.itemPartNumber": "Numéro de pièce",
    "action.workInstructions.bom.revision": "Rév:",
    "action.workInstructions.bom.bomItemQuantity": "Quantité",
    "action.workInstructions.bom.serialize": "Sérialiser",
    "action.workInstructions.bom.deserialize": "Désérialiser",
    "action.workInstructions.bom.value": "Valeur",
    // ELEMENT TOOLBAR
    "action.workInstructions.toolbar.form": "Formulaire",
    "action.workInstructions.toolbar.image": "Image",
    "action.workInstructions.toolbar.line": "Trait",
    "action.workInstructions.toolbar.marker": "Marqueur",
    "action.workInstructions.toolbar.mobile": "Mobile",
    "action.workInstructions.toolbar.pdf": "PDF",
    "action.workInstructions.toolbar.preview": "Aperçu",
    "action.workInstructions.toolbar.saveTemplate": "Enregistrer",
    "action.workInstructions.toolbar.selectTemplate": "Sélectionner",
    "action.workInstructions.toolbar.shape": "Forme",
    "action.workInstructions.toolbar.text": "Texte",
    "action.workInstructions.toolbar.communique": "Communiqué",
    "action.workInstructions.toolbar.label": "Étiquette",
    "action.workInstructions.toolbar.video": "Vidéo",
    // ELEMENT MENU
    "action.workInstructions.elementMenu.back": "Mettre à l'arrière-plan",
    "action.workInstructions.elementMenu.backward": "Déplacer vers l'arrière",
    "action.workInstructions.elementMenu.delete": "Supprimer",
    "action.workInstructions.elementMenu.edit": "Éditer",
    "action.workInstructions.elementMenu.forward": "Déplacer vers l'avant",
    "action.workInstructions.elementMenu.front": "Mettre au premier plan",
    // EDIT DIALOG (GENERAL)
    "action.workInstructions.editDialog.cancel": "Annuler",
    "action.workInstructions.editDialog.confirm": "Confirmer",
    "action.workInstructions.editDialog.save": "Sauvegarder",
    // FORM
    "action.workInstructions.form.approvalGroupId": "Groupe d'approbation",
    "action.workInstructions.form.emptySelection": "Sélectionnez un élément à modifier",
    "action.workInstructions.form.label": "Étiquette",
    "action.workInstructions.form.list": "Liste",
    "action.workInstructions.form.storeTo": "Stocker dans",
    "action.workInstructions.form.title": "Réglages du formulaire",
    "action.workInstructions.form.format": "Format (regex)",
    "action.workInstructions.form.formatErrorMessage": "Message d'erreur de format",
    "action.workInstructions.form.required": "Requis",
    "action.workInstructions.form.defaultValue": "Valeur par défaut",
    "action.workInstructions.form.multiline": "Multiligne",
    "action.workInstructions.form.inputType": "Type d'entrée",
    "action.workInstructions.form.inputType.approval": "Approbation",
    "action.workInstructions.form.inputType.checkbox": "Case à cocher",
    "action.workInstructions.form.inputType.checklist": "Liste de cases à cocher",
    "action.workInstructions.form.inputType.dropdown": "Liste déroulante",
    "action.workInstructions.form.inputType.label": "Étiquette",
    "action.workInstructions.form.inputType.radio": "Bouton radio",
    "action.workInstructions.form.inputType.textbox": "Zone de texte",
    "action.workInstructions.form.inputType.textboxlist": "Zone de texte liste",
    "action.workInstructions.form.error.required": "Ce champ est obligatoire",
    "action.workInstructions.form.error.invalidFormat": "Format invalide",
    "action.workInstructions.form.error.invalidRegexError": "Regex invalide",
    "action.workInstructions.form.error.minItems": "Pas assez d'éléments. Le minimum est: ",
    "action.workInstructions.form.error.maxItems": "Trop d'éléments. Le maximum est: ",
    "action.workInstructions.form.tabIndex": "Index de tabulation",
    "action.workInstructions.form.formItemLabel": "Étiquette d'un élément de formulaire",
    "action.workInstructions.form.minItems": "Nb. minimum items",
    "action.workInstructions.form.maxItems": "Nb. maximum items",

    // IMAGE
    "action.workInstructions.image.title": "Sélection de l'image",
    "action.workInstructions.image.select": "Sélectionner",
    "action.workInstructions.image.cancel": "Annuler",
    "action.workInstructions.image.zoom": "Zoomer",
    "action.workInstructions.image.editTooltip": "Modifier l'info-bulle",
    "action.workInstructions.image.tooltipEditor.title": "Modifier l'info-bulle",
    "action.workInstructions.image.tooltipEditor.originalTooltip": "Info-bulle originale",
    "action.workInstructions.image.tooltipEditor.customTooltip": "Info-bulle personnalisée",
    // LINE
    "action.workInstructions.line.color": "Couleur",
    "action.workInstructions.line.dash": "Tiret",
    "action.workInstructions.line.end": "Fin",
    "action.workInstructions.line.start": "Début",
    "action.workInstructions.line.thickness": "Épaisseur",
    "action.workInstructions.line.title": "Réglages du trait",
    // MARKER
    "action.workInstructions.marker.arrowCount": "Flèches",
    "action.workInstructions.marker.backgroundColor": "Couleur de fond",
    "action.workInstructions.marker.shape": "Forme",
    "action.workInstructions.marker.shape.circle": "Cercle",
    "action.workInstructions.marker.shape.rectangle": "Rectangle",
    "action.workInstructions.marker.shape.triangle": "Triangle",
    "action.workInstructions.marker.text": "Texte",
    "action.workInstructions.marker.textColor": "Couleur du texte",
    "action.workInstructions.marker.title": "Réglages du marqueur",

    /**
     * BOM ASSIGNMENT DILAOG
     */
    "bomAssignmentDialog.bomId": "BOM ID",
    "bomAssignmentDialog.bomRev": "BOM Révision",
    "bomAssignmentDialog.selectNodes": "Sélectionnez les dossiers et les processus qui seront mis à jour:",
    "bomAssignmentDialog.unselectAll": "TOUT DÉSELECTIONNER",
    "bomAssignmentDialog.onlyUpdatesDraft": "* Ce changement affectera uniquement les processus brouillons.",

    /**
     * BOM PAGE
     */
    "bomPage.adjustmentType": "Type d'ajustement",
    "bomPage.adjustmentType.edit": "Modifier",
    "bomPage.adjustmentType.multiTraceability": "Multi-traçabilité",
    "bomPage.adjustmentType.overconsumption": "Surconsommation",
    "bomPage.adjustmentType.reassignment": "Réassignation",
    "bomPage.adjustmentType.remove": "Retirer",
    "bomPage.adjustmentType.replacement": "Remplacement",
    "bomPage.adjustmentType.standard": "Standard",
    "bomPage.bomTable.addTraceability": "Ajouter",
    "bomPage.bomTable.bubbleNumber": "#",
    "bomPage.bomTable.completionDate": "Date de complétion",
    "bomPage.bomTable.date": "Date",
    "bomPage.bomTable.description": "Description",
    "bomPage.bomTable.duplicatedTraceability": "Traçabilité dupliquée",
    "bomPage.bomTable.edit": "Modifier",
    "bomPage.bomTable.justification": "Justification",
    "bomPage.bomTable.symptom": "Symptôme",
    "bomPage.bomTable.lastModificationDate": "Date de dernière modification",
    "bomPage.bomTable.location": "Localisation",
    "bomPage.bomTable.materialLocation": "Localisation matériel",
    "bomPage.bomTable.lotSerialType": "Lot/Serial Type", // FIXME: How to translate?
    "bomPage.bomTable.multipleTraceabilities": "Traçabilité multiple", // Est-ce le bon terme?
    "bomPage.bomTable.operations": "Opérations",
    "bomPage.bomTable.originalTraceability": "Traçabilité originale",
    "bomPage.bomTable.overconsumption": "Surconsommation",
    "bomPage.bomTable.partNumber": "Numéro de pièce",
    "bomPage.bomTable.process": "Processus",
    "bomPage.bomTable.processAction": "Action",
    "bomPage.bomTable.processActionStep": "Étape",
    "bomPage.bomTable.quantity": "Quantité",
    "bomPage.bomTable.previousQuantity": "Quantité précédente",
    "bomPage.bomTable.newQuantity": "Nouvelle quantité",
    "bomPage.bomTable.quantitySum": "La somme des quantités doit être égale à",
    "bomPage.bomTable.remove": "Retirer",
    "bomPage.bomTable.replacement": "Remplacement",
    "bomPage.bomTable.searchPartNumberOrDescription": "Rechercher un numéro de pièce ou une description",
    "bomPage.bomTable.startDate": "Date de début",
    "bomPage.bomTable.status": "Statut",
    "bomPage.bomTable.summary": "Sommaire",
    "bomPage.bomTable.traceability": "Traçabilité",
    "bomPage.bomTable.previousTraceability": "Traçabilité précédente",
    "bomPage.bomTable.serialNumber": "Numéro de série",
    "bomPage.bomTable.newTraceability": "Nouvelle traçabilité",
    "bomPage.bomTable.newSerialNumber": "Nouveau numéro de série",
    "bomPage.bomTable.history": "Historique",
    "bomPage.bomTable.totalColon": "Total :",
    "bomPage.bomTable.serialNumberNoLongerExists": "Le numéro de série affiché n'existe plus car il a été retiré.",
    "bomPage.bomTable.traceabilityNoLongerExists": "La traçabilité affichée n'existe plus car elle a été retirée.",
    "bomPage.bomTable.traceabilityNonTraceableItem": "L'article n'est pas traçable et n'a donc aucune traçabilité.",
    "bomPage.bomTable.trackingId": "Numéro de suivi",
    "bomPage.bomTable.type": "Type",
    "bomPage.bomTable.unit": "Unité",
    "bomPage.bomTable.warningItemQuantityExceeded":
        "Cet item a une quantité supérieure à celle indiquée dans le BOM (surconsommations non incluses).",
    "bomPage.bomTable.warningItemQuantityExpected": "Attendue:",
    "bomPage.bomTable.warningItemQuantityReceived": "Reçue:",
    "bomPage.bomTable.workOrder": "Bon de travail",

    "bomPage.bomTable.createdSerialNumber": "Numéro de série créé",
    "bomPage.bomTable.createdTraceability": "Traçabilité créée",
    "bomPage.bomTable.editedSerialNumber": "Numéro de série modifié",
    "bomPage.bomTable.editedTraceability": "Traçabilité éditée",
    "bomPage.bomTable.multiTraceability": "Multi-traçabilité",
    "bomPage.bomTable.overconsumedTraceability": "Traçabilité surconsommée",
    "bomPage.bomTable.reassignedSerialNumber": "Numéro de série réassignée",
    "bomPage.bomTable.reassignedTraceability": "Traçabilité réassignée",
    "bomPage.bomTable.removedSerialNumber": "Numéro de série retiré",
    "bomPage.bomTable.removedTraceability": "Traçabilité retirée",
    "bomPage.bomTable.replacedSerialNumber": "Numéro de série remplacé",
    "bomPage.bomTable.replacedTraceability": "Traçabilité remplacée",
    "bomPage.bomTable.serialNumberDissociated": "Numéro de série dissocié",
    "bomPage.bomTable.serialNumberReassociated": "Numéro de série réassocié",

    "bomPage.date": "Date",
    "bomPage.export": "Exporter",
    "bomPage.filter": "Filtre",
    "bomPage.from": "De",
    "bomPage.generate": "Générer",
    "bomPage.noData": "Aucune donnée n'a été chargée. Sélectionnez un type de recherche, entrez une valeur et cliquez sur Générer.",
    "bomPage.noDataFound": "Aucune donnée trouvée.",
    "bomPage.nonTraceableItems": "Items non traçables",
    "bomPage.searchType": "Type de recherche",
    "bomPage.searchType.adjustmentType": "Type d'ajustement",
    "bomPage.searchType.adjustmentTypeSerialNumber": "Type d'ajustement - Numéro de série",
    "bomPage.searchType.jobRefId": "Bon de commande",
    "bomPage.searchType.partNumber": "Numéro de pièce",
    "bomPage.searchType.secondaryTraceabilitySerialNumber": "Traçabilité secondaire - Numéro de série",
    "bomPage.searchType.symptom": "Symptôme",
    "bomPage.searchType.traceability": "Traçabilité",
    "bomPage.searchType.trackingId": "Numéro de suivi",
    "bomPage.to": "À",
    "bomPage.traceableItems": "Items traçables",
    "bomPage.value": "Valeur",

    // PREVIEW PANE
    "previewPane.noPreviewAvailable": "Aucun aperçu disponible",

    // PDF PLAYER
    "pdfPlayer.page": "Page ",
    "pdfPlayer.pageOf": " de ",

    // player
    "player.electronicSignatureRequired": "Signature électronique requise",
    "player.of": "manquant de",
    "player.add": "Ajouter",
    "player.addFailures": "Ajouter des échecs",
    "player.selectRelatedProcess": "Sélectionnez le processus associé",
    "player.processSteps": "Étape du processus",
    "player.processCompleted": "Processus complété avec succès!",
    "player.processMessageCircularProgress":
        "Attendez que toutes les données collectées soient enregistrées dans la base de données avant de pouvoir démarrer une autre exécution pour ce processus.",
    "player.processStopped": "Processus arrêté!",
    "player.processFailed": "Processus échoué!",
    "player.processFailureReport": "Rapport de processus échoué",
    "player.processVersion": "Version",
    "player.processVersionDraft": "Brouillon",
    "player.manualFailure": "Échec manuel",
    "player.runAgain": "Réexécuter",
    "player.close": "Fermer",
    "player.apply": "Appliquer",
    "player.stop": "Arrêt",
    "player.runAgainText": "Réexécuter le processus en cas de succès",
    "player.paused": "Pause",
    "player.step": "Étape",
    "player.steps": "Étapes",
    "player.send": "Envoyer",
    "player.retry": "Réessayer",
    "player.report": "Rapport",
    "player.status": "Statut",
    "player.select": "Sélectionner",
    "player.type": "Type",
    "player.symptom": "Symptôme",
    "player.comments": "Commentaires",
    "player.conditionPlayer.condition": "Condition",
    "player.conditionPlayer.substitution": "Substitution",
    "player.conditionPlayer.result": "Résultat",
    "player.mathPlayer.equation": "Équation",
    "player.mathPlayer.substitution": "Substitution",
    "player.mathPlayer.result": "Résultat",
    "player.parserPlayer.result": "Résultat",
    "player.inputPlayer.invalidEmpty": "Invalide: Valeur absente",
    "player.inputPlayer.invalidRegex": "Invalide: REGEX",
    "player.inputPlayer.previousProcessNotRun": "Processus précédent non exécuté",
    "player.inputPlayer.invalidBatchQty": "Quantité de lot non valide",
    "player.loop.doOnce": "Première itération toujours exécutée",
    "player.loop.maxCount": "Nombre maximal d'itérations",
    "player.loop.count": "Compteur d'itérations'",
    "player.setPlayer.tilte": "Attribution d'une valeur à une variable",
    "player.newVersionWarning": "Nouvelle version relâchée le ",
    "player.changeLog": "Historique de changement",
    "player.sign": "Soumettre la signature",
    "player.stepMessage": "Entrez votre message",
    "player.user": "Utilisateur",
    "player.password": "Mot de passe",
    "player.infoExecution": "Info",
    "player.addComment": "Commentaire",
    "player.bomFollowUp": "Follow-up",
    "player.startExecution": "Démarrer",
    "player.stopExecution": "Arrêter",
    "player.rewindStep": "Précédent",
    "player.nextStep": "Suivant",
    "player.pauseExecution": "Pause",
    "player.failExecution": "Billet d'échec",
    "player.trainingCommunique": "Communiqué de formation",
    "player.notTrainedForProcess":
        "Vous n'êtes pas formé et/ou certifié pour ce processus. Un formateur ou un opérateur formé et certifié doit vous accompagner.",
    "player.trainingCompletionExplanation": "Une signature électronique est nécessaire pour compléter la formation.",
    "player.trainingCompletionTitle": "Formation",
    "player.traineeElectronicSignatureRequiredContextTrained":
        "Je confirme avoir pris connaissance des modifications appliquées à ce processus.",
    "player.trainerElectronicSignatureRequiredContext":
        "Je confirme que je vais accompagner l'opérateur en formation tout au long de l'exécution du processus.",
    "player.trainerOrTrainedAndCertifiedOperator": "Formateur ou opérateur formé et certifié",
    "player.trainerRequired": "Opérateur formé requis",
    "player.selectTrainedOperator": "Sélectionner un utilisateur...",
    "player.validate": "Valider",
    "player.validationRequired": "Validation requise",
    "player.validationRequiredByTrainedUser": "Un opérateur formé doit valider l'étape.",
    "player.messageDialogTitleEnterMessage": "Étape du processus",
    "player.messageDialogTitleProcessRetryMessage": "Le processus a déjà été exécuté pour ce numéro de série. Justifier la réexécution :",
    "player.stopPlayerTitleConfirmation": "Arrêt du processus",
    "player.stopPlayerConfirmation": "Êtes-vous certain de vouloir arrêter l'exécution du processus?",
    "player.cancel": "Annuler",
    "player.confirm": "Confirmer",
    "player.continue": "Continuer",
    "player.wrongCredentials": "Identifiant incorrect",
    "player.wrongPassword": "Mot de passe incorrect",
    "player.fail": "Échoué",
    "player.failureTicketCreate.createFailureTicket": "Création du billet d'échec",
    "player.failureTicketCreate.createdJob": "Création d'un bon de travail",
    "player.failPathTitle": "Chemin d'échec",
    "player.failPathMessage": "Continuer pour finaliser l'échec.",
    "player.runnerHubFailureDialog.attempt": "Le système tente de ressayer l'opération... tentative #",
    "player.runnerHubFailureDialog.autoRetry": "Une opération système n'a pu être complétée.",
    "player.runnerHubFailureDialog.autoRetryUnsuccessful": "Le système n'a pu compléter l'opération.",
    "player.runnerHubFailureDialog.manualRetry": "Voulez-vous réessayer ou arrêter l'exécution du processus?",
    "player.runnerHubFailureDialog.retry": "Réessayer",
    "player.runnerHubFailureDialog.stop": "Arrêter",
    "player.runnerHubFailureDialog.title": "Erreur serveur",
    "player.reworkStatusItemDisabled": "Certains statuts peuvent être indisponibles car le processus n'a pas été arrêté manuellement.",

    "player.bom.bom": "BOM",
    "player.bom.step": "Étapes",
    "player.bom.stepItems": "Items de l'étape",
    "player.bom.bubbleNumber": "#",
    "player.bom.partNumber": "Numéro de pièce",
    "player.bom.quantity": "Quantité",
    "player.bom.qty": "Qté",
    "player.bom.description": "Description",
    "player.bom.location": "Localisation",
    "player.bom.materialLocation": "Localisation matériel",
    "player.bom.traceability": "Traçabilité",
    "player.bom.newTraceability": "Nouvelle traçabilité",
    "player.bom.justification": "Justification",
    "player.bom.unit": "Unité",
    "player.bom.edit": "Modifier",
    "player.bom.editItemTraceability": "Modifier la traçabilité de l'item",
    "player.bom.editItemSerialNumber": "Modifier le numéro de série de l'item",
    "player.bom.multiTraceability": "Traçabilité multiple",
    "player.bom.addNewLine": "Ajouter une nouvelle ligne",
    "player.bom.overconsumption": "Surconsommation",
    "player.bom.replacement": "Remplacement",
    "player.bom.actionName": "Nom de l'action",
    "player.bom.stepName": "Nom de l'étape",
    "player.bom.serialNumber": "Numéro de série",
    "player.bom.newSerialNumber": "Nouveau numéro de série",
    "player.bom.processBom": "BOM du processus",
    "player.bom.itemSerialized": "Item serialisé",
    "player.bom.loadingBomFollowUp": "Chargement du BOM follow-up",
    "player.bom.errorLoadingBomFollowUp": "Erreur lors du chargement du BOM follow-up",
    "player.bom.bomFollowUpSymptom": "Symptôme",
    "player.bom.bomFollowUpIncompleteDialogTitle": "Traçabilité incomplète",
    "player.bom.bomFollowUpIncompleteDialogExplanation":
        "Certaines traçabilités et/ou numéros de série sont vides. Êtes-vous sûr de vouloir passer à l'étape suivante?",

    "player.failed.PROCESS_MANUALLY_STOPPED_BY_USER": "Le processus a été arrêté manuellement par l'usager.",
    "player.failed.SEQUENCER_ACTION_CORE_REMOTE_STOP": "Le processus a été arrêté manuellement par l'usager.",
    "player.failed.PROCESS_FAILED_NO_ACTION_OUPUT": "Le processus a été arrêté, car il manque une connexion entre les actions.",
    "player.failed.PROCESS_FAILED_INVALID_RELEASE_DATES": "Le processus ne peut pas être joué, car il est retiré ou n'est pas relâché.",
    "player.failed.PROCESS_FAILED_JOB_PROCESS_NOT_READY": "Le processus ne peut pas être joué, car il n'est pas prêt ou en cours.",
    "player.failed.MANUAL_STEP_PROCESS_FAIL": "L'utilisateur a échoué le processus manuellement.",
    "player.failed.STEP_PROCESS_FAIL": "L'utilisateur a échoué le processus manuellement.",
    "player.failed.MANUAL_FAILURE_TICKET": "Le billet d'échec est créé manuellement.",
    "player.failed.FAILURE_TICKET_CREATED_WITHOUT_INTERRUPTING_EXECUTION": "Le billet d'échec est créé sans interrompre l'exécution.",
    "player.failed.failedQty": "Quantité échouée",
    "player.failed.qtyError": "La quantité doit être inférieure ou égale à ",
    "player.failed.trackingId": "Numéro suivi",
    "player.failed.partNumber": "Numéro de pièce",
    "player.failed.originalJobRefId": "Bon de travail (original)",
    "player.failed.job": "Bon de travail (billet échec)",
    "player.failed.enterTrackingTitle":
        "Entrer le numéro de suivi et appuyer sur le bouton recherche pour récupérer l'information disponible",
    "player.failed.selectProcessTitle":
        "Selectionner le processus associé à l'item pour retrouver les possibles statuts, type d'échecs et symptômes.",
    "player.failed.addFailuresTitle": "Ajouter tout les détails associés aux différents échecs",
    "player.failed.connector.shell": "Une commande shell échouée.",
    "player.failed.connector.rest": "Une commande rest a échouée.",
    "player.failed.failureTicket.forkError": "L'action n'a pas pu créer une reprise et un travail",
    "player.failed.failureTicket.requireJobAndRework": "Cette action nécessite une bon de travail et une réparation pour fonctionner.",
    "player.failed.input.unsupportedTrackingType": "Type de numéro de suivi non supporté.",
    "player.failed.parser.jsonParseError": "Une valeur n'a pas pu être analysée par l'action.",
    "player.failed.parser.jsonNoMatch": "La requête n'a pas trouvé de correspondance.",
    "player.failed.stop.triggerFailure": "Le processus a été arrêté par l'action Arrêt.",
    "player.failed.selectYieldType": "Sélectionner un type de rendement",
    "player.failed.processName": "Processus sélectionné",

    "player.runDetailsDialog.runDetails": "Détails de l'Exécution en Cours",
    "player.runDetailsDialog.currentDetails": "Exécution en Cours",
    "player.runDetailsDialog.single": "Simple",
    "player.runDetailsDialog.batch": "Lot",
    "player.runDetailsDialog.singleOrBatch": "Simple ou Lot",
    "player.runDetailsDialog.trackingNumber": "Numéro de suivi",
    "player.runDetailsDialog.maxQty": "Quantité Maximum",
    "player.runDetailsDialog.newProducts": "Nouveaux Produits",
    "player.runDetailsDialog.totalQty": "Quantité Totale",
    "player.runDetailsDialog.inProgressQty": "Quantité en Cours",
    "player.runDetailsDialog.failedQty": "Quantité échouée",
    "player.runDetailsDialog.moreInfo": "Plus d'Informations",
    "player.runDetailsDialog.runType": "Type d'exécution",
    "player.runDetailsDialog.testRun": "Éxécution de Test",
    "player.runDetailsDialog.dryRun": "Éxécution d'essaie",
    "player.runDetailsDialog.normalRun": "Éxécution Normal",
    "player.runDetailsDialog.runFromJobProcess": "Éxécution d'un Processus de Travail",
    "player.runDetailsDialog.isRework": "Éxécution d'un billet d'Échec",
    "player.runDetailsDialog.process": "Processus",
    "player.runDetailsDialog.processName": "Nom",
    "player.runDetailsDialog.releaseStatus": "Statut de Relâche",
    "player.runDetailsDialog.versionStatus": "Statut de Version",
    "player.runDetailsDialog.releasedOn": "Relâché le",
    "player.runDetailsDialog.jobProcess": "Processus de Travail",

    // PDF
    "action.workInstructions.pdf.allPagesStart": "Tous (",
    "action.workInstructions.pdf.allPagesEnd": ")",
    "action.workInstructions.pdf.asDocument": "Document",
    "action.workInstructions.pdf.asSteps": "Étapes",
    "action.workInstructions.pdf.importOptions": "Options d'importation",
    "action.workInstructions.pdf.title": "Sélection du PDF",
    "action.workInstructions.pdf.select": "Sélectionner",
    "action.workInstructions.pdf.cancel": "Annuler",
    "action.workInstructions.pdf.pageTitle": "Pages",
    "action.workInstructions.pdf.page": " page",
    "action.workInstructions.pdf.pages": " pages",
    "action.workInstructions.pdf.error.invalidFormat": "Format invalide",
    "action.workInstructions.pdf.error.invalidRange": "Pages hors des limites",

    // SHAPE
    "action.workInstructions.shape.border": "Bordure",
    "action.workInstructions.shape.border.none": "Aucune",
    "action.workInstructions.shape.borderColor": "Couleur de la bordure",
    "action.workInstructions.shape.color": "Couleur",
    "action.workInstructions.shape.title": "Réglages du la forme",
    "action.workInstructions.shape.type": "Type",
    "action.workInstructions.shape.type.arrow": "Flèche",
    "action.workInstructions.shape.type.circle": "Cercle",
    "action.workInstructions.shape.type.octagon": "Octogone",
    "action.workInstructions.shape.type.rectangle": "Rectangle",
    "action.workInstructions.shape.type.triangle": "Triangle",
    // TEMPLATE EDITOR
    "action.workInstructions.template.editor.applyToAll": "Appliquer les changements à toutes les étapes utilisant ce modèle",
    "action.workInstructions.template.editor.color": "Couleur",
    "action.workInstructions.template.editor.description": "Description",
    "action.workInstructions.template.editor.descriptionRequired": "Une description est requise.",
    "action.workInstructions.template.editor.label": "Étiquette",
    "action.workInstructions.template.editor.labelRequired": "Une étiquette est requise.",
    "action.workInstructions.template.editor.newTemplate": "Nouveau modèle",
    "action.workInstructions.template.editor.overwrite": "Écraser",
    "action.workInstructions.template.editor.save": "Enregistrer",
    "action.workInstructions.template.editor.saveAs": "Enregistrer sous",
    "action.workInstructions.template.editor.title": "Réglages du modèle",
    // TEMPLATE SELECTOR
    "action.workInstructions.template.selector.delete": "Supprimer",
    "action.workInstructions.template.selector.search": "Rechercher",
    "action.workInstructions.template.selector.select": "Sélectionner",
    "action.workInstructions.template.selector.title": "Sélection du modèle",
    // TRAINING COMMUNIQUE EDITOR
    "action.workInstructions.player.trainingCommuniqueEditorTitle": "Des modifications ont été appliquées à cette étape.",
    "action.workInstructions.toolbar.trainingCommuniqueEditorLabel": "Tapez la liste des modifications appliquées à cette étape.",
    "action.workInstructions.toolbar.trainingCommuniqueEditorTitle": "Communiqué de formation",
    // TEXT
    "action.workInstructions.text.backgroundColor": "Couleur de fond",
    "action.workInstructions.text.border": "Bordure",
    "action.workInstructions.text.border.none": "Aucune",
    "action.workInstructions.text.borderColor": "Couleur de la bordure",
    "action.workInstructions.text.emptyTextError": "Doit contenir au moins 1 caractère",
    "action.workInstructions.text.style": "Style",
    "action.workInstructions.text.style.header1": "Entête 1",
    "action.workInstructions.text.style.header2": "Entête 2",
    "action.workInstructions.text.style.header3": "Entête 3",
    "action.workInstructions.text.style.normal": "Normal",
    "action.workInstructions.text.style.small": "Petit",
    "action.workInstructions.text.style.title": "Titre",
    "action.workInstructions.text.horizontalAlignment": "Alignement horizontal",
    "action.workInstructions.text.horizontalAlignment.center": "Centre",
    "action.workInstructions.text.horizontalAlignment.left": "Gauche",
    "action.workInstructions.text.horizontalAlignment.right": "Droite",
    "action.workInstructions.text.padding": "Marge",
    "action.workInstructions.text.padding.none": "Aucune",
    "action.workInstructions.text.text": "Texte",
    "action.workInstructions.text.textColor": "Couleur du texte",
    "action.workInstructions.text.title": "Réglages du texte",
    "action.workInstructions.text.verticalAlignment": "Alignement vertical",
    "action.workInstructions.text.verticalAlignment.bottom": "Bas",
    "action.workInstructions.text.verticalAlignment.middle": "Milieu",
    "action.workInstructions.text.verticalAlignment.top": "Haut",
    // VIDEO
    "action.workInstructions.video.title": "Sélection de la vidéo",
    "action.workInstructions.video.select": "Sélectionner",
    "action.workInstructions.video.cancel": "Annuler",

    /**
     * ACTION - PARSER
     */
    "action.parser.json": "JSON",
    "action.parser.jsonPath": "Chemin",
    "action.parser.jsonReference": "Référence",
    "action.parser.jsonReferenceClipboardMessage": "Copié dans le presse-papier",
    "action.parser.jsonReferenceDescription": "Description",
    "action.parser.jsonReferenceExample": "Exemple",
    "action.parser.jsonReferenceOperator": "Opérateur",
    "action.parser.jsonReferenceOperator1": "$",
    "action.parser.jsonReferenceOperator2": "@",
    "action.parser.jsonReferenceOperator3": ".",
    "action.parser.jsonReferenceOperator4": "..",
    "action.parser.jsonReferenceOperator5": "*",
    "action.parser.jsonReferenceOperator6": "[]",
    "action.parser.jsonReferenceOperator7": "[,]",
    "action.parser.jsonReferenceOperator8": "[début:fin:pas]",
    "action.parser.jsonReferenceOperator9": "?()",
    "action.parser.jsonReferenceOperator10": "()",
    "action.parser.jsonReferenceOperatorDescription1": "L'oject/élément racine",
    "action.parser.jsonReferenceOperatorDescription2": "L'object/élément courant",
    "action.parser.jsonReferenceOperatorDescription3": "Opérateur membre enfant",
    "action.parser.jsonReferenceOperatorDescription4": "Opérateur descendant récursif",
    "action.parser.jsonReferenceOperatorDescription5":
        "Caractère générique correspondant à tous les objets/éléments quel que soit leur nom",
    "action.parser.jsonReferenceOperatorDescription6": "Opérateur d'indice",
    "action.parser.jsonReferenceOperatorDescription7":
        "Opérateur d'union pour les noms alternatifs ou les indices de tableau sous forme d'ensemble",
    "action.parser.jsonReferenceOperatorDescription8": "Opérateur de tranche de tableau",
    "action.parser.jsonReferenceOperatorDescription9": "Applique une expression de filtre (script) via une évaluation statique",
    "action.parser.jsonReferenceOperatorDescription10": "Expression de script via une évaluation statique",
    "action.parser.jsonReferencePath": "Chemin",
    "action.parser.jsonReferencePath1": "$.boutique.livre[*].auteur",
    "action.parser.jsonReferencePath2": "$..auteur",
    "action.parser.jsonReferencePath3": "$.boutique.*",
    "action.parser.jsonReferencePath4": "$.boutique..prix",
    "action.parser.jsonReferencePath5": "$..livre[2]",
    "action.parser.jsonReferencePath6": "$..livre[(@.length-1)]",
    "action.parser.jsonReferencePath7": "$..livre[-1:]",
    "action.parser.jsonReferencePath8": "$..livre[0,1]",
    "action.parser.jsonReferencePath9": "$..livre[:2]",
    "action.parser.jsonReferencePath10": "$..livre[?(@.isbn)]",
    "action.parser.jsonReferencePath11": "$..livre[?(@.prix<10)]",
    "action.parser.jsonReferencePath12": "$..livre[?(@.prix==8.95)]",
    "action.parser.jsonReferencePath13": "$..livre[?(@.prix<30 && @.categorie=='fiction')]",
    "action.parser.jsonReferencePath14": "$..*",
    "action.parser.jsonReferencePathDescription1": "Les auteurs de tous les livres de la boutique",
    "action.parser.jsonReferencePathDescription2": "Tous les auteurs",
    "action.parser.jsonReferencePathDescription3": "Toutes les choses de la boutique",
    "action.parser.jsonReferencePathDescription4": "Le prix de tout ce qu'il y a dans la boutique",
    "action.parser.jsonReferencePathDescription5": "Le troisième livre",
    "action.parser.jsonReferencePathDescription6": "Le dernier livre (via une indice de script)",
    "action.parser.jsonReferencePathDescription7": "Le dernier livre (via une tranche de tableau)",
    "action.parser.jsonReferencePathDescription8": "Les deux premiers livres (via une union d'indice)",
    "action.parser.jsonReferencePathDescription9": "Les deux premiers livres (via une tranche de tableau d'indices)",
    "action.parser.jsonReferencePathDescription10": "Filtrer tous les livres avec un numéro isbn",
    "action.parser.jsonReferencePathDescription11": "Filtrer tous les livres qui coûtent moins que 10",
    "action.parser.jsonReferencePathDescription12": "Filtrer tous les livres qui coûtent 8,95",
    "action.parser.jsonReferencePathDescription13": "Filtrer tous les livres de fiction qui coûtent moins que 30",
    "action.parser.jsonReferencePathDescription14": "Tous les membres d'une structure JSON",
    "action.parser.jsonReferenceSyntax": "Syntaxe",
    "action.parser.jsonTest": "JSON à tester",
    "action.parser.jsonTestObject": "Objet JSON",
    "action.parser.jsonTestResult": "Résultat du test JSON",
    "action.parser.source": "Chaîne de départ",
    "action.parser.regex": "Regex",
    "action.parser.regexEnd": "Regex (Fin)",
    "action.parser.regexStart": "Regex (Début)",
    "action.parser.regexAction": "Action",
    "action.parser.regexExtract": "Extraire",
    "action.parser.regexExtractFirstMatch":
        "Cette fonctionalité extrait uniquement la première occurence qui satisfait les expressions régulières.",
    "action.parser.regexFlags": "Drapeaux",
    "action.parser.regexFlagsEnd": "Drapeaux (Fin)",
    "action.parser.regexFlagsStart": "Drapeaux (Début)",
    "action.parser.regexSubstitution": "Substitution",
    "action.parser.regexValidation": "Validation",
    "action.parser.storeTo": "Stocker dans",
    "action.parser.test": "Tester",
    "action.parser.type": "Type",

    /**
     * ACTION - TIME
     */
    "action.time.conversion": "Conversion",
    "action.time.convertDateToTimestamp": "Date en horodatage",
    "action.time.convertTimestampToDate": "Horodatage en date",
    "action.time.countdown": "Compte à rebours",
    "action.time.countdownCreated": "Un nouveau compte à rebours a été créé",
    "action.time.function": "Fonction",
    "action.time.getDayFromTimestamp": "Jour d'un horodatage",
    "action.time.getHoursFromTimestamp": "Heures d'un horodatage",
    "action.time.getMinutesFromTimestamp": "Minutes d'un horodatage",
    "action.time.getMonthFromTimestamp": "Mois d'un horodatage",
    "action.time.getSecondsFromTimestamp": "Secondes d'un horodatage",
    "action.time.getTimestamp": "Obtenir un horodatage",
    "action.time.getYearFromTimestamp": "Année d'un horodatage",
    "action.time.startTimer": "Commencer",
    "action.time.stopTimer": "Arrêter",
    "action.time.storeTo": "Stocker dans",
    "action.time.time": "Temps",
    "action.time.timer": "Minuteur",
    "action.time.timerCreated": "Un nouveau minuteur a été créé",
    "action.time.type": "Type",
    "action.time.value": "Valeur",
    "action.time.wait": "Attendre",

    /**
     * ACTION - failureTicketFork
     */
    "action.failureTicketFork.workOrder": "Bon de commande du billet d'échec",

    /**
     * CORE COMPONENTS
     */
    "coreComponents.languageSelector.selectLanguage": "Selectionner une langue",
    "coreComponents.languageSelector.noLanguagesAvailable": "Aucune autre langue disponible",

    "coreComponents.KortexTextFieldLocation.close": "Fermer",
    "coreComponents.KortexTextFieldLocation.mainLanguage": "Langue principale",
    "coreComponents.KortexTextFieldLocation.secondaryLanguages": "Langues secondaires",
    "coreComponents.KortexTextFieldLocation.save": "Sauvegarder",
    "coreComponents.KortexTextFieldLocation.title": "Champs de traduction",
    "coreComponents.KortexTextFieldLocation.translateAll": "Tout traduire",

    /**
     * INFO PAGE
     */
    "info.unknown": "Inconnue",
    "info.fullName": "Nom",
    "info.userName": "Nom d'usager",
    "info.hub": "Noyau",
    "info.runner": "Exécuteur",
    "info.storage": "Stockage",
    "info.ui": "IU App",
    "info.notConnected": "Non connecté",
    "info.connection": "Connexion",
    "info.version": "Version",
    "info.host": "Hôte",

    /**
     * KORTEX-REACT-COMPONENTS
     */
    // COLOR PICKER
    "kortexReactComponent.kortexColorPicker.opacity": "Opacité",

    /**
     * PROCESS LOCALIZATION PAGE
     */
    "processLocalization.actionLabel": "Étiquette de l'action",
    "processLocalization.actionType": "Type d'action",
    "processLocalization.cancel": "Annuler",
    "processLocalization.componentType": "Type de composante",
    "processLocalization.default": "(défaut)",
    "processLocalization.deutsch": "DE",
    "processLocalization.english": "EN",
    "processLocalization.french": "FR",
    "processLocalization.processTrackingLabel": "Étiquette de suivi de process",
    "processLocalization.textElement": "Élément de texte",
    "processLocalization.save": "Sauvegarder",
    "processLocalization.stepLabel": "Étiquette de l'étape",
    "processLocalization.title": "Internationalisation du processus",

    /**
     * LOGIN
     */
    "login.advsettings": "Options avancées",
    "login.connect": "Connexion",
    "login.instance": "Instance",
    "login.language": "Langue",
    "login.password": "Mot de passe",
    "login.username": "Usager",

    /**
     * MAIN MENU
     */
    "menu.bom": "BOM",
    "menu.dashboards": "Tableaux de bord",
    "menu.file.manager": "Gestion des fichiers",
    "menu.process.editor": "Procédures & routages",
    "menu.process.player": "Éxécution",
    "menu.process.scheduler": "Planification",
    "menu.process.operatorScheduler": "Liste de travail",
    "menu.reports": "Rapports",
    "menu.info": "Info",
    "menu.approvals": "Approbations",
    "menu.kanban": "Kanban",
    "menu.settings": "Paramètres",
    "menu.settings.users": "Utilisateurs",
    "menu.settings.groups": "Groupes",
    "menu.settings.roles": "Rôles",
    "menu.settings.tags": "Clés",
    "menu.settings.workzones": "Zones de travail",
    "menu.settings.globals": "Paramètres généraux",
    "menu.tasks": "Tâches",
    "menu.reworks": "Billet d'Échecs",
    "menu.training": "Formation",

    /**
     * PROCESS CHANGELOG
     */
    "processChangelog.emptyMessage": "No changes logged yet",

    /**
     * PROCESS TRAINING COMMUNIQUÉ DRAWER
     */
    "processEditor.trainingCommunique.action": "Action",
    "processEditor.trainingCommunique.step": "Étape",
    "processEditor.trainingCommunique.stepNumber": "# étape",
    "processEditor.trainingCommunique.version": "Version du processus",

    /**
     * PROCESS VALIDATION
     */
    "processEditor.validation.action": "Action",
    "processEditor.validation.error": "Erreur",
    "processEditor.validation.errorFound": "Erreur(s) trouvées",
    "processEditor.validation.errorFoundSubmitVersion": "Validation: Erreur(s) trouvées",
    "processEditor.validation.na": "N/A",
    "processEditor.validation.processNotFound": "Le processus n'a pu être trouvé...",
    "processEditor.validation.step": "Étape",
    "processEditor.validation.success": "Succès! Aucune erreur trouvée!",
    "processEditor.validation.successSubmitVersion": "Validation: Succès!",
    "processEditor.validation.validating": "En cours de validation...",
    "processEditor.validation.errorCode.process.inputNotFound": "ENTRÉE introuvable",
    "processEditor.validation.errorCode.process.outputNotFound": "SORTIE introuvable",
    "processEditor.validation.errorCode.process.outputNotFoundFailPath": "SORTIE introuvable dans le chemin d'erreur",
    "processEditor.validation.errorCode.processAction.emptyField": "Champ vide: ",
    "processEditor.validation.errorCode.processAction.invalidActionId": "L'action suivante est introuvable: ",
    "processEditor.validation.errorCode.processAction.invalidVariable": "La variable suivante est introuvable: ",
    "processEditor.validation.errorCode.processAction.missingLoopReturnInput": "Lien manquant sur une BOUCLE",
    "processEditor.validation.errorCode.processAction.missingOutputLink": "Lien manquant sur une action",
    "processEditor.validation.errorCode.processAction.typeMismatch": "Erreur de type: ",
    "processEditor.validation.errorCode.processAction.valueNotValid": "Valeur invalide",
    "processEditor.validation.errorCode.processAction.bomItem": "Item de BOM invalide.",
    "processEditor.validation.errorCode.processAction.bomItemMessage": "Cet item n'existe pas dans le BOM.",

    /**
     * PROCESS VARIABLE MANAGER
     */
    "processVariableManager.addVariable": "Ajouter une variable",
    "processVariableManager.appendValue": "Ajouter",
    "processVariableManager.clear": "Effacer",
    "processVariableManager.close": "Fermer",
    "processVariableManager.currentValue": "Valeur actuelle",
    "processVariableManager.delete": "Supprimer",
    "processVariableManager.edit": "Éditer",
    "processVariableManager.decimals": "Décimales",
    "processVariableManager.defaultValue": "Valeur par défaut",
    "processVariableManager.deleteDisabledMessage":
        "Cette variable ne peut pas être supprimée, car elle est utilisée par les actions suivantes:",
    "processVariableManager.invalidIdentifier":
        "Identifiant invalide: il ne doit contenir que des lettres, des chiffres ou des traits de soulignement.",
    "processVariableManager.reservedIdentifier": "Identifiant invalide: SYS_ est réservé pour les variables système.",
    "processVariableManager.overwriteValue": "Écraser",
    "processVariableManager.search": "Chercher",
    "processVariableManager.select": "Sélectionner",
    "processVariableManager.title": "Gestionnaire de variables de processus",
    "processVariableManager.type": "Type",
    "processVariableManager.type.boolean": "Vrai / Faux",
    "processVariableManager.type.json": "JSON",
    "processVariableManager.type.number": "Nombre",
    "processVariableManager.type.string": "Texte",
    "processVariableManager.valueFalse": "Faux",
    "processVariableManager.valueTrue": "Vrai",
    "processVariableManager.variable": "Variable",
    "processVariableManager.variableIdentifier": "Identifiant",
    "processVariableManager.whereUsed": "Utilisé où",
    "processVariableManager.duplicateVariable": "Variable en double:",
    "processVariableManager.showSystemVariable": "Afficher les variables système",

    /**
     * PROCESS INFO
     */
    "processInfo.close": "Fermer",
    "processInfo.processInfo": "Info du processus",
    "processInfo.processName": "Nom du processus",
    "processInfo.standardTime": "Temps standard",
    "processInfo.productReference": "Référence du produit",
    "processInfo.processId": "Id Processus",
    "processInfo.example": "e.g. item1,item2,item3",
    "processInfo.bom": "BOM",
    "processInfo.bomId": "ID",
    "processInfo.bomRevision": "Révision",

    /**
     * PROCESS ACTION SELECTOR
     */
    "processActionSelector.cancel": "Annuler",
    "processActionSelector.hideInactiveActions": "Cacher les actions inactives",
    "processActionSelector.select": "Sélectionner",
    "processActionSelector.dialogTitle": "Sélectionner une action",

    /**
     * PROCESS REPOSITORY
     */
    "processRepository.archiveDialogArchiveDisabledMessage":
        "Ce processus ne peut pas être archivé, car il est relâché ou est utilisé par au moins une job ou un routage actif.",
    "processRepository.archiveDialogCancel": "Annuler",
    "processRepository.archiveDialogConfirm": "Confirmer",
    "processRepository.archiveDialogConfirmation": "Souhaitez-vous vraiment archiver cette version du processus?",
    "processRepository.unarchiveDialogConfirmation": "Souhaitez-vous vraiment activer cette version du processus?",
    "processRepository.archiveDialogTitle": "Confirmation pour archiver",
    "processRepository.folder": "Dossier",
    "processRepository.insertFolder": "Dossier",
    "processRepository.insertProcess": "Processus",
    "processRepository.insertRouting": "Routage",
    "processRepository.process": "Processus",
    "processRepository.routing": "Routage",
    "processRepository.unknown": "Inconnu",
    "processRepository.whereUsed": "Utilisé où: ",
    "processRepository.whereUsedClose": "Fermer",
    "processRepository.filter.processAndRouting": "Processus & Routage",

    /**
     * PROCESS WHERE USED
     */
    "processWhereUsed.approvalStatus": "Statut de l'approbation",
    "processWhereUsed.empty": "Ce processus n'est pas utilisé.",
    "processWhereUsed.emptyActive": "Ce processus est uniquement utilisé par des processus et/ou routage(s) inactif(s).",
    "processWhereUsed.jobProcessStatus": "Statut du processus",
    "processWhereUsed.jobStatus": "Statut de la job",
    "processWhereUsed.jobs": "Jobs",
    "processWhereUsed.partNumber": "Numéro de pièce",
    "processWhereUsed.processAction": "Action",
    "processWhereUsed.processLabel": "Nom",
    "processWhereUsed.processVersion": "Version",
    "processWhereUsed.releaseStatus": "Statut de relâche",
    "processWhereUsed.routings": "Routage",
    "processWhereUsed.showInactive": "Afficher les inactifs",
    "processWhereUsed.reference": "Référence",
    "processWhereUsed.refId": "Bon de commande",

    /**
     * PROCESS VARIABLE COPY DIALOG
     */
    "processVariableCopyDialog.buttonClose": "Fermer",
    "processVariableCopyDialog.duplicate": "Doublon",
    "processVariableCopyDialog.explanation":
        "Les variables suivantes n'ont pas pu être copiées car elles existaient déjà dans ce processus, mais elles ont des propriétés différentes.",
    "processVariableCopyDialog.original": "Originale",
    "processVariableCopyDialog.title": "Attention - Variables en double",
    "processVariableCopyDialog.variableIdentifier": "Identifiant",
    "processVariableCopyDialog.variableDecimals": "Décimales",
    "processVariableCopyDialog.variableDefaultValue": "Valeur par défaut",
    "processVariableCopyDialog.variableType": "Type",

    /**
     * PROCESS UPDATE
     */
    "processUpdate.approvalStatus": "Statut de l'approbation",
    "processUpdate.empty": "Cette version du processus n'est actuellement pas utilisé par aucune job.",
    "processUpdate.emptyActive": "Cette version du processus est uniquement utilisé par des job(s) inactif(s).",
    "processUpdate.jobProcessStatus": "Statut du processus",
    "processUpdate.jobStatus": "Statut de la job",
    "processUpdate.jobs": "Jobs",
    "processUpdate.partNumber": "Numéro de pièce",
    "processUpdate.processAction": "Action",
    "processUpdate.processLabel": "Nom",
    "processUpdate.processVersion": "Version",
    "processUpdate.releaseStatus": "Statut de relâche",
    "processUpdate.showInactive": "Afficher les inactifs",
    "processUpdate.reference": "Référence",
    "processUpdate.refId": "Bon de commande",
    "processUpdate.remaining": "Restant",
    "processUpdate.clearAll": "Enlever sélection",
    "processUpdate.selectAll": "Tout sélectionner",

    /**
     * PROCESS EDITOR
     */
    "processEditor.delete": "Supprimer",
    "processEditor.deleteConfirmationLabel": "Êtes-vous certain de vouloir supprimer cette action?",
    "processEditor.deleteCommuniqueConfirmationLabel": "Êtes-vous certain de vouloir supprimer ce communiqué?",
    "processEditor.deleteConfirmationCancel": "Anuller",
    "processEditor.deleteConfirmationConfirm": "Confirmer",
    "processEditor.deleteConfirmationTitle": "Confirmation de suppression",
    "processEditor.edit": "Éditer",
    "processEditor.disconnect": "Déconnecter",
    "processEditor.read": "Lire",
    "processEditor.start": "Commencer",
    "processEditor.openProcess": "Ouvrir processus",
    "processEditor.nonCopyableActions": "Les actions suivantes ne peuvent être copiées: Échec, Entrée et Sortie.",
    "processEditor.copyError": "Impossible de copier.",

    // PROCESS EDITOR SIDE MENU
    "processEditorSideMenu.info": "Info",
    "processEditorSideMenu.versioning": "Versions",
    "processEditorSideMenu.variables": "Variables",
    "processEditorSideMenu.actions": "Actions",
    "processEditorSideMenu.translations": "Traductions",
    "processEditorSideMenu.dryRun": "Essayer",
    "processEditorSideMenu.changeLogs": "Historique",
    "processEditorSideMenu.testRun": "Tester",
    "processEditorSideMenu.run": "Éxécuter",
    "processEditorSideMenu.communique": "Communiqué",
    "processEditorSideMenu.validate": "Valider",

    "repoBreadCrumbs.cancel": "Annuler",
    "repoBreadCrumbs.select": "Sélectionner",
    "repoBreadCrumbs.selectProcess": "Sélectionner un processus",
    "repoBreadCrumbs.noProcessSelect": "Aucun processus sélectionné",
    "repoBreadCrumbs.titleProcess": "Gérer les processus & routages ",
    "repoBreadCrumbs.backRouting": "Retour au routing",
    "repoBreadCrumbs.draftVersionDifferentThanLatestReleaseVersion": "Version brouillon est plus récente que la dernière version relâchée",
    "repoBreadCrumbs.draftVersionDifferentThanLatestApprovalVersion":
        "Version brouillon est plus récente que la dernière version en approbation",
    "repoBreadCrumbs.draftVersionDifferentThanLatestReleaseAndApprovalVersion":
        "Version brouillon est plus récente que les dernières versions relâchée et en approbation",
    "repoBreadCrumbs.draftVersionDifferentNoteOnIgnoredDifferences": "Position des actions ignorée",

    "rework.rework": "Billet de l'échec",
    "rework.clearFilters": "Effacer les filtres",
    "rework.reworkStatus": "Statut du billet",
    "rework.reworkItemStatus": "Statut de pièce",
    "rework.partNumber": "# de pièce",
    "rework.failureTicketNumber": "# de billet",
    "rework.trackingId": "# de suivi",
    "rework.trackingInstance": "Instance # de suivi",
    "rework.date": "Date",
    "rework.reworkDetails": "Détails du billet",
    "rework.failureDescription": "Description de l'échec",
    "rework.failureItemState": "État de l'item",
    "rework.problems": "Problèmes",
    "rework.comments": "Commentaires",
    "rework.commentsRequired": "Un commentaire est requis.",
    "rework.trackinIdRequired": "Un # de série est requis.",
    "rework.partNumberRequired": "Un # de pièce est requis.",
    "rework.process": "Processus",
    "rework.close": "Fermer",
    "rework.play": "Jouer",
    "rework.cancel": "Annuler",
    "rework.createNewJob": "Créer un nouveau travail",
    "rework.useOrginalJob": "Utiliser le travail existant",
    "rework.useExistingJob": "Utiliser le travail original existant ou créer un nouveau travail?",
    "rework.createJob": "Création du travail",
    "rework.resolution": "Détails de résolution",
    "rework.quantity": "Quantité",
    "rework.workingTime": "Temps travaillé",
    "rework.failureInfo": "Information de l'échec",
    "rework.yieldType": "Type de rendement",
    "rework.FPY": "FPY",
    "rework.FPYTooltip": "Produits non-conformes en cours de production",
    "rework.TPY": "TPY",
    "rework.TPYTooltip": "Produits non-conformes après complétion de son procédé de fabrication",
    "rework.RMA": "RMA",
    "rework.RMATooltip": "Retour client",
    "rework.ASSY": "ASSY",
    "rework.ASSYTooltip": "Assemblage",
    "rework.rootCause": "Cause racine",

    "rework.from": "De",
    "rework.to": "À",
    "rework.createdOn": "Date de Création",

    "rework.details": "Détails",
    "rework.schedule": "Céduler",
    "rework.run": "Jouer",

    "rework.status.NEW": "Nouveau",
    "rework.status.SCHE": "Céduler",
    "rework.status.INPRG": "En cours",
    "rework.status.HOLD": "En attente",
    "rework.status.DONE": "Complété",
    "rework.status.ARCH": "Archivé",

    "rework.optionShowArchived": "Afficher les réparations archivées",
    "rework.optionShowScheduled": "Afficher les réparations cédulées",
    "rework.delete": "Supprimer",

    "rework.logs": "Journal",
    "rework.failureLog": "Échec initial",
    "rework.symptomLog": "Mise à jour des symptômes",
    "rework.workLog": "Journal de travail",
    "rework.dateTime": "Date - Heure",
    "rework.user": "Utilisateur",
    "rework.reworkItemStatusId": "Status du billet",
    "rework.addWorkLog": "Ajouter un journal",
    "rework.updateSymptom": "Mettre à jour le symptôme",
    "rework.ProcessReworkItem": "Processus pour réparer l'item",
    "rework.addProcessFromOriginalJob": "Processus du job original",
    "rework.addFailPathProcess": "Processus du chemin d'échec du routage",
    "rework.addProcess": "Processus",
    "rework.addRouting": "Routage",
    "rework.updateReworkInformation": "Mise à jour des information de réparation",
    "rework.typeSymptom": "Type",
    "rework.symptom": "Symptômes",

    "rework.status": "Statut de pièce",
    "rework.statusRequired": "Un statut est requis.",
    "rework.jobRefId": "Job original",
    "rework.scheduledJobRefId": "Job de l'échec",
    "rework.runnedBy": "Exécuter par",
    "rework.LoadingReworks": "Chargement des billets d'échec...",
    "rework.AddNewReworkSuggestion": "Ajouter un nouveau billet d'échec",
    "rework.NoReworksFound": "Désolé, pas de billets d'échec trouvés",
    "rework.ScrollToLoadMoreItems": "Défiler pour charger plus de billets d'échec",
    "rework.NoMoreReworks": "Il n'y a plus de billets d'échec",

    "rework.willCloseTicket": "** Cette état de l'item va automatiquement ferme le billet",

    "scheduler.cancel": "Annuler",
    "scheduler.dryRun": "Essayer",
    "scheduler.insertJob": "Travail",
    "scheduler.insertProcess": "Processus",
    "scheduler.insertRouting": "Routage",
    "scheduler.select": "Sélectionner",
    "scheduler.selectProcess": "Sélectionner un processus",
    "scheduler.selectRouting": "Sélectionner un routage",
    "scheduler.preview": "Aperçu",
    "scheduler.play": "Exécuter",
    "scheduler.run": "Exécuter",
    "scheduler.tracking": "# Suivi",
    "scheduler.trackingInstances": "# Instances",
    "scheduler.trackingList": "Liste # suivi",
    "scheduler.delete": "Supprimer",
    "scheduler.jobStatusDraft": "En édition",
    "scheduler.jobStatusReady": "Prêt",
    "scheduler.jobStatusInProgress": "En cours",
    "scheduler.jobStatusOnHold": "En attente",
    "scheduler.jobStatusDone": "Complété",
    "scheduler.jobStatusArchived": "Archivé",
    "scheduler.jobStatusCancelled": "Annulé",
    "scheduler.jobStatusUnknown": "Inconnu",
    "scheduler.jobProcessRunStatusDraft": "En préparation",
    "scheduler.jobProcessRunStatusReady": "Prêt",
    "scheduler.jobProcessRunStatusInProgress": "En cours",
    "scheduler.jobProcessRunStatusPass": "Succès",
    "scheduler.jobProcessRunStatusFail": "Échoué",
    "scheduler.jobStatusNotReleased": "Non relâché",
    "scheduler.jobStatusRetired": "Retiré",
    "scheduler.jobReleasedOn": "Publié le",
    "scheduler.jobReleasedSince": "publié depuis le",
    "scheduler.jobRetiredOn": "retiré le",
    "scheduler.jobReleasedFrom": "publié de",
    "scheduler.jobReleasedTo": "à",
    "scheduler.jobProcessRunStatusUnknown": "Inconnu",
    "scheduler.steps": "Étape(s)",
    "scheduler.optionShowArchived": "Voir les tâches archivées",
    "scheduler.optionActiveJobProcessOnly": "Voir les tâches actives seulement",
    "scheduler.status": "Statut",
    "scheduler.quantity": "Quantité",
    "scheduler.quantityInProgress": "En cours",
    "scheduler.quantityRemainingToStart": "Manquant",
    "scheduler.quantityPass": "Réussi",
    "scheduler.quantityFail": "Échec",
    "scheduler.elapsedTime": "Temps écoulé",
    "scheduler.plannedOn": "Date planifiée",
    "scheduler.scheduledOn": "Date cédulée",
    "scheduler.jobScheduledOn": "WO date cédulée",
    "scheduler.jobProcessScheduledOn": "Processus date cédulée",
    "scheduler.requestedOn": "Date requise",
    "scheduler.group": "Groupe",
    "scheduler.customer": "Client",
    "scheduler.planner": "Planificateur",
    "scheduler.jobRefId": "Bon de commande",
    "scheduler.referenceId": "Référence",
    "scheduler.partNumber": "Numéro de pièce",
    "scheduler.workZone": "Zone de travail",
    "scheduler.completion": "Progrès",
    "scheduler.from": "De",
    "scheduler.to": "À",
    "scheduler.clearFilters": "Effacer les filtres",
    "scheduler.progress": "Progrès",
    "scheduler.currentProcess": "Procédure courante",
    "scheduler.unassigned": "Non assigné",
    "scheduler.jobSettings": "Paramètres du travail",
    "scheduler.reworkSettings": "Configuration des billets d'échecs",
    "scheduler.close": "Fermer",
    "scheduler.checkPrevious": "Vérifier le précédent",
    "scheduler.processNotReleased": "Le processus est non relâché ou inactif, il ne peut être joué",
    "scheduler.processRetired":
        "Le processus est retiré, il ne peut être joué. Mettre à jour la version du travail ou purger la version pour ce travail.",
    "scheduler.processNotFound": "Processus introuvable, il ne peut être joué",
    "scheduler.processNotValid": "Processus non valide, il ne peut être joué",
    "scheduler.LoadingJobs": "Chargement des bons de commande...",
    "scheduler.AddNewJobSuggestion": "Ajouter un nouveau bon de commande",
    "scheduler.NoJobFound": "Désolé, pas de bons de commande trouvés",
    "scheduler.ScrollToLoadMoreItems": "Défiler pour charger plus de bons de commande",
    "scheduler.NoMoreJobs": "Il n'y a plus de bons de commande",
    "scheduler.NoJobSelected": "Sélectionner un bon de commande pour plus de détails",
    "scheduler.AddNewProcessOrRouting": "Ajouter un routage / processus",
    "scheduler.NoPlanner": "Aucun planificateur",
    "scheduler.archiveAllCompletedJobs": "Archiver les tâches complétées",
    "scheduler.getJobsFromErp": "Récupérer les tâches du ERP",

    /**
     * STORAGE MANAGER
     */
    "storagemanager.folder": "Dossier",
    "storagemanager.insertFolder": "Dossier",
    "storagemanager.insertFile": "Fichier",
    "storage.whereUsedDialog.processName": "Nom Processus",
    "storage.whereUsedDialog.processVersion": "Version du processus",
    "storage.whereUsedDialog.actionName": "Nom de l'action",
    "storage.whereUsedDialog.actionType": "Type d'action",
    "storage.whereUsedDialog.processPath": "Chemin du processus",
    "storage.whereUsedDialog.processActionType.core-work-instructions": "Instruction de travail",
    "storage.whereUsedDialog.close": "Fermer",
    "storage.success": "Fichier téléversé avec succès",
    "storage.error.upload": "Impossible de téléverser le fichier: ",
    "storage.error.invalid.file": "Le fichier est invalide",
    "storage.error.max.size": "Le fichier dépasse la taille limite",
    "storage.error.upload.server": "Impossible de rejoindre le serveur de fichier",
    "storage.error.compression": "Erreur de compression de l'image",

    "treeview.dropFileToUpload": "Déposer le(s) fichier(s) pour télécharger",
    "treeview.edit": "Éditer",
    "treeview.or": "ou",
    "treeview.rename": "Renommer",
    "treeview.selectFile": "Sélectionner un fichier",
    "treeview.emptyFolder": "Le dossier est vide",
    "treeview.optionShowArchived": "Montrer les archivés",
    "treeview.archive": "Archiver",
    "treeview.active": "Activé",
    "treeview.cascadeArchiveDialogHeader": "Archive",
    "treeview.cascadeArchiveDialogMessage":
        "Cette action archivera (ou enlèvera l'archive) tous les fichiers enfants. Souhaitez-vous procéder?",
    "treeview.cascadeArchiveDialogProceed": "Procéder",
    "treeview.cascadeArchiveDialogCancel": "Annuler",
    "treeview.menu.assignBom": "Assigner info BOM",
    "treeview.menu.assignTag": "Assigner Étiquette ",
    "treeview.menu.whereUsed": "Utiliser où",
    "treeview.menu.failureType": "Type d'échec",
    "treeview.menu.editTooltip": "Modifier l'info-bulle",
    "treeview.menu.editTooltip.customTooltip": "Info-bulle personalisée",
    "treeview.menu.editTooltip.originalTooltip": "Info-bulle originale",
    "treeview.menu.editTooltip.title": "Modifier l'info-bulle",
    "treeview.menu.editTooltip.tooltip": "Info-bulle",
    "treeview.menu.reworkItemStatus": "Configuration des billets d'échecs",
    "treeview.menu.rootCause": "Cause racine",
    "treeview.menu.bomFollowUpSymptoms": "Symptômes de traçabilité de BOM",
    "treeview.tagSelectorHeader": "Selection Étiquette",
    "treeview.bomAssignmentHeader": "Assignation du BOM",
    "treeview.optionShowFilter": "Montrer les",

    /**
     * PROCESS
     */

    // PROCESS EDITOR
    "porcess.messageError.updtateAction": "Modification non enregistrée. La version du processus n'est pas un brouillon.",
    "porcess.messageError.errorSystem": "Modification non enregistrée. Erreur système, contacter support",

    // FUNCTION BLOCK
    "process.functionBlock.connector.label": "Connecteur",
    "process.functionBlock.connector.description": "Interface de communication matérielle et logicielle",

    // PRINTING
    "process.printing.approvals": "Approbations",
    "process.printing.approvalDate": "Date",
    "process.printing.approvalGroup": "Groupe",
    "process.printing.approverName": "Nom",
    "process.printing.approverSignature": "Signature",
    "process.printing.author": "Auteur",
    "process.printing.code": "Code",
    "process.printing.cancel": "Annuler",
    "process.printing.description": "Description",
    "process.printing.draft": "BROUILLON",
    "process.printing.export": "Exporter",
    "process.printing.preview": "Aperçu de l'impression PDF du processus",
    "process.printing.printDate": "Date d'impression",
    "process.printing.processFlow": "Flux des processus",
    "process.printing.revisionHistory": "Historique des révisions",
    "process.printing.tableOfContent": "Table des matières",
    "process.printing.version": "Version",
    "process.printing.uncontrolledPrintedCopy": "Document imprimé non controllé",

    // VERSIONING
    "process.versioning.action": "Action",
    "process.versioning.actionReleaseDateUpdate": "Mise-à-jour date(s) de publication/retrait",
    "process.versioning.actionCancelledVersion": "Annulée",
    "process.versioning.actionVersionOnHold": "Suspendue",
    "process.versioning.archive": "Archiver",
    "process.versioning.archived": "Archivé",
    "process.versioning.archiveDialogArchiveDisabledMessage":
        "Ce processus ne peut pas être archivé, car il est utilisé par au moins une job ou processus actif.",
    "process.versioning.archiveDialogCancel": "Annuler",
    "process.versioning.archiveDialogConfirm": "Confirmer",
    "process.versioning.archiveDialogTitle": "Confirmation pour archiver",
    "process.versioning.approval": "Approbations",
    "process.versioning.approvals": "Approbations",
    "process.versioning.recover": "Récupérer",
    "process.versioning.recoverVersionDialogCancel": "Annuler",
    "process.versioning.recoverVersionDialogConfirm": "Confirmer",
    "process.versioning.recoverVersionDialogMessage":
        "Voulez-vous vraiment récupérer cette version? Tous les changements appliqués à la version brouillon seront perdus.",
    "process.versioning.versionInformation": "Information de version",
    "process.versioning.approvalInformation": "Information d'approbation",
    "process.versioning.releaseInformation": "Information de publication",
    "process.versioning.submitNewVersion": "Soumettre une nouvelle version",
    "process.versioning.approvedBy": "Approuvé par",
    "process.versioning.approvalGroup": "Groupe d'approbation",
    "process.versioning.approvalStatus": "Statut de l'approbation",
    "process.versioning.requestedBy": "Demandée par",
    "process.versioning.requestedDate": "Date de la demande",
    "process.versioning.approve": "Approuver",
    "process.versioning.approved": "Approuvée",
    "process.versioning.approvedAutomatically": "Approuvée automatiquement",
    "process.versioning.awaiting": "En attente",
    "process.versioning.changelog": "Historique",
    "process.versioning.clearFilters": "Effacer les filtres",
    "process.versioning.currentDraft": "Brouillon actuel",
    "process.versioning.cancel": "Annuler",
    "process.versioning.cancelled": "Annulée",
    "process.versioning.hold": "Suspendre",
    "process.versioning.invalidDate": "Date invalide",
    "process.versioning.invalidRouting": "Le routage ne peut être soumis. Il contient des processus non-approuvés.",
    "process.versioning.major": "Majeure",
    "process.versioning.minor": "Mineure",
    "process.versioning.onHold": "Suspendue",
    "process.versioning.pdf": "PDF",
    "process.versioning.referenceId": "ID de référence",
    "process.versioning.reject": "Rejeter",
    "process.versioning.rejectVersionDialogMessage": "Voulez-vous vraiment rejeter cette version?",
    "process.versioning.rejectVersionDialogReason": "Commentaire",
    "process.versioning.rejectVersionDialogReasonCommentRequired": "Un commentaire est requis.",
    "process.versioning.rejected": "Rejetée",
    "process.versioning.release": "Publier",
    "process.versioning.releaseScheduled": "Planifiée",
    "process.versioning.released": "Publiée",
    "process.versioning.releasedDate": "Date de publication",
    "process.versioning.releasedDateFrom": "De",
    "process.versioning.releasedDateTo": "À",
    "process.versioning.releasedDateAfterRetireDate": "La date de publication doit être antérieure à la date de retrait",
    "process.versioning.releasedDateEqualToRetireDate": "La date de publication et la date de retrait doivent être différentes",
    "process.versioning.releasedBy": "Publiée par",
    "process.versioning.updatedBy": "Mise à jour par",
    "process.versioning.updatedDate": "Date de Mise à jour",
    "process.versioning.releaseStatus": "Statut de relâche",
    "process.versioning.reviewRequest": "Demande de révision",
    "process.versioning.reviewRequestCancel": "Annuler",
    "process.versioning.reviewRequestConfirm": "Confirmer",
    "process.versioning.reviewRequestSearchUser": "Chercher un utilisateur",
    "process.versioning.retired": "Retirée",
    "process.versioning.retiredDate": "Date de retrait",
    "process.versioning.retiredDateFrom": "De",
    "process.versioning.retiredDateTo": "À",
    "process.versioning.retiredDateBeforeReleasedDate": "La date de retrait doit être postérieure à la date de publication",
    "process.versioning.showArchived": "Montrer les archivés",
    "process.versioning.statusNone": "...",
    "process.versioning.submit": "Soumettre",
    "process.versioning.submitDisabledAnotherPendingVersion": "Une autre version est déjà en attente d'approbation ou de relâche.",
    "process.versioning.submittedBy": "Soumis par",
    "process.versioning.submissionDate": "Date de soumission",
    "process.versioning.submissionDateFrom": "De",
    "process.versioning.submissionDateTo": "À",
    "process.versioning.training": "Formation",
    "process.versioning.trainingRequirementCopyIcon":
        "Le niveau de changement sélectionné lors de la soumission de cette version est Aucun.\nCependant, puisque des formations ont été copiées depuis un autre processus, le niveau de changement a aussi été copié.",
    "process.versioning.trainingRequirementCopyWarning":
        "Le niveau de changement du processus à partir duquel les formations seront copiées\ndéterminera le niveau de changement de cette version (puisque vous avez choisi Aucun).",
    "process.versioning.level": "Niveau de changement",
    "process.versioning.level.select": "Sélectionner un niveau de changement...",
    "process.versioning.level.none": "Aucun",
    "process.versioning.level.reading": "Lecture",
    "process.versioning.level.training": "Formation",
    "process.versioning.level.certification": "Certification",
    "process.versioning.processTrainingCopy": "Copier les formations d'un autre processus",
    "process.versioning.update": "Mettre à jour",
    "process.versioning.updateVersion": "Mettre à jour les ordres de fabrication",
    "process.versioning.version": "Version",
    "process.versioning.processVersioning": "Versionnement de processus",
    "process.versioning.showHistory": "Historique des versions",
    "process.versioning.signedBy": "Signé par",
    "process.versioning.comments": "Commentaires",
    "process.versioning.reviewDone": "Revue complétée",
    "process.versioning.whereUsed": "Utilisé où",
    "process.versioning.whereUsedClose": "Fermer",
    "process.versioning.whereUsedTitle": "Utilisé où: ",
    "process.versioning.minimumApprovers": "Approbations minimales requises",
    "process.versioning.selectPeopleToNotify": "Sélectionnez les personnes à notifier",
    "process.versioning.refusedBy": "Refusé par",
    "process.versioning.selectAll": "Sélectionner tous",
    "process.versioning.emptyDate": "aaaa-mm-jj",

    "process.versioning.areYouSureDialog.title": "Soumission d'une nouvelle version de processus",
    "process.versioning.areYouSureDialog.approvalGroupsNotAllSelected": "Les groupes d'approbation n'ont pas tous été sélectionnés.",
    "process.versioning.areYouSure.question": "Êtes-vous certain de vouloir procéder?",
    "process.versioning.areYouSureDialog.missingTrainingCommunique": "Aucun communiqué de formation n'a été rédigé.",

    /* FAILURE TYPE DIALOG */
    "failureTypeDialog.close": "Fermer",
    "failureTypeDialog.add": "Ajout",
    "failureTypeDialog.addSpecial": "Ajout Spécial",
    "failureTypeDialog.failureTypesFor": "Type d'échec pour",
    "failureTypeDialog.failureTypes": "Types d'échecs",
    "failureTypeDialog.failureSymptoms": "Types de symptômes",
    "failureTypeDialog.addInstructions":
        "Ajout en format CVS pour insertion multiple, Ajout Spécial pour ajout selon le format Format TYPE - SYMPTÔMES, csv supporté",
    "failureTypeDialog.optionShowArchived": "Montrer les archivés",
    "failureTypeDialog.toInsert": "  à insérer",
    "reworkItemStatusDialog.itemsToInsert": "Items à insérer",

    /* REWORK ITEM STATUS DIALOG */
    "reworkItemStatusDialog.add": "Ajouter",
    "reworkItemStatusDialog.addSpecial": "Ajout Spécial",
    "reworkItemStatusDialog.reworkItemStatusFor": "Configuration des billets d'échecs pour",
    "reworkItemStatusDialog.reworkItemStatus": "Configuration des billets d'échecs",
    "reworkItemStatusDialog.failureTicketsExplanation":
        "Selectionner Compléter et/ou Archiver pour automatiquement fermer ou archiver un billet lors de la création",
    "reworkItemStatusDialog.addInstructions":
        "Ajout en format CVS pour insertion multiple, Ajout Spécial pour ajout selon le format Format CORRECTIONS, csv supporté",
    "reworkItemStatusDialog.optionShowArchived": "Montrer les archivés",
    "reworkItemStatusDialog.automaticClose": "Compléter",
    "reworkItemStatusDialog.automaticArchive": "Archiver",
    "reworkItemStatusDialog.itemState": "État de l'item",
    "reworkItemStatusDialog.continue": "Continuer",
    "reworkItemStatusDialog.continueTooltip":
        "Lorsque cochée, l'exécution du processus ne sera pas arrêtée.\nCe statut ne sera pas disponible si le processus n'échoue pas manuellement.",

    /* ROOT CAUSE DIALOG */
    "rootCauseDialog.rootCauseConfiguration": "Configuration des causes première pour",
    "rootCauseDialog.rootCause": "Cause racine",
    "rootCauseDialog.description": "Description",

    /* BOM FOLLOW-UP SYMPTOM DIALOG */
    "bomFollowUpSymptomDialog.bomFollowUpSymptomConfiguration": "Configuration des symptômes pour",
    "bomFollowUpSymptomDialog.bomFollowUpSymptom": "Symptôme",
    "bomFollowUpSymptomDialog.description": "Description",
    "bomFollowUpSymptomDialog.addSymptom": "Ajouter un symptôme",

    /**
     * DATA STORE ACTION
     */
    "action.datastore.basicValueSettings": "Réglages des valeurs simples",
    "action.datastore.tableSettings": "Réglages des tables",
    "action.datastore.cancel": "Annuler",
    "action.datastore.save": "Sauvegarder",
    "action.datastore.column": "Colonne",
    "action.datastore.MaxColumnReached": "Nombre de colonnes maximum atteint",
    "action.datastore.table": "Table",
    "action.datastore.valueTableId": "Table",
    "action.datastore.groupId": "Groupe",
    "action.datastore.subGroupId": "Sous-groupe",
    "action.datastore.results": "Résultats des processus",
    "action.datastore.key": "Clé",
    "action.datastore.value": "Valeur",
    "action.datastore.delete": "Supprimer",
    "action.datastore.actionType": "Type d'action",
    "action.datastore.actionType.write": "Écriture",
    "action.datastore.actionType.read": "Lecture",
    "action.datastore.storageType": "Type de stockage",
    "action.datastore.storageType.basicValue": "Valeur simple",
    "action.datastore.storageType.table": "Table valeur",
    "action.datastore.storageType.localFile": "Fichier local",
    "action.datastore.storageType.cloudFile": "Fichier cloud",
    "action.datastore.storageType.results": "Résultats des processus",
    "action.datastore.storageType.status": "Statut des processus",
    "action.datastore.group.name": "Nom",
    "action.datastore.group.type": "Type",
    "action.datastore.group.unit": "Unité",
    "action.datastore.group.metadata": "Métadonnées",
    "action.datastore.group.value": "Valeur",
    "action.datastore.group.child": "Lien enfant",
    "action.datastore.group.parent": "Lien parent",
    "action.datastore.table.name.error": "Format invalide",
    "action.datastore.table.columnsCount.error": "Le nombre de colonnes a dépassé la limite permise",
    "action.datastore.dupicated.error": "La nom doit être unique",
    "action.datastore.reqColumn": "Colonne demandée",
    "action.datastore.readItem.table": "Table",
    "action.datastore.readItem.valueTableId": "Table",
    "action.datastore.readItem.tag": "Clé",
    "action.datastore.readItem.value": "Valeur",
    "action.datastore.readItem.metadata": "Métadonnées",
    "action.datastore.readItem.trackingId": "Numéro de suivi",
    "action.datastore.readItem.jobRefId": "Bon de commande",
    "action.datastore.readItem.refId": "Bon de commande",
    "action.datastore.readItem.groupId": "Groupe",
    "action.datastore.readItem.subGroupId": "Sous-groupe",
    "action.datastore.readItem.allColumn": "Toutes les colonnes",
    "action.datastore.readItem.userName": "Nom d'usager",
    "action.datastore.readItem.label": "Nom du processus",
    "action.datastore.readItem.version": "Version du processus",
    "action.datastore.readItem.pass": "Succès",
    "action.datastore.readItem.fail": "Échec",
    "action.datastore.readItem.zoneIdsJSON": "Station de travail",
    "action.datastore.readItem.qtyPlayed": "Quantité exécutée",
    "action.datastore.storeTo": "Stocker dans",
    "action.datastore.source": "Source",
    "action.datastore.noAction": "Aucune action disponible",
    "action.datastore.tabGroups": "Groupe",
    "action.datastore.metadata": "Metadonnées",
    "action.datastore.tabMetadata": "Métadonnées",
    "action.datastore.property": "Propriété",
    "action.datastore.propertyType.object": "Objet",
    "action.datastore.propertyType.value": "Valeur",
    "action.datastore.selectMetadata": "Sélectionnez une métadonnée",
    "action.datastore.noMetadata": "Aucune",
    "action.datastore.defaultMetadataLabel": "metadonnees", // Pas d'accents, c'est utilisé comme propriété d'un JSON
    "action.datastore.defaultMetadataPropertyLabel": "cle", // Pas d'accents, c'est utilisé comme propriété d'un JSON
    "action.datastore.metadata.property": "Propriété",
    "action.datastore.metadata.type": "Type",
    "action.datastore.metadata.metadata": "Métadonnées",

    /**
     * ACTION STOP PROCESS
     */
    "action.stop.process.trigger.rework": "Générer procédure de correction",
    "action.stop.process.yes": "Oui",
    "action.stop.process.no": "Non",

    /**
     * ACTION DEFINITIONS
     */
    "action.workInstructions.label": "Instruction de travail",
    "action.workInstructions.description": "Interface pour bâtir des intructions de travail",
    "action.robotControl.label": "Contrôle de robot",
    "action.robotControl.description": "Contrôle haut niveau de la famille de robots Kinova",
    "action.condition.label": "Condition",
    "action.condition.description": "Condition permettant de bifurquer l'exécution d'un processus",
    "action.condition.failOnFalse": "Chemin erreur sur un faux",
    "action.loop.label": "Boucle",
    "action.loop.maxLoopCount": "Nombre maximal d'itérations",
    "action.loop.description": "Boucle permettant de bifurquer l'exécution d'un processus en attendant une condition",
    "action.math.label": "Math",
    "action.math.description": "Exécute des opérations mathématiques",
    "action.failureTicketCreate.label": "Branche Billet Échec / ",
    "action.failureTicketCreate.description": "Embranchement billet d'échec et bon de travail",
    "action.message.label": "Message",
    "action.message.description": "Send a user message",
    "action.time.label": "Temps",
    "action.time.description": "Gestion du temps, délai et minuterie",
    "action.parser.label": "Analyseur",
    "action.set.description": "Créer une variable or stocker une valeur dans une variable",
    "action.set.label": "Set",
    "action.parser.description": "Traitement de texte à l'aide d'expressions régulières",
    "action.stop.label": "Arrêt",
    "action.stop.description": "Arrête l'exécution d'un processus",
    "action.fail.label": "Échec",
    "action.fail.description": "Reprise suite à l'échec de l'exécution d'un processus",
    "action.email.label": "Notifications",
    "action.email.description": "Envoie des notifications par email ou par SMS",
    "action.remoteTask.label": "Processus externe",
    "action.remoteTask.description": "Exécute des processus distants",
    "action.connector.label": "Connecteur",
    "action.connector.description": "Interface de communication matérielle et logicielle",
    "action.routingProcess.label": "Processus de flux",
    "action.routingProcess.description": "Processus à ajouter dans un routage",
    "action.routingFail.label": "Échec de routing",
    "action.routingFail.description": "Cheming échec/ménage dans un routing",
    "action.input.label": "Entrée",
    "action.input.description": "",
    "action.input.batchNew": "Nouveaux items produits",
    "action.output.label": "Sortie",
    "action.output.description": "",
    "action.dataStore.label": "Magasin données",
    "action.dataStore.description": "Sauvegarde ou lit des informations dans la base de données",
    "action.undefinedAction.description": "Action non définie",
    "action.undefinedAction.label": "Non définie",

    "action.failureTicketCreate.workOrder": "Bon de Travail",

    /**
     * TRAINING
     */
    "training.userTraining": "Formation de l'utilisateur",
    "training.processTraining": "Formation aux processus",
    "training.trainingCertificate": "Attestation de formation",
    "training.traningName": "Nom de la formation",
    "training.trainingVersion": "Révision",
    "training.latestApprovedVersion": "Dernière révision approuvée",
    "training.lastVersion": "Dernière révision",
    "training.history": "Historique",
    "training.process": "Processus",
    "training.userName": "Nom",
    "training.trained": "Formé",
    "training.certified": "Certifié",
    "training.processSelectedWithoutRequirement": "Le processus sélectionné n'a aucune exigence de formation. Sélectionnez-en un autre.",
    "training.selectProcess": "Sélectionner le processus",
    "training.addProcessTraining": "Ajouter un processus de formation",
    "training.searchProcessTraining": "Rechercher un processus de formation",
    "training.trainingHistory": "Historique de formation",
    "training.currentlyReleasedProcessVersion": "Révision",
    "training.previousProcessVerisonWithTrainingRequirement": "Version précédente nécessitant une formation :",
    "training.previousProcessVerisonWithTrainingRequirement.version": "Révision :",
    "training.levelOfChange": "Niveau de changement",
    "training.levelOfChange.none": "Aucun",
    "training.levelOfChange.reading": "Lecture",
    "training.levelOfChange.training": "Formation",
    "training.levelOfChange.certification": "Certification",
    "training.archiveDate": "Date d'archivage de la formation",
    "training.trainingDate": "Date de formation",
    "training.originalVersion": "Révision originale",
    "training.moreOptions.history": "Historique",
    "training.moreOptions.maintain": "Maintenir",
    "training.moreOptions.noLongerMaintain": "Ne plus maintenir",
    "training.moreOptions.archive": "Archiver",
    "training.tooltipTrainingStatusDeprecated": "Cette formation ne sera pas maintenue pour la prochaine version.",
    "training.processName": "Nom du processus",
    "training.readingCertificateSignatureContext": "J'atteste avoir lu les processus listés.",
    "training.trainingCertificateNoData":
        "Aucune donnée n'a été chargée. Sélectionnez un processus, sélectionnez les utilisateurs et cliquez sur Confirmer.",
    "training.trainingCertificateFormDialogTitle": "Formulaire d'attestation de formation",
    "training.addTrainingCertificate": "Créer l'attestation de formation",
    "training.trainingCertificateTrainer": "Formateur",
    "training.trainingCertificateTrainers": "Formateurs",
    "training.trainingCertificateMainTrainer": "Formateur principal",
    "training.trainingCertificateTrainee": "Utilisateurs formés",
    "training.trainingProcess": "Processus de la formation",
    "training.trainingCertificateReference": "Référence",
    "training.trainingDuration": "Durée de formation",
    "training.trainingCertificateTraineeSignatureContext": "J'atteste avoir participé à la présente formation.",
    "training.trainingCertificateTrainerSignatureContext": "J'atteste avoir donné la présente formation.",
    "training.trainingCertificateTrainerNotQualified":
        "(formateur principal) n'est pas qualifié pour créer un certificat de formation pour le processus suivant:",
    "training.trainingCertificationCertifiedUserSignatureContext": "J'atteste avoir participé à la certification de formation.",
    "training.detail": "Détails",
    "training.processVersion": "Version du processus",
    "training.numberOfReadingsToDo": "Nombre de lectures à faire",
    "training.lateSince": "En retard depuis",
    "training.showArchivedTrainings": "Afficher les formations archivées",
    "training.getUsersWithTraining": "Obtenir tous les utilisateurs qui ont une formation sur les processus sélectionnés.",
    "training.addUserToLatestVersion": "Ajouter un utilisateur à la dernière révision approuvée",
    "training.addUserToHistoryVersion": "Ajouter un utilisateur à la révision sélectionnée dans l'historique",

    // CERTIFICATION
    "training.trainingCertification": "Certification de formation",
    "training.readingCertificate": "Attestation de lecture",
    "training.addTrainingCertification": "Créer une certification de formation",
    "training.trainingCertificationFormDialogTitle": "Formulaire de certification de formation",
    "training.trainingCertificationCertifiedUser": "Utilisateur certifié",
    "training.processesTrainingCertification": "Processus",
    "training.trainingCertificationReference": "Référence",
    "training.trainingCertificationCriteriaCheckbox": "Les critères de certification ont été remplis",
    "training.trainingCertificationComment": "Commentaire",
    "training.trainingCertificationAuditor": "Auditeur",
    "training.trainingCertificationCriteria":
        "Porte les équipements de protection individuelle.\nDispose adéquatement les produits et résidus de produit dangereux.\nAgit sécuritairement envers lui et les autres.\nUtilise et renseigne adéquatement sa documentation de travail.\nRetrouve l’information requise dans les procédures manufacturières appropriées.\nVérifie l’état de la calibration de ses équipements de travail et la date de péremption des produits.\nS’assure de gérer adéquatement les ESD\nDécrit les étapes clés de son travail. Peut expliquer les raisons et l’impact de ses opérations. Connait les principaux problèmes pouvant survenir.\nEffectue la tâche selon les spécifications et utilise les outils, équipements de manière appropriée.\nAuto-vérifie son travail en accordant une attention particulière aux points critiques d’inspection.",
    "training.trainingCertificationAuditorNotQualified":
        "Vous n'êtes pas qualifié(e) pour créer une certification pour le processus suivant:",
    "training.trainingCertificationAuditorSignatureContext": "J'atteste avoir accompagné et évalué que l'assembleur est certifié.",
    "training.warningMessagePending": "Cet utilisateur a déjà des formations en attente pour les processus suivants :",
    "training.warningMessageTrained": "Cet utilisateur est déjà formé sur les processus suivants :",

    "training.tooltipButtonHistory":
        "Le bouton permet à l'utilisateur sélectionné de consulter son historique de formation pour toutes les révisions du processus.",
    "training.tooltipHeaderVersionTraining": "Cette révision correspond à la révision du processus pour lequel la formation a été donnée.",
    "training.tooltipHeaderCurrentVersion":
        "La révision en vigueur correspond a la dernière révision relachée et non retirée. Cela indique qu'il s'agit d'une version en cours d'utilisation.",
    "training.tooltipHeaderLastVersion":
        "La dernière révision correspond à la dernière révision approuvée, mais non encore publiée ou retirée, d'un processus nécessitant une formation. Cela indique qu'il s'agit d'une version en cours de déploiement. Si aucune révision n'est indiqué, c'est que la révision est identique à la révision en vigueur.",
    "training.tooltipAddProcessTraining": "Ajouter des formations de processus.",
    "training.tooltipLateTraining": "En retard",
    "training.tooltipUpToDateTraining": "À jour",
    "training.tooltipUpcomingTraining": "Formation à venir. Une nouvelle version du processus est en cours de déploiement.",
    "training.tooltipUpcomingTrainingReady": "Formé pour la prochaine version du processus",
    "training.tooltipNotMaintainedTraining": "Non maintenue",
    "training.tooltipArchivedTraining": "Archivée",
    "training.tooltipOriginalVersion": "Révision à laquelle cette formation a été créée.",
    "training.tooltipReadingCertificate": "Une attestation de formation est requise pour cette version.",
    "training.archiveConfirmationDialog.archiveTraining": "Archiver une formation",
    "training.archiveConfirmationDialog.areYouSure": "Voulez-vous vraiment archiver cette formation?",

    /**
     * TEAM SETTINGS
     */
    // GLOBAL SETTINGS
    "settings.globalSettings.approval.allowSelfProcessApproval": "Autoriser l'auto-approbation de processus",
    "settings.globalSettings.approval.allowSelfRoutingApproval": "Autoriser l'auto-approbation de routage",
    "settings.globalSettings.approval.automatic": "Automatique",
    "settings.globalSettings.approval.contextText": "Texte contextuel",
    "settings.globalSettings.approval.manual": "Manuel",
    "settings.globalSettings.approval.electronicSignature": "Signature électronique",
    "settings.globalSettings.approval.lockPendingProcess": "Verrouiller l'édition d'un processus",
    "settings.globalSettings.approval.lockPendingProcessNo": "Non",
    "settings.globalSettings.approval.lockPendingProcessYes": "Oui",
    "settings.globalSettings.approval.lockPendingRouting": "Verrouiller l'édition d'un routage",
    "settings.globalSettings.approval.process": "Processus",
    "settings.globalSettings.approval.routing": "Routage",
    "settings.globalSettings.release.process": "Processus",
    "settings.globalSettings.release.contextText": "Texte contextuel",
    "settings.globalSettings.release.routing": "Routage",
    "settings.globalSettings.approval.title": "Approbation de version",
    "settings.globalSettings.release.title": "Approbation de relâche",
    "settings.globalSettings.billing": "Facturation",
    "settings.globalSettings.billing.billingContact": "Contact de facturation",
    "settings.globalSettings.billing.billingHistory": "Historique de facturation",
    "settings.globalSettings.billing.currentMonth": "Mois en cours",
    "settings.globalSettings.billing.disableAccount": "DÉSACTIVER LE COMPTE",
    "settings.globalSettings.billing.nextBillingDate": "Prochaine date de facturation",
    "settings.globalSettings.billing.status": "Statut",
    "settings.globalSettings.generalSettings": "Réglages généraux",
    "settings.globalSettings.generalSettings.allowLoginByUserCode": "Autoriser la connexion par code d'utilisateur",
    "settings.globalSettings.generalSettings.organizationName": "Nom de l'organisation",
    "settings.globalSettings.generalSettings.itEmail": "Courriel TI",
    "settings.globalSettings.localization": "Localisation",
    "settings.globalSettings.localization.addLanguage": "Ajouter une langue",
    "settings.globalSettings.localization.mainLanguage": "Langue principale",
    "settings.globalSettings.localization.secondaryLanguages": "Langues secondaires",
    "settings.globalSettings.mainContact": "Contact principal",
    "settings.globalSettings.mainContact.mainContact": "Contact principal",
    "settings.globalSettings.patch.apply": "Appliquer",
    "settings.globalSettings.patch.title": "Correction",
    "settings.globalSettings.userAndPasswordTitle": "Usager et Mot de Passe",
    "settings.globalSettings.userAndPassword.passwordSettings": "Configuration Mot de Passe",
    "settings.globalSettings.userAndPassword.passwordRetriesLimit": "Nombre d'essais maximal",
    "settings.globalSettings.userAndPassword.passwordAccountLockTime": "Durée de verrouillage du compte",
    "settings.globalSettings.userAndPassword.passwordExpiresTimeSec": "Délai d'expiration",
    "settings.globalSettings.userAndPassword.passwordChangeOnFirstLogin": "Forcer le changement à la première connexion",
    "settings.globalSettings.trainingTitle": "Formation",
    "settings.globalSettings.enabled": "Activé",
    "settings.globalSettings.trainingInactivityTimeout": "Délai d'inactivité",
    "settings.globalSettings.trainingInactivityValidationExcludedGroups": "Groupes exclus de la validation d'inactivité",
    "settings.globalSettings.trainingInactivityValidationFrequency": "Fréquence de validation d'inactivité",
    "settings.globalSettings.trainingInactivityValidationDate": "Date de validation d'inactivité",
    "settings.tag.column": "Colonne",
    "settings.tag.value": "Valeur",

    "settings.roles.name": "Nom Rôle",
    "settings.roles.system": "Système",
    "settings.roles.userEditor": "Éditeur d'Usagé",
    "settings.roles.groupEditor": "Éditeur de Groupe",
    "settings.roles.roleEditor": "Éditeur de Rôle",
    "settings.roles.reporting": "Rapports",
    "settings.roles.storageManager": "Dépot de Fichier",
    "settings.roles.processRepository": "Dépot de Processus",
    "settings.roles.processEditor": "Éditeur Processus / Routage",
    "settings.roles.processPlayer": "Éxécuteur de Processus",
    "settings.roles.processApproval": "Révision et Approbation de Processus",
    "settings.roles.processRelease": "Relâche de Processus",
    "settings.roles.scheduler": "Planification",
    "settings.roles.workSchedule": "Liste Travail",
    "settings.roles.kanban": "Kanban",
    "settings.roles.reworks": "Billets d'échecs",
    "settings.roles.details": "Details du Rôle",
    "settings.roles.permissions": "Permission du Rôle",
    "settings.roles.assignTo": "Assigné à",
    "settings.roles.approval.reviewOnly": "Révision Seulement",
    "settings.roles.approval.reviewAndApprove": "Révision et Approbation",
    "settings.roles.training": "Formation",
    "settings.roles.bom": "BOM",

    // USER SETTINGS
    "settings.userSettings.cell": "Cellulaire",
    "settings.userSettings.contactInformation": "Contact",
    "settings.userSettings.code": "Code",
    "settings.userSettings.details": "Détails",
    "settings.userSettings.done": "Complété",
    "settings.userSettings.email": "Adresse e-mail",
    "settings.userSettings.firstName": "Prénom",
    "settings.userSettings.forceUserToChangePasswordLabel": "Forcer l'usager à modifier son mot de passe",
    "settings.userSettings.groups": "Groupes",
    "settings.userSettings.groupsRolesPermissions": "Groupes, Rôles et Permissions",
    "settings.userSettings.invalid": "Invalide",
    "settings.userSettings.invalidCell": "Doit respecter la formule XXX-XXX-XXXX(XX)",
    "settings.userSettings.invalidEmail": "Doit respecter la formule x@y.com",
    "settings.userSettings.invalidLanguage": "SVP choisir une langue",
    "settings.userSettings.invalidLength_0_50": "Doit contenir entre 0 et 50 caractères",
    "settings.userSettings.invalidLength_2_50": "Doit contenir entre 2 et 50 caractères",
    "settings.userSettings.invalidPasswordError": "Le format du mot de passe est invalide",
    "settings.userSettings.invalidRoles": "Doit avoir au moins un rôle",
    "settings.userSettings.language": "Langue",
    "settings.userSettings.lastName": "Nom",
    "settings.userSettings.name": "Nom",
    "settings.userSettings.optionShowArchived": "Afficher utilisateurs archivés",
    "settings.userSettings.password": "Mot de passe temporaire",
    "settings.userSettings.passwordExpired": "Le mot de passe de cet utilisateur est expiré",
    "settings.userSettings.passwordHint":
        "Le mot de passe doit contenir au moins 1 majuscule, 1 minuscule et 1 chiffre et doit avoir une longueur d'au moins 8 caractères",
    "settings.userSettings.passwordsMismatchError": "Le mot de passe et sa confirmation ne sont pas identiques",
    "settings.userSettings.passwordUpdateCompleted": "Mise à jour du mot de passe complétée",
    "settings.userSettings.resetPassword": "Réinitialiser le mot de passe",
    "settings.userSettings.retypedPassword": "Confirmez le mot de passe temporaire",
    "settings.userSettings.role": "Rôle",
    "settings.userSettings.roles": "Rôles",
    "settings.userSettings.status": "Statut",
    "settings.userSettings.status.active": "Actif",
    "settings.userSettings.status.disabled": "Désactivé",
    "settings.userSettings.status.archived": "Archivé",
    "settings.userSettings.userDetails": "Détails utilisateur",
    "settings.userSettings.username": "Nom d'utilisateur",
    "settings.userSettings.userStatus": "Statut d'utilisateur",
    "settings.userSettings.usernameUnavailableError":
        "Ce nom d'utilisateur n'est pas disponible. SVP en choisir un autre avant de continuer.",
    "settings.userSettings.usercodeUnavailableError":
        "Ce code d'utilisateur n'est pas disponible. SVP en choisir un autre avant de continuer.",
    "settings.userSettings.exportEmail": "Exporter",

    // GROUP SETTINGS
    "settings.groups.attachedTo": "Attaché à",
    "settings.groups.assignedTo": "Assigné à",
    "settings.groups.groupDetails": "Détails",
    "settings.groups.name": "Nom",
    "settings.groups.isApprover": "Approbateur",
    "settings.groups.isTrainer": "Formateur",
    "settings.groups.trainedAfterReading": "Formé après lecture",
    "settings.groups.approvalMandatory": "Approbation obligatoire",
    "settings.groups.approverQty": "Qté Approbation",
    "settings.groups.status": "Statut",
    "settings.groups.yes": "Oui",
    "settings.groups.no": "Non",
    "settings.groups.users": "Utilisateurs",
    "settings.groups.processes": "Processus",
    "settings.groups.active": "Actif",
    "settings.groups.archived": "Archivé",
    "settings.groups.cannotArchive": "Puisque des processus en attente d'approbation y sont rattachés, vous ne pouvez archiver ce groupe.",
    "settings.groups.showArchived": "Montrer les archivés",

    /**
     * PROCESS VERSION PICKER
     */
    "processVersionPicker.draft": "Brouillon",
    "processVersionPicker.latest": "Dernière",
    "processVersionPicker.noVersionAvailable": "Aucune version disponible",
    "processVersionPicker.noApprovedVersionAvailable": "Aucune version approuvée disponible",
    "processVersionPicker.noReleasedVersionAvailable": "Aucune version publiée disponible",

    /**
     * APPLICATION HEADER
     */
    // CONNECTION STATUS
    "connectionStatus.account": "Compte",
    "connectionStatus.language": "Langage",
    "connectionStatus.logout": "Déconnexion",

    /**
     * REPORTING PAGE
     */
    "reporting.type.summary": "Sommaire",
    "reporting.type.process": "Processus",
    "reporting.type.process.step": "Actions de processus",
    "reporting.type.rework": "Billets d'échecs",
    "reporting.type.data.store.value": "Données Valeur",
    "reporting.type.data.store.table": "Données Table",
    "reporting.type.audit.trail": "Suivi d'audit",
    "reporting.type.electronic.signature": "Signatures électroniques",
    "reporting.type.entity": "Entité",
    "reporting.type.training": "Formation",
    "reporting.type.trainingReadingCertificate": "Attestation de lecture",
    "reporting.type.trainingTrainingCertificate": "Attestation de formation",
    "reporting.type.trainingCertification": "Certification",
    "reporting.from": "De",
    "reporting.to": "À",
    "reporting.tracking.id": "Numéro suivi",
    "reporting.action.generate": "Générer",
    "reporting.action.export": "Exporter",
    "reporting.rows.per.page": "Lignes par page",
    "reporting.table": "Table",
    "reporting.report.type": "Type rapport",
    "reporting.job.ref.id": "Bon de commande",
    "reporting.testRun": "Exécution Test",
    "reporting.testRunIncludes": "Inclure",
    "reporting.testRunOnly": "Seulement",
    "reporting.tooMuchRecordDialog": "Trop de données à télécharger",
    "reporting.tooMuchRecordDialog1": "Trop de données à télécharger: ",
    "reporting.tooMuchRecordDialog2": ". Seulement les premières ",
    "reporting.tooMuchRecordDialog3": " données sont disponibles. Utiliser l'exportation csv pour avoir toutes les données.",
    "reporting.groupData": "Grouper les données",
    "reporting.showFilterDate": "Montrer les dates",
    "reporting.showArchived": "Montrer les archivés",
    "reporting.certificateFilters": "Attestation de formation",
    "reporting.readingCertificateFilters": "Attestation de lecture",
    "reporting.trainer": "Formateur",
    "reporting.reference": "Référence",
    "reporting.certificationFilters": "Certification",
    "reporting.auditor": "Auditeur",
    "reporting.tag": "Étiquette",

    "reporting.tableheader.processId": "ID du processus",
    "reporting.tableheader.processName": "Processus",
    "reporting.tableheader.total": "Total",
    "reporting.tableheader.totalPass": "Total succès",
    "reporting.tableheader.totalFail": "Total échec",
    "reporting.tableheader.avgTimeDHMS": "Temps moyen",
    "reporting.tableheader.reportTagEntry": "Étiquettes",
    "reporting.tableheader.jobRefId": "Bon de commande",
    "reporting.tableheader.startedOnDateTimeUTC": "Commencé",
    "reporting.tableheader.completedOnDateTimeUTC": "Complété",
    "reporting.tableheader.trackingId": "Numéro suivi",
    "reporting.tableheader.refId": "ID de référence",
    "reporting.tableheader.userName": "Nom d'utilisateur",
    "reporting.tableheader.workStation": "Station de travail",
    "reporting.tableheader.pass": "Succès",
    "reporting.tableheader.fail": "Échec",
    "reporting.tableheader.isTestRunYNBOOL": "Test",
    "reporting.tableheader.isProcessReworkYNBOOL": "Réparation",
    "reporting.tableheader.durationDHMS": "Temps",
    "reporting.tableheader.processVersion": "Version du processus",
    "reporting.tableheader.actionType": "Type d'action",
    "reporting.tableheader.actionName": "Nom de l'action",
    "reporting.tableheader.actionStepName": "Nom de l'étape",
    "reporting.tableheader.actionStepStartedOnDateTimeUTC": "Commencé",
    "reporting.tableheader.actionStepDurationDHMS": "Temps",
    "reporting.tableheader.actionStepComment": "Commentaire",
    "reporting.tableheader.actionStepApprovedBy": "Approuvé par",
    "reporting.tableheader.actionStepApprovedOnUTC": "Approuvé",
    "reporting.tableheader.approvalDate": "Date d'approbation",
    "reporting.tableheader.approvedBy": "Approuvé par",
    "reporting.tableheader.context": "Contexte",
    "reporting.tableheader.releasedDateUTC": "Date de relâche",
    "reporting.tableheader.retiredDateUTC": "Date de retraite",
    "reporting.tableheader.electronicSignatureDateUTC": "Date de signature électronique",
    "reporting.tableheader.sysAuditTrailId": "Id",
    "reporting.tableheader.tableName": "Nom de Table",
    "reporting.tableheader.entryId": "Entrée Id",
    "reporting.tableheader.modificationType": "Type",
    "reporting.tableheader.modificationInfo": "Info",
    "reporting.tableheader.previousValue": "Valeur précédente",
    "reporting.tableheader.modifiedOnDateTimeUTC": "Modifié le",
    "reporting.tableheader.modifiedByUserName": "Modifié par",
    "reporting.tableheader.description": "Description",
    "reporting.tableheader.failedOnDateTimeUTC": "Échoué le",
    "reporting.tableheader.failureStatus": "Statut d'échec",
    "reporting.tableheader.reworkStatus": "Statut",
    "reporting.tableheader.reworkItemStatus": "Statut Item",
    "reporting.tableheader.reworkComment": "Commentaire des billets d'échecs",
    "reporting.tableheader.resolution": "Résolution",
    "reporting.tableheader.failureType": "Type d'échec",
    "reporting.tableheader.symptomType": "Symptôme",
    "reporting.tableheader.problemComment": "Problème",
    "reporting.tableheader.runBy": "Exécuté par",
    "reporting.tableheader.title": "Titre",
    "reporting.tableheader.reference": "Réference",
    "reporting.tableheader.formedUsersLength": "Nombre Usagés",
    "reporting.tableheader.requestedBy": "Demandé Pour",
    "reporting.tableheader.scheduledOnDateUTC": "Cédulé Pour",
    "reporting.tableheader.requestedCompletedByDateUTC": "Demande de Teminer Pour",
    // Entity
    "reporting.tableheader.trackingInstance": "Numéro instance BATCH",
    "reporting.tableheader.partNumber": "Numéro de pièce",
    "reporting.tableheader.status": "Statut",
    "reporting.tableheader.batchEnableTFBOOL": "BATCH activé",
    "reporting.tableheader.completed": "Complété",
    "reporting.tableheader.cycleTimeDHMS": "Temps de cycle",
    "reporting.tableheader.assemblyDurationDHMS": "Durée d'assemblage",
    "reporting.tableheader.reworkDurationDHMS": "Durée de rework",
    "reporting.tableheader.passedQuantity": "Quantité Passée",
    "reporting.tableheader.faildedQuantity": "Quantité Échouée",

    "reporting.electronicSignature.type": "Type",
    "reporting.electronicSignature.type.processApproval": "Approbation du processus",
    "reporting.electronicSignature.type.processRelease": "Approbation de relâche du processus",
    "reporting.electronicSignature.type.processRunApproval": "Approbation de l'exécution du processus",
    "reporting.electronicSignature.type.processStepApproval": "Approbation de l'étape du processus",
    "reporting.electronicSignature.type.processTrainingCoachRequired": "Accompagnateur d'exécution",
    "reporting.electronicSignature.type.processTrainingCompleted": "Attestation de lecture par exécution",
    "reporting.electronicSignature.type.readingCertificate": "Attestation de lecture manuelle",
    "reporting.electronicSignature.type.trainingCertificate": "Attestation de formation",
    "reporting.electronicSignature.type.trainingCertification": "Certification",

    "entity.status.NEW": "Nouveau",
    "entity.status.INPROG": "En cours",
    "entity.status.COMPLET": "Complété",

    // Training
    "reporting.tableheader.trainedTFBOOL": "Formé",
    "reporting.tableheader.certifiedTFBOOL": "Certifié",
    "reporting.tableheader.createdOnDateTimeUTC": "Créé le",
    "reporting.tableheader.version": "Version",
    "reporting.tableheader.versionSearch": "Version de recherche",
    "reporting.tableheader.versionCertificate": "Version du processus",
    "reporting.tableheader.dateCertificateDateTimeUTC": "Date de l'attestation",
    "reporting.tableheader.dateCertificationDateTimeUTC": "Date de certification",
    "reporting.tableheader.versionCertification": "Version du processus",
    "reporting.tableheader.dateTimeUTC": "Date",
    "reporting.tableheader.dateSignatureDateTimeUTC": "Date de signature",
    "reporting.tableheader.trainerName": "Formateur",
    "reporting.tableheader.trainerDateTimeUTCArray": "Date de signature du formateur",
    "reporting.tableheader.trainerContext": "Context du formateur",
    "reporting.tableheader.archivedOnUTC": "Date d'archivage",
    "reporting.tableheader.trainingArchiveDateUTC": "Date d'archivage de la formation",
    "reporting.tableheader.auditorName": "Auditeur",
    "reporting.tableheader.auditorDateTimeUTC": "Date de signature de l'auditeur",
    "reporting.tableheader.auditorContext": "Contexte de l'auditeur",
    "reporting.tableheader.type": "Type",
    "reporting.tableheader.processCopiedFrom": "Processus copié",
    "reporting.tableheader.processCopiedVersion": "Version du processus copié",

    /**
     * KORTEX SEARCH FIELD
     */
    "kortexSearchField.placeholder": "Rechercher",

    /**
     * Password Changed Dialog
     */
    "passwordChangedDialog.dialogTitle": "Votre mot de passe est expiré",
    "passwordChangedDialog.oldPassword": "Ancien mot de passe",
    "passwordChangedDialog.newPassword": "Nouveau mot de passe",
    "passwordChangedDialog.retypePassword": "Retapez votre mot de passe",
    "passwordChangedDialog.submit": "Soumettre",

    /**
     * selectProgressRunInfo
     */
    "selectProgressRunInfo.lastRunFailed": "Exécution échouée, souhaitez-vous reprendre l'exécution?",
    "selectProgressRunInfo.runInProgress": "Exécution arrêtée, souhaitez-vous reprendre l'exécution?",
    "selectProgressRunInfo.startTime": "Début",
    "selectProgressRunInfo.stopTime": "Fin",
    "selectProgressRunInfo.trackingInstanceList": "Liste numéros d'item",

    /*
     * Tasks
     */
    "tasks.statusOpen": "Ouvert",
    "tasks.statusClose": "Fermé",
    "tasks.statusUnknown": "Inconnu",
    "tasks.group": "Groupe",
    "tasks.reviewer": "Vérificateur",
    "tasks.from": "De",
    "tasks.to": "À",
    "tasks.optionShowArchived": "Montrer les archivés",
    "tasks.clearFilters": "Effacer les filtres",
    "tasks.status": "Statut",
    "tasks.requestedOn": "Date requise",
    "tasks.taskType.processApproval": "Approbation de processus",
    "tasks.taskType.processReview": "Revue de processus",
    "tasks.taskType.unknown": "Tâche inconnue",
    "tasks.taskStatus.open": "Ouverte",
    "tasks.taskStatus.close": "Terminée",
    "tasks.taskStatus.archive": "Archivée",
    "tasks.taskStatus.unknown": "Statut inconnu",

    "tasks.taskCard.status": "Statut",
    "tasks.taskCard.reference": "Référence",
    "tasks.taskCard.submitted": "Date soumise",
    "tasks.taskCard.requestedBy": "Requis par",
    "tasks.taskCard.processName": "Nom du processus",
    "tasks.taskCard.processId": "Id du processus",
    "tasks.taskCard.processPath": "Chemin du processus",
    "tasks.taskCard.approverGroup": "Groupe d'approbation",
    "tasks.taskCard.reviewer": "Vérificateur",
    "tasks.taskCard.comments": "Commentaires",
    "tasks.taskCard.requestedCompletedBy": "Date requise",
    "tasks.taskCard.title": "Titre",

    /**
     * VARIABLE PICKER
     */
    "variablePicker.append": "(Ajouter)",

    /**
     * SPECIFIC WIDGETS
     */

    /**
     * DATA TABLE
     */
    "datatable.textLabels.body.noMatch": "Aucune donnée. Appuyez sur Générer pour effectuer un nouveau rapport",
    "datatable.textLabels.body.loading": "Capture des résulats en cours....",
    "datatable.textLabels.body.toolTip": "Classer",

    "datatable.textLabels.pagination.next": "Page suivante",
    "datatable.textLabels.pagination.previous": "Page précédente",
    "datatable.textLabels.pagination.rowsPerPage": "Lignes par page:",
    "datatable.textLabels.pagination.displayRows": "de",

    "datatable.textLabels.toolbar.search": "Recherche",
    "datatable.textLabels.toolbar.downloadCsv": "Télécharger en format CSV",
    "datatable.textLabels.toolbar.print": "Imprimer",
    "datatable.textLabels.toolbar.viewColumns": "Voir les colonnes",
    "datatable.textLabels.toolbar.filterTable": "Filtrer la table",

    "datatable.textLabels.filter.all": "TOUT",
    "datatable.textLabels.filter.title": "FILTRES",
    "datatable.textLabels.filter.reset": "RÉINITIALISER",

    "datatable.textLabels.viewColumns.title": "Voir les colonnes",
    "datatable.textLabels.viewColumns.titleAria": "Afficher/masquer la colonne Table",

    "datatable.textLabels.selectedRows.text": "ligne(s) sélectionnée(s)",
    "datatable.textLabels.selectedRows.delete": "Supprimer",
    "datatable.textLabels.selectedRows.deleteAria": "Supprimer les lignes sélectionnées",

    /*
        NOTIFICATION CENTER
    */
    "notificationCenter.notificationCard.titleDilaog": "Centre de notifications",
    "notificationCenter.notificationCard.title": "Titre",
    "notificationCenter.notificationCard.explanation": "Explication",
    "notificationCenter.notificationCard.suggestion": "Suggestion",
    "notificationCenter.details": "Détails",
    "notificationCenter.severity": "Sévérité",
    "notificationCenter.date": "Date",

    /*
        SCANNER
    */
    "scanner.codeListPlaceholder": "Scanner un article",
    "scanner.error.invalidCode": "Code invalide: ",
    "scanner.error.invalidItem": "Article invalide: ",
    "scanner.error.invalidKanban": "Kanban invalide: ",
    "scanner.error.invalidTable": "Table invalide: ",
    "scanner.error.noItem": "Aucun article n'a été envoyé",
    "scanner.error.noKanban": "Aucun kanban n'a été envoyé",
    "scanner.error.noTable": "Aucune table n'a été envoyée",
    "scanner.error.noValidItem": "Aucun article valide",
    "scanner.error.kanbanAlreadyScanned": "Un kanban a déjà été scanné",
    "scanner.error.tableAlreadyScanned": "Une table a déjà été scannée",
    "scanner.item": "Article",
    "scanner.kanban": "Kanban",
    "scanner.lotNumber": "# lot",
    "scanner.quantity": "Qté",
    "scanner.scanItem": "Scanner un article",
    "scanner.scanKanban": "Scanner un kanban",
    "scanner.scanTable": "Scanner une table",
    "scanner.send": "ENVOYER",
    "scanner.sentSuccessfully": "Envoi réussi",
    "scanner.table": "Table",
};

import { getDateTimeStr, IGetSerializedItemsByTypeAjustmentRes, Unpack } from "@kortex/aos-common";
import { getPageUrl } from "@kortex/aos-ui/configs/menu";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    tableCell: {
        padding: "8px 4px",
    },
    tableRow: {},
    typographyLine: {
        display: "flex",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    index: number;
    serialNumberHistory: Unpack<IGetSerializedItemsByTypeAjustmentRes>;
}

const Row: FC<IOwnProps> = (props) => {
    const { index, serialNumberHistory } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    return (
        <TableRow className={classes.tableRow} id="bomFollowUpPageAdjustmentTypeSerialNumberOperationTableRowId">
            <TableCell className={classes.tableCell}>
                <div className={classes.typographyLine}>
                    <Typography id={`bomFollowUpPageAdjustmentTypeSerialNumberOperationTableRowSerialNumberId-${index}`} variant="body2">
                        {serialNumberHistory.serialNumber || serialNumberHistory.previousValues.serialNumber ? (
                            <Link
                                to={`${getPageUrl(
                                    EnumPageTypes.BOM_FOLLOW_UP
                                )}/?type=secondaryTraceabilitySerialNumber&secondaryTraceabilitySerialNumber=${
                                    serialNumberHistory.serialNumber || serialNumberHistory.previousValues.serialNumber
                                }`}
                            >
                                {serialNumberHistory.serialNumber || serialNumberHistory.previousValues.serialNumber}
                            </Link>
                        ) : (
                            translate("general.na")
                        )}
                    </Typography>
                    &nbsp;
                    {!serialNumberHistory.serialNumber && serialNumberHistory.previousValues.serialNumber ? (
                        <Typography
                            color="error"
                            id={`bomFollowUpPageAdjustmentTypeSerialNumberOperationTableRowMissingPreviousTraceabilityIcon1Id-${index}`}
                            variant="body2"
                        >
                            <sup>1</sup>
                        </Typography>
                    ) : null}
                </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeSerialNumberOperationTableRowTraceabilityId-${index}`} variant="body2">
                    <Link
                        to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=traceability&traceability=${
                            serialNumberHistory.bomFollowUpSerializedItem.bomFollowUp.traceability
                        }`}
                    >
                        {serialNumberHistory.bomFollowUpSerializedItem.bomFollowUp.traceability}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeSerialNumberOperationTableRowPartNumberId-${index}`} variant="body2">
                    <Link
                        to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=partNumber&partNumber=${
                            serialNumberHistory.bomFollowUpSerializedItem.bomFollowUp.partNumber
                        }`}
                    >
                        {serialNumberHistory.bomFollowUpSerializedItem.bomFollowUp.partNumber}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell} id={`bomFollowUpPageAdjustmentTypeSerialNumberOperationTableRowTrackingIdId-${index}`}>
                <Typography variant="body2">
                    <Link
                        to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=trackingId&trackingId=${
                            serialNumberHistory.bomFollowUpSerializedItem.bomFollowUp.trackingId
                        }`}
                    >
                        {serialNumberHistory.bomFollowUpSerializedItem.bomFollowUp.trackingId}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeSerialNumberOperationTableRowJobId-${index}`} variant="body2">
                    <Link
                        to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=jobRefId&jobRefId=${
                            serialNumberHistory.bomFollowUpSerializedItem.bomFollowUp.jobRefId
                        }`}
                    >
                        {serialNumberHistory.bomFollowUpSerializedItem.bomFollowUp.jobRefId}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeSerialNumberOperationTableRowCreatedOnId-${index}`} variant="body2">
                    {getDateTimeStr(serialNumberHistory.createdOn)}
                </Typography>
            </TableCell>
            <TableCell
                className={classes.tableCell}
                id={`bomFollowUpPageAdjustmentTypeSerialNumberOperationTableRowSymptomTitleId-${index}`}
            >
                <Typography variant="body2">{serialNumberHistory.symptom?.title || translate("general.na")}</Typography>
            </TableCell>
            <TableCell
                className={classes.tableCell}
                id={`bomFollowUpPageAdjustmentTypeSerialNumberOperationTableRowJustificationId-${index}`}
            >
                <Typography variant="body2">{serialNumberHistory.justification || translate("general.na")}</Typography>
            </TableCell>
        </TableRow>
    );
};

export default Row;

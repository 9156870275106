import { useSelectorBoms } from "@kortex/aos-ui/redux/selectors";
import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { useBomContext } from "../../context";
import { NoDataFound } from "../../noDataFound";
import { WoBomTable } from "../../woBomTable";

const useStyles = makeStyles({
    loadingContainer: {
        alignItems: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "16px 0",
        width: "100%",
    },
});

const BomTrackingId: FC = () => {
    const classes = useStyles();
    const { loading } = useBomContext();
    const woBoms = useSelectorBoms();

    return (
        <div>
            {loading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            ) : woBoms.length ? (
                woBoms.map((woBom, index) => <WoBomTable woBom={woBom} key={`woBomTable${index}`} />)
            ) : (
                <NoDataFound />
            )}
        </div>
    );
};

export default BomTrackingId;

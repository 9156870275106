import {
    areSimilar,
    BomFollowUpHistoryTraceabilityType,
    BomFollowUpSerializedItemHistoryTraceabilityType,
    BomFollowUpSymptomId,
    IBomFollowUpSymptomDbModel,
    isNullOrUndefined,
    JobRefId,
    PartNumber,
    SerialNumber,
    TrackingId,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { bomClearBoms, bomGetByTrackingId } from "@kortex/aos-ui/redux/bom-manager/bom-thunks";
import { client } from "@kortex/aos-ui/utilitites/kortex-client/client";
import { useLocationQuery } from "@kortex/aos-ui/utilitites/useLocationQuery";
import React, { createContext, Dispatch, FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export type SecondaryTraceabilityType = "serialNumber";

export interface IFilters {
    adjustmentType?: BomFollowUpHistoryTraceabilityType;
    adjustmentTypeSerialNumber?: BomFollowUpSerializedItemHistoryTraceabilityType;
    bomFollowUpSymptomId?: BomFollowUpSymptomId;
    dateFrom?: number;
    dateTo?: number;
    jobRefId?: JobRefId;
    partNumber?: PartNumber;
    quantity?: number;
    secondaryTraceabilitySerialNumber?: SerialNumber;
    traceability?: string;
    trackingId?: TrackingId;
}

export interface ISearch {
    filters: IFilters;
    type: keyof IFilters;
}

function createDefaultSearch(): ISearch {
    const initialDate = new Date();

    return {
        filters: {
            dateFrom: new Date(initialDate.getFullYear(), initialDate.getMonth(), 1, 0, 0, 0, 0).getTime(),
            dateTo: new Date().setHours(23, 59, 59, 999),
            trackingId: "",
        },
        type: "trackingId",
    };
}

interface IBomContext {
    bomFollowUpSymptoms: IBomFollowUpSymptomDbModel[];
    createDefaultSearch: () => ISearch;
    generate: () => Promise<void>;
    loading: boolean;
    previousSearch: ISearch;
    queryResult: unknown;
    search: ISearch;
    setSearch: Dispatch<React.SetStateAction<ISearch>>;
    updateQuery: (search?: ISearch) => void;
}

const BomContext = createContext<IBomContext>({
    bomFollowUpSymptoms: [],
    createDefaultSearch,
    generate: async () => void 0,
    loading: false,
    previousSearch: createDefaultSearch(),
    queryResult: null,
    search: createDefaultSearch(),
    setSearch: () => void 0,
    updateQuery: () => void 0,
});

type BomProviderProps = PropsWithChildren<{}>;

export const BomProvider: FC<BomProviderProps> = (props) => {
    const { children } = props;

    const dispatch = useThunkDispatch();
    const navigate = useNavigate();
    const query = useLocationQuery();

    const [bomFollowUpSymptoms, setBomFollowUpSymptoms] = useState<IBomFollowUpSymptomDbModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [previousSearch, setPreviousSearch] = useState<ISearch>(createDefaultSearch());
    const [queryResult, setQueryResult] = useState<unknown>(null);
    const [search, setSearch] = useState<ISearch>(createDefaultSearch());

    /**
     * Wait until the page URL query is changed. Generate a follow-up report if query is valid.
     */
    useEffect(() => {
        generate();
    }, [query]);

    const createSearchUrl = (searchFilters: ISearch = search): string => {
        const { filters, type } = searchFilters;
        let urlParams = `type=${type}`;

        for (const key in filters) {
            const value = filters[key as keyof IFilters];

            if (isNullOrUndefined(value)) continue;
            if (!String(value).trim()) continue;

            urlParams += `&${key}=${value}`;
        }

        return urlParams;
    };

    const generate = async (): Promise<void> => {
        setLoading(true);
        setQueryResult(null);

        const type = query.get("type") as keyof IFilters | null;

        if (!type) {
            setSearch(createDefaultSearch());
            setLoading(false);

            return void 0;
        }

        // Empty BOMs from redux
        dispatch(bomClearBoms());

        let filters: IFilters = {};

        for (const [key, cb = (value: string): unknown => value] of [
            ["adjustmentType"],
            ["adjustmentTypeSerialNumber"],
            ["dateFrom", Number],
            ["dateTo", Number],
            ["jobRefId"],
            ["partNumber"],
            ["quantity", Number],
            ["secondaryTraceabilitySerialNumber"],
            ["traceability"],
            ["trackingId"],
        ] as [string, ((param: string) => unknown)?][]) {
            const value = query.get(key);

            if (value) {
                filters = Object.assign(filters, { [key]: cb(value) });
            }
        }

        switch (type) {
            case "trackingId": {
                if (!filters.trackingId) break;

                setPreviousSearch((prevState) => {
                    const updatedState: ISearch = {
                        ...prevState,
                        type: "trackingId",
                        filters: {
                            ...prevState.filters,
                            trackingId: filters.trackingId,
                        },
                    };

                    setSearch(updatedState);

                    return updatedState;
                });

                const result = await dispatch(bomGetByTrackingId(filters.trackingId));

                if (result?.woBoms[0]?.woBom.jobRefId) {
                    setBomFollowUpSymptoms([
                        ...(await client.services.bomFollowUpSymptom.getAll({
                            treeNodeId: undefined,
                            jobRefId: result.woBoms[0].woBom.jobRefId,
                        })()),
                    ]);
                }

                break;
            }
            case "jobRefId": {
                if (!filters.jobRefId) break;

                setPreviousSearch(() => {
                    const updatedState: ISearch = { filters, type };
                    setSearch(updatedState);
                    return updatedState;
                });

                const { resultMasters } = await client.services.erp
                    .getBomByJob({
                        dateFrom: filters.dateFrom,
                        dateTo: filters.dateTo,
                        jobRefId: filters.jobRefId,
                    })()
                    .catch(() => ({ resultMasters: [] }));

                setQueryResult(resultMasters);

                break;
            }
            case "partNumber": {
                if (!filters.partNumber) break;

                setPreviousSearch(() => {
                    const updatedState: ISearch = { filters, type };
                    setSearch(updatedState);
                    return updatedState;
                });
                setQueryResult(
                    await client.services.erp
                        .getItemsByPartNumber({
                            dateFrom: filters.dateFrom,
                            dateTo: filters.dateTo,
                            jobRefId: filters.jobRefId,
                            partNumber: filters.partNumber,
                            quantity: filters.quantity,
                            traceability: filters.traceability,
                            trackingId: filters.trackingId,
                        })()
                        .catch(() => [])
                );

                break;
            }
            case "traceability": {
                if (!filters.traceability) break;

                setPreviousSearch(() => {
                    const updatedState: ISearch = { filters, type };
                    setSearch(updatedState);
                    return updatedState;
                });
                setQueryResult(
                    await client.services.erp
                        .getItemsByTraceability({
                            dateFrom: filters.dateFrom,
                            dateTo: filters.dateTo,
                            jobRefId: filters.jobRefId,
                            partNumber: filters.partNumber,
                            quantity: filters.quantity,
                            traceability: filters.traceability,
                            trackingId: filters.trackingId,
                        })()
                        .catch(() => [])
                );

                break;
            }
            case "secondaryTraceabilitySerialNumber": {
                if (!filters.secondaryTraceabilitySerialNumber) break;

                setPreviousSearch(() => {
                    const updatedState: ISearch = { filters, type };
                    setSearch(updatedState);
                    return updatedState;
                });
                setQueryResult(
                    await client.services.erp
                        .getItemsBySecondaryTraceabilitySerialNumber({
                            dateFrom: filters.dateFrom,
                            dateTo: filters.dateTo,
                            jobRefId: filters.jobRefId,
                            partNumber: filters.partNumber,
                            serialNumber: filters.secondaryTraceabilitySerialNumber,
                            traceability: filters.traceability,
                            trackingId: filters.trackingId,
                        })()
                        .catch(() => [])
                );

                break;
            }
            case "adjustmentType": {
                if (!filters.adjustmentType) break;

                setPreviousSearch(() => {
                    const updatedState: ISearch = { filters, type };
                    setSearch(updatedState);
                    return updatedState;
                });
                setQueryResult(
                    await client.services.erp
                        .getItemsByAdjustmentType({
                            dateFrom: filters.dateFrom,
                            dateTo: filters.dateTo,
                            jobRefId: filters.jobRefId,
                            partNumber: filters.partNumber,
                            quantity: filters.quantity,
                            traceability: filters.traceability,
                            trackingId: filters.trackingId,
                            type: filters.adjustmentType,
                        })()
                        .catch(() => [])
                );

                break;
            }
            case "adjustmentTypeSerialNumber": {
                if (!filters.adjustmentTypeSerialNumber) break;

                setPreviousSearch(() => {
                    const updatedState: ISearch = { filters, type };
                    setSearch(updatedState);
                    return updatedState;
                });
                setQueryResult(
                    await client.services.erp
                        .getSerializedItemsByAdjustmentType({
                            dateFrom: filters.dateFrom,
                            dateTo: filters.dateTo,
                            jobRefId: filters.jobRefId,
                            partNumber: filters.partNumber,
                            serialNumber: filters.secondaryTraceabilitySerialNumber,
                            traceability: filters.traceability,
                            trackingId: filters.trackingId,
                            type: filters.adjustmentTypeSerialNumber,
                        })()
                        .catch(() => [])
                );

                break;
            }
            default:
                break;
        }

        setLoading(false);
    };

    /**
     * Update the search query. `params` will be added to the page URL.
     */
    const updateQuery = (updatedSearch: ISearch = search): void => {
        const isSameSearch = areSimilar(updatedSearch, previousSearch);

        if (isSameSearch) {
            generate();
        } else {
            navigate({ search: createSearchUrl(updatedSearch) });
        }
    };

    return (
        <BomContext.Provider
            value={{
                bomFollowUpSymptoms,
                createDefaultSearch,
                generate,
                loading,
                previousSearch,
                queryResult,
                search,
                setSearch,
                updateQuery,
            }}
        >
            {children}
        </BomContext.Provider>
    );
};

export const useBomContext = (): IBomContext => useContext<IBomContext>(BomContext);

import { getDateTimeStr, GetItemsBySecondaryTraceabilitySerialNumberRes, Unpack } from "@kortex/aos-common";
import { getPageUrl } from "@kortex/aos-ui/configs/menu";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    tableCell: {
        padding: "8px 4px",
    },
    tableRow: {},
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    followUp: Unpack<GetItemsBySecondaryTraceabilitySerialNumberRes>;
    index: number;
}

const Row: FC<IOwnProps> = (props) => {
    const { followUp, index } = props;

    const classes = useStyles(props);

    return (
        <TableRow className={classes.tableRow} id={`bomFollowUpPageSecondaryTraceabilitySerialNumberTableRowId-${index}`}>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageSecondaryTraceabilitySerialNumberTableRowTraceabilityId-${index}`} variant="body2">
                    <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=traceability&traceability=${followUp.traceability}`}>
                        {followUp.traceability}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageSecondaryTraceabilitySerialNumberTableRowPartNumberId-${index}`} variant="body2">
                    <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=partNumber&partNumber=${followUp.partNumber}`}>
                        {followUp.partNumber}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageSecondaryTraceabilitySerialNumberTableRowTrackingIdId-${index}`} variant="body2">
                    <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=trackingId&trackingId=${followUp.trackingId}`}>
                        {followUp.trackingId}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageSecondaryTraceabilitySerialNumberTableRowJobId-${index}`} variant="body2">
                    <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=jobRefId&jobRefId=${followUp.jobRefId}`}>
                        {followUp.jobRefId}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageSecondaryTraceabilitySerialNumberTableRowUpdatedOnId-${index}`} variant="body2">
                    {getDateTimeStr(followUp.updatedOn)}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default Row;

import { BomFollowUpSerializedItemId, IBomFollowUp, ISerializedItem, IWoBomItem } from "@kortex/aos-common";
import { MenuType } from "@kortex/aos-ui/components/core/bom";
import { usePlayerContext } from "@kortex/aos-ui/components/core/ProcessPlayer/context";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { processPlayerBomFollowUpInsertFollowUpSerializedItem } from "@kortex/aos-ui/redux/player-manager/player-thunk-bom-followUp";
import { AutoTabField } from "@kortex/aos-ui/utilitites/autoTab";
import { IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { FC } from "react";

const useStyles = makeStyles({
    cell: {
        borderBottom: "none",
    },
    cellSerialized: {
        flexDirection: "column",
    },
    editIcon: {
        padding: "0px",
    },
});

interface IOwnProps {
    bomFollowUp?: IBomFollowUp;
    handleMoreClick: (
        type: MenuType,
        followUp?: IBomFollowUp,
        serializedItem?: ISerializedItem
    ) => (event: React.MouseEvent<HTMLElement>) => void;
    index: number;
    item: IWoBomItem;
    itemIndex: number;
    traceabilityIndex: number;
}

const SerialNumberRow: FC<IOwnProps> = (props) => {
    const { bomFollowUp, handleMoreClick, index, item, itemIndex, traceabilityIndex } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const { jobProcessInfo, playerState } = usePlayerContext();
    const translate = useTranslate();

    const bomDisabled = !(playerState.processState.bom.enabled && Boolean(jobProcessInfo));
    const editIconDisabled =
        bomDisabled || !Boolean(bomFollowUp?.traceability) || !Boolean(bomFollowUp?.serializedItems[index]?.serialNumber);
    const textFieldDisabled =
        bomDisabled || // BOM features not enabled
        !Boolean(bomFollowUp?.traceability) || // Item does not have a traceability
        Boolean(bomFollowUp?.serializedItems[index]?.serialNumber) || // There is already a serial number at current index
        (index !== 0 && !bomFollowUp?.serializedItems[index - 1]?.serialNumber); // Previous index does not have a serial number

    const handleSerialNumberChange =
        (
            followUpId?: IBomFollowUp["bomFollowUpId"],
            bomFollowUpSerializedItemId?: BomFollowUpSerializedItemId
        ): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            const serialNumber = event.target.value;

            if (!followUpId || !serialNumber || textFieldDisabled) return void 0;

            dispatch(
                processPlayerBomFollowUpInsertFollowUpSerializedItem({
                    bomFollowUpId: followUpId,
                    serialNumber,
                    type: bomFollowUpSerializedItemId ? "reassignment" : "standard",
                    bomFollowUpSerializedItemId,
                })
            );
        };

    return (
        <div className={`${classes.cell} ${classes.cellSerialized}`} key={index}>
            <AutoTabField
                autoTabIndex={[itemIndex, traceabilityIndex, index]}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                className={classes.editIcon}
                                disabled={editIconDisabled}
                                id={`serialNumberRowMoreId-${itemIndex}-${traceabilityIndex}-${index}`}
                                onClick={handleMoreClick("serial", bomFollowUp, bomFollowUp?.serializedItems[index])}
                            >
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    readOnly: textFieldDisabled,
                }}
                onBlur={handleSerialNumberChange(
                    bomFollowUp?.bomFollowUpId,
                    bomFollowUp?.serializedItems[index]?.bomFollowUpSerializedItemId
                )}
                TextFieldProps={{
                    autoComplete: "off",
                    id: `serialNumberFieldId-${itemIndex}-${traceabilityIndex}-${index}`,
                    placeholder: textFieldDisabled ? "" : item.serialized ? translate("player.bom.serialNumber") : translate("general.na"),
                }}
                value={bomFollowUp?.serializedItems[index]?.serialNumber ?? ""}
                variant="standard"
            />
        </div>
    );
};

export default SerialNumberRow;

import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { defaultFrontPage, getPageUrl } from "../menu";

const NoMatchRoute: FC = () => {
    const location = useLocation();
    const session = useSelectorUserSession();

    return (
        <Navigate
            to={session?.userId ? defaultFrontPage(session, true) : getPageUrl(EnumPageTypes.LOGIN)}
            replace={true}
            state={{ from: location }}
        />
    );
};

export default NoMatchRoute;

export class PromiseStack {
    //---------------------------------------------------------
    // Constructor
    //---------------------------------------------------------
    constructor(startOnPush = false) {
        //---------------------------------------------------------
        // Private Members
        //---------------------------------------------------------
        this.running = false;
        this.stack = [];
        //---------------------------------------------------------
        // Public Members
        //---------------------------------------------------------
        this.startOnPush = false;
        this.startOnPush = startOnPush;
    }
    //---------------------------------------------------------
    // Private Methods
    //---------------------------------------------------------
    async start() {
        this.running = true;
        while (this.stack.length > 0) {
            await this.stack.shift()();
        }
        this.running = false;
    }
    //---------------------------------------------------------
    // Public Methods
    //---------------------------------------------------------
    get length() {
        return this.stack.length;
    }
    push(promiseFn) {
        this.stack.push(promiseFn);
        if (this.startOnPush && !this.running) {
            this.start();
        }
    }
}

export function createResultProcessStatusDbModel() {
    return {
        uniqueKey: "",
        jobProcessId: 0,
        reworkId: 0,
        processId: 0,
        trackingId: "",
        totalDuration: 0,
        totalPauseDuration: 0,
        totalStopDuration: 0,
        pass: 0,
        fail: 0,
        qtyPlayed: 0,
        completedOn: 0,
    };
}

import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { IBomFollowUp } from "@kortex/aos-common";
import { useAppLayoutContext } from "@kortex/aos-ui/components/context";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useSelectorBoms } from "@kortex/aos-ui/redux/selectors";
import { makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useRef, useState } from "react";

import { serviceMap, ServiceVariant } from "../../utils";

const useStyles = makeStyles({
    dialogContent: {
        display: "grid",
        gridGap: "5px",
    },
    quantityTextField: {
        width: "150px",
    },
    traceability: {
        display: "flex",
        alignItems: "center",
    },
});

interface IOwnProps {
    followUp: IBomFollowUp;
    onClose: () => void;
    open: boolean;
    variant: ServiceVariant;
}

const EditTraceabilityDialog: FC<IOwnProps> = (props) => {
    const { followUp, onClose, open, variant } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const { setLoading } = useAppLayoutContext();
    const lotSerialType = useSelectorBoms()[0]?.woBom.items[followUp.partNumber].lotSerialType;
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [justification, setJustification] = useState<string>("");
    const [traceability, setTraceability] = useState<string>(followUp.traceability);
    const [traceabilityError, setTraceabilityError] = useState<string>("");

    const confirmButtonDisabled = traceability.trim().length === 0 || justification.trim().length < 3;

    /**
     * Resets the fields when the dialog is opened
     */
    useEffect(() => {
        if (open) {
            setJustification("");
            setTraceability(followUp.traceability);
        }
    }, [open]);

    /**
     * Closes the dialog
     */
    const handleClose = (): void => {
        onClose();
    };

    /**
     * Proceeds to edit the follow-up
     */
    const handleConfirm = (): void => {
        setTraceabilityError("");
        setLoading(true);

        dispatch(
            serviceMap[variant].editItem({
                bomFollowUpId: followUp.bomFollowUpId,
                partNumber: followUp.partNumber,
                justification: justification.trim(),
                traceability: traceability.trim(),
                lotSerialType,
            })
        )
            .then((res) => {
                if (!res) {
                    setTraceabilityError(translate("bomPage.bomTable.invalidTraceability"));
                    if (inputRef.current) {
                        inputRef.current.select();
                    }
                } else {
                    handleClose();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Updates the justification (inner state)
     */
    const handleJustificationChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setJustification(event.target.value);
    };

    /**
     * Updates the traceability (inner state)
     */
    const handleTraceabilityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setTraceabilityError("");
        setTraceability(event.target.value);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={confirmButtonDisabled}
            onCancel={handleClose}
            onConfirm={handleConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("bomPage.bomTable.edit"),
            }}
        >
            <div className={classes.dialogContent} id="editTraceabilityDialogContentId">
                {/* TRACEABILITY */}
                <div className={classes.traceability}>
                    <KortexTextField
                        label={translate("bomPage.bomTable.traceability")}
                        onChange={handleTraceabilityChange}
                        TextFieldProps={{
                            autoComplete: "off",
                            id: "editTraceabilityDialogTraceabilityId",
                            required: true,
                        }}
                        value={traceability}
                        variant="outlined"
                        InputProps={{
                            inputRef: inputRef,
                        }}
                        error={traceabilityError !== "" ? traceabilityError : undefined}
                    />
                </div>

                {/* JUSTIFICATION */}
                <KortexTextField
                    label={translate("bomPage.bomTable.justification")}
                    onChange={handleJustificationChange}
                    TextFieldProps={{
                        autoComplete: "off",
                        id: "editTraceabilityDialogJustificationId",
                        multiline: true,
                        required: true,
                        rows: 5,
                    }}
                    value={justification}
                    variant="outlined"
                    error={justification.trim().length < 3 ? translate("bomPage.bomTable.errorJustification") : undefined}
                />
            </div>
        </KortexDialogConfirmation>
    );
};

export default EditTraceabilityDialog;

import { getDateTimeStr, IGetItemsByTypeAjustmentRes, Unpack } from "@kortex/aos-common";
import { getPageUrl } from "@kortex/aos-ui/configs/menu";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    tableCell: {
        padding: "8px 4px",
    },
    tableRow: {},
    typographyLine: {
        display: "flex",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    followUpHistory: Unpack<IGetItemsByTypeAjustmentRes>;
    index: number;
}

const Row: FC<IOwnProps> = (props) => {
    const { followUpHistory, index } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    return (
        <TableRow className={classes.tableRow} id="bomFollowUpPageAdjustmentTypeOperationTableRowId">
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeOperationTableRowQuantityId-${index}`} variant="body2">
                    {followUpHistory.quantity}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <div className={classes.typographyLine}>
                    <Typography id={`bomFollowUpPageAdjustmentTypeOperationTableRowTraceabilityId-${index}`} variant="body2">
                        {followUpHistory.traceability || followUpHistory.previousValues.traceability ? (
                            <Link
                                to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=traceability&traceability=${
                                    followUpHistory.traceability
                                }`}
                            >
                                {followUpHistory.traceability || followUpHistory.previousValues.traceability}
                            </Link>
                        ) : (
                            translate("general.na")
                        )}
                    </Typography>
                    &nbsp;
                    {!followUpHistory.traceability && followUpHistory.previousValues.traceability ? (
                        <Typography
                            color="error"
                            id={`bomFollowUpPageAdjustmentTypeOperationTableRowMissingPreviousTraceabilityIcon1Id-${index}`}
                            variant="body2"
                        >
                            <sup>1</sup>
                        </Typography>
                    ) : !followUpHistory.traceability && !followUpHistory.previousValues.traceability ? (
                        <Typography
                            color="error"
                            id={`bomFollowUpPageAdjustmentTypeOperationTableRowMissingPreviousTraceabilityIcon2Id-${index}`}
                            variant="body2"
                        >
                            <sup>2</sup>
                        </Typography>
                    ) : null}
                </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeOperationTableRowPartNumberId-${index}`} variant="body2">
                    <Link
                        to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=partNumber&partNumber=${followUpHistory.followUp.partNumber}`}
                    >
                        {followUpHistory.followUp.partNumber}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeOperationTableRowTrackingIdId-${index}`} variant="body2">
                    <Link
                        to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=trackingId&trackingId=${followUpHistory.followUp.trackingId}`}
                    >
                        {followUpHistory.followUp.trackingId}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeOperationTableRowJobId-${index}`} variant="body2">
                    <Link to={`${getPageUrl(EnumPageTypes.BOM_FOLLOW_UP)}/?type=jobRefId&jobRefId=${followUpHistory.followUp.jobRefId}`}>
                        {followUpHistory.followUp.jobRefId}
                    </Link>
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeOperationTableRowCreatedOnId-${index}`} variant="body2">
                    {getDateTimeStr(followUpHistory.createdOn)}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography id={`bomFollowUpPageAdjustmentTypeOperationTableRowSymptomTitleId-${index}`} variant="body2">
                    {followUpHistory.symptom?.title || translate("general.na")}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell} id={`bomFollowUpPageAdjustmentTypeOperationTableRowJustificationId-${index}`}>
                <Typography variant="body2">{followUpHistory.justification || translate("general.na")}</Typography>
            </TableCell>
        </TableRow>
    );
};

export default Row;

import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";
import { MenuItem } from "@material-ui/core";
import React, { useMemo } from "react";

import { IFilters } from "../../context/bomContext";

export function useAdjustmentTypeMenuItems(filter: keyof IFilters): JSX.Element[] {
    const language = useSelectorLanguage();
    const translate = useTranslate();

    return useMemo(() => {
        let options: string[] = [];

        switch (filter) {
            case "adjustmentType":
                options = ["edit", "multiTraceability", "overconsumption", "reassignment", "replacement", "remove", "standard"];
                break;
            case "adjustmentTypeSerialNumber":
                options = ["edit", "reassignment", "replacement", "remove", "standard"];
                break;
        }

        return options
            .sort((a, b) => translate(`bomPage.adjustmentType.${a}`).localeCompare(translate(`bomPage.adjustmentType.${b}`)))
            .map((type, index) => (
                <MenuItem key={index} id={`bomHeaderAjustmentType-${type}`} value={type}>
                    {translate(`bomPage.adjustmentType.${type}`)}
                </MenuItem>
            ));
    }, [filter, language]);
}
